import mixpanel from 'mixpanel-browser';

import Storage from 'lib/utils/localStorage';

mixpanel.init(process.env.MIXPANEL_TOKEN, { debug: false });

export const mixpanelTrack = (event, extra) => mixpanel.track(event, {
  name: Storage.getParam('facility/name'),
  facility_id: Storage.getParam('facility/id'),
  location: Storage.getParam('facility/location'),
  segment: Storage.getParam('facility/segment'),
  autoselectType: Storage.setParam('facility/autoselectType'),
  selectionType: Storage.setParam('facility/selectionType'),
  ...extra,
});


export default mixpanel;

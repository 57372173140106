import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Portal from '@material-ui/core/Portal';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';
import {
  Favorite, FavoriteBorder, NotInterested, NotInterestedSharp, Close,
} from '@material-ui/icons';
import { Typography } from '@material-ui/core';

const variantIcon = {
  favorite: Favorite,
  removeFavorite: FavoriteBorder,
  DNR: NotInterested,
  removeDNR: NotInterestedSharp,
};

const styles1 = (theme) => ({
  toast: {
    backgroundColor: '#E8EAF1',
    borderLeft: '3px solid #38427E',
    alignSelf: 'center',
  },
  title: {
    fontSize: '1em',
    fontWeight: 'bold',
    color: '#2D3748',
  },
  icon: {
    fontSize: 24,
    color: '#38427E',
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  messageContainer: {
    display: 'flex',
    marginLeft: -theme.spacing.unit,
  },
  message: {
    // display: 'flex',
    alignItems: 'center',
    color: '#38427E',
  },
  messageText: {
    color: '#2D3748',
  },
  closeButton: {
    marginRight: -theme.spacing.unit * 3,
    marginTop: -theme.spacing.unit,
    maxHeight: 20,
  },
  undoButton: {
    border: '1px solid #404B89',
    color: '#404B89',
    borderRadius: 10,
    marginTop: theme.spacing.unit,
    textTransform: 'capitalize',
  },
  margin: {
    margin: theme.spacing.unit,
  },
});

const Toast = (props) => {
  const {
    classes, message, title, onUndo, variant, onClose, isOpen, relation,
  } = props;
  const Icon = variantIcon[variant];

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };

  const handleUndo = () => {
    onClose();
    if (onUndo) {
      const action = variant.indexOf('remove') >= 0 ? 'add' : 'remove';
      onUndo(action, relation);
    }
  };

  return (
    <Portal>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={isOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <SnackbarContent
          className={classes.toast}
          aria-describedby="client-snackbar"
          message={(
            <div className={classes.messageContainer}>
              <Icon className={classNames(classes.icon, classes.iconVariant)} />
              <span id="client-snackbar" className={classes.message}>
                <Typography className={classes.title}>{title}</Typography>
                <Typography variant="body1" className={classes.messageText}>{message}</Typography>
                { onUndo
                  ? (
                    <Button className={classes.undoButton} onClick={handleUndo}>
                      Undo
                    </Button>
                  ) : null}
              </span>
              <Button className={classes.closeButton} onClick={onClose}>
                <Close fontSize="15" />
              </Button>
            </div>
      )}
        />
      </Snackbar>
    </Portal>
  );
};

Toast.defaultProps = {
  onUndo: null,
};

Toast.propTypes = {
  classes: PropTypes.shape({
    icon: PropTypes.string,
    columns: PropTypes.string,
    close: PropTypes.string,
    messageContainer: PropTypes.string,
    message: PropTypes.string,
    messageText: PropTypes.string,
    title: PropTypes.string,
    iconVariant: PropTypes.string,
    margin: PropTypes.string,
    toast: PropTypes.string,
    undoButton: PropTypes.string,
    closeButton: PropTypes.string,
  }).isRequired,
  message: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  relation: PropTypes.string.isRequired,
  onUndo: PropTypes.func,
  variant: PropTypes.oneOf(['favorite', 'removeFavorite', 'DNR', 'removeDNR']).isRequired,
};

export default withStyles(styles1)(Toast);

import React from 'react'
import { Field, FieldArray } from 'redux-form';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = {
  component: {
    marginTop: 40
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginBottom: 5,
    fontSize: '1rem',
    transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    transformOrigin: 'top left',
    cursor: 'default',
  },
  labelWithList: {
    color: '#303f9f',
    transform: 'translate(0, -10px) scale(0.75)'
  },
  rows: {
    marginLeft: 20
  },
  rowsWithList: {
    marginTop: -22
  },
  items: {
    display: 'flex',
    alignItems: 'flex-end',
    marginTop: -10
  },
  item: {
    marginRight: 10
  },
  buttonDelete: {
    marginLeft: 20
  },
  buttonAdd: {
    marginLeft: 20,
    marginTop: 15
  }
};

const renderItem= ({ fields, label, children, ...props }) => {

  if (typeof children === 'object' && children.map === undefined) {
    children = [children];
  }

  let labelStyles = styles.label;
  if (fields.length) {
    labelStyles = {...styles.label, ...styles.labelWithList};
  }

  let rowsStyles = styles.rows;
  if (fields.length) {
    rowsStyles = {...styles.rows, ...styles.rowsWithList};
  }

  return (
    <div style={styles.component}>
      <div style={labelStyles}>{label}</div>
      <div style={rowsStyles}>
        {fields.map((name, index) => (
          <div key={index} style={styles.items}>
            {children.map((item, key) => (
              <div key={`${index}-${name}-${item.props.name}-${key}`} style={styles.item}>
                <Field
                  {...props}
                  {...item.props}
                  component={item.type}
                  name={`${name}.${item.props.name}`}
                />
              </div>
            ))}
            <div>
              <Button
                variant="fab"
                mini
                color="secondary"
                aria-label="delete"
                style={styles.buttonDelete} onClick={() => fields.remove(index)}
              >
                <DeleteIcon/>
              </Button>
            </div>
          </div>
        ))}
      </div>
      <div style={styles.buttonAdd}>
        <Button
          variant="fab"
          mini
          color="secondary"
          aria-label="add"
          onClick={() => fields.push({})}
        >
          <AddIcon/>
        </Button>
      </div>
    </div>
  );
};

const SingleList = (props) => {
  return <FieldArray name={props.source} component={renderItem} {...props} />;
};

export default SingleList;

import React, { useCallback, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Toolbar, SaveButton } from 'react-admin';
import * as shiftAction from 'lib/redux/shifts/actions';
import classnames from 'classnames';
import ContentSaveIcon from '@material-ui/icons/Save';
import DNRDialog from '../../ui/DNRDialog';

const styles = (theme) => ({
  toolbar: {
    marginTop: 0,
  },
  saveButton: {
    '&>span>div': {
      fontSize: theme.spacing.unit * 3,
    },
  },
  progressIcon: {
    display: 'block',
    color: 'white',
  },
});

const ReviewToolbarWithConfirmation = compose(
  connect((state) => ({
    isLoading: state.loading.stack[shiftAction.SHIFT_SEND_REVIEW],
  })),
  withStyles(styles),
)(({
  classes, width, isLoading, onSubmit, isShowConfirm, record, invalid, ...props
}) => {
  const icon = isLoading
    ? <div><CircularProgress size={24} className={classes.progressIcon} /></div>
    : <ContentSaveIcon />;

  const [isOpen, setOpen] = useState(false);
  const handleClick = useCallback(() => {
    if (!invalid) {
      if (isShowConfirm) {
        setOpen(true);
      } else {
        onSubmit(record);
      }
    }
  }, [isShowConfirm, invalid, record]);

  const handleConfirm = useCallback((reason) => {
    if (!invalid) {
      onSubmit(record, reason);
      setOpen(false);
    }
  }, [record]);

  const handleCancel = useCallback(() => {
    // add it later if we want to submit the review on cancel
    // onSubmit(record);
    setOpen(false);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);


  return (
    <Toolbar {...props} className={classnames('toolbar', classes.toolbar)}>
      <SaveButton
        className={classes.saveButton}
        icon={icon}
        label="Confirm"
        submitOnEnter
        disabled={isLoading}
        onClick={handleClick}
      />
      <DNRDialog isOpen={isOpen} onConfirm={handleConfirm} onClose={handleClose} onCancel={handleCancel} />
    </Toolbar>
  );
});
export default ReviewToolbarWithConfirmation;

import { AUTH_ROLE_SET } from './actions';

const initialState = {
  init: false,
  grants: [],
  showResources: [],
  startResource: null,
};

export default (state = initialState, { type, payload } = {}) => {

  switch (type) {
    case AUTH_ROLE_SET:
      return { ...state, grants: payload.grants, showResources: payload.showResources, startResource: payload.startResource, init: true };
  }

  return state;
}
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import WarningIcon from '@material-ui/icons/WarningRounded';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import DNRReasons from './DNRReasons';

const styles = (theme) => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '20px',
  },
  title: {
    fontSize: '1.1em',
    fontWeight: 'bold',
    color: '#2D3748',
  },
  icon: {
    fontSize: 30,
    color: '#C53030',
    alignSelf: 'center',
    marginLeft: theme.spacing.unit,
  },
  messageContainer: {
    display: 'flex',
    border: '2px solid #C53030',
    borderRadius: 10,
    marginRight: theme.spacing.unit * 2,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    color: '#1A202C',
    marginLeft: theme.spacing.unit,
    marginTop: theme.spacing.unit,
  },
  messageText: {
    color: '#2D3748',
    fontSize: 16,
    marginRight: theme.spacing.unit * 2,
  },
  closeButton: {
    marginRight: -theme.spacing.unit,
  },
  deleteButton: {
    color: '#fff',
    borderRadius: 5,
    marginTop: theme.spacing.unit,
    textTransform: 'capitalize',
    backgroundColor: '#E53E3E',
  },
  cancelButton: {
    color: '#2D3748',
    borderRadius: 5,
    marginTop: theme.spacing.unit,
    textTransform: 'capitalize',
    backgroundColor: '#E2E8F0',
  },
  margin: {
    margin: theme.spacing.unit,
  },
});

const DNRDialog = ({
  isOpen, onClose, onConfirm, classes, onCancel,
}) => {
  const [reason, setReason] = useState(null);
  const updateReson = (selectedReason) => {
    setReason(selectedReason);
  };
  const handleConfirm = useCallback(
    (e) => {
      e.stopPropagation();
      onConfirm(reason);
    },
    [onConfirm, reason],
  );

  const handleClick = useCallback((e) => {
    e.stopPropagation();
  }, []);
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      onClick={handleClick}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" disableTypography className={classes.dialogTitle}>
        <h2 className={classes.title}>Add to DNR list</h2>
        <IconButton onClick={onClose} classeName={classes.closeButton}>
          <Close fontSize="default" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className={classes.messageContainer}>
          <WarningIcon className={classes.icon} />
          <span id="client-snackbar" className={classes.message}>
            <Typography variant="body1" className={classes.messageText}>
        This clinician will be withdrawn from all
              <br />
shifts at your Facility and will lose access
              <br />
to all future shifts
            </Typography>
          </span>
        </div>
        <DNRReasons onSubmit={updateReson} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" autoFocus className={classes.cancelButton}>
          Cancel
        </Button>

        <Button onClick={handleConfirm} color="primary" autoFocus className={classes.deleteButton} disabled={!reason || reason === ''}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DNRDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    icon: PropTypes.string,
    columns: PropTypes.string,
    close: PropTypes.string,
    messageContainer: PropTypes.string,
    message: PropTypes.string,
    messageText: PropTypes.string,
    title: PropTypes.string,
    margin: PropTypes.string,
    closeButton: PropTypes.string,
    dialogTitle: PropTypes.string,
    cancelButton: PropTypes.string,
    deleteButton: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(DNRDialog);

/** @format */

// in src/Menu.js
import React, { createElement, useEffect } from "react";
import { connect } from "react-redux";
import { getResources, MenuItemLink, translate } from "react-admin";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import LabelIcon from "@material-ui/icons/Label";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import DescriptionIcon from "@material-ui/icons/Description";
import PeopleIcon from "@material-ui/icons/People";

import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { mixpanelTrack } from "lib/utils/mixpanel";
import Storage from "lib/utils/localStorage";
import { bindActionCreatorsObjective } from "lib/redux/utils";
import * as systemActions from "lib/redux/system/actions";
import { NEXTGEN_APP_URL } from "../../config";

const styles = (theme) => ({
  itemText: {
    marginTop: 0,
  },
  badge: {
    top: -theme.spacing.unit,
    fontSize: Math.round(theme.typography.fontSize * 0.8),
    fontWeight: "bold",
    // The border color match the background color.
  },
  badgeRoot: {
    display: "inline-block",
  },
});

const handlerTrack = (systemActions, pathname) => {
  switch (pathname) {
    case "Monthly Calendar":
      systemActions.trackOpenScreenCalendar();
      break;
    case "Weekly Calendar":
      window.location.href = `${NEXTGEN_APP_URL}/weekly`;
      break;
    case "OverviewShift":
      systemActions.trackOpenScreenShiftOverview();
      break;
    case "CompletedShift":
      systemActions.trackOpenScreenCompletedShift();
      break;
    case "OverviewShift/create":
      systemActions.trackOpenScreenShiftCreate("main menu");
      break;
    case "Messages":
      systemActions.trackOpenScreenMessages("main menu");
      break;
    case "Facility":
      systemActions.trackOpenSettings();
      break;
  }
};

const LayoutMainMenu = ({
  systemActions,
  classes,
  resources,
  unreadCount,
  onMenuClick,
  logout,
  location: { pathname },
  translate,
  flags,
  ...props
}) => {
  const { userManagement, nextGenShiftPosting, nextGenCalendar } = flags;
  const role = Storage.getParam("user/role");
  const nextGenRole = Storage.getParam("facility/nextGenRole");
  const launchIntercom = () => {
    const id = Storage.getParam("facility/id");
    const name = Storage.getParam("facility/name");
    const email = Storage.getParam("facility/email");
    const areaName = Storage.getParam("facility/location");
    const segmentName = Storage.getParam("facility/segment");
    const selectionType = Storage.getParam("facility/selectionType");
    if (name || email) {
      global.window.Intercom("boot", {
        app_id: process.env.INTERCOM_APP_ID,
        user_id: id,
        name,
        email,
        Segment: segmentName,
        "Home Market": areaName,
        "Selection Type": selectionType,
        type: "facility",
      });
    }
  };
  useEffect(() => {
    launchIntercom();
  }, []);
  const items = resources.map((resource) => {
    let { isHide } = resource.options;

    let resourceName = resource.name;
    let resourcePath = `/${resource.name}`;
    if (resource.name === 'Calendar') {
      resourceName = 'Monthly Calendar';
      resourcePath = '/Calendar';
    }

    if (resource.name === "Weekly Calendar") {
      if (
        nextGenCalendar &&
        (nextGenRole === "Facility" || role === "facility")
      ) {
        isHide = false;
      }
    }
    if (resource.name === "Shift Templates") {
      if (
        nextGenShiftPosting &&
        (nextGenRole === "Facility" || role === "facility")
      ) {
        isHide = false;
      }
    }
    if (isHide) {
      return null;
    }

    if (role && role === "admin" && resource.name === "Messages") {
      return null;
    }

    let { name } = resource;
    const i18Name = translate(`resources.${resource.name}.name`, resource.name);
    if (i18Name && i18Name !== `resources.${resource.name}.name`) {
      name = /\|\|\|\|/.test(i18Name) ? i18Name.split("||||")[1] : i18Name;
    }

    const isRenderUnreadCount = resource.name === "Messages" && unreadCount > 0;

    return (
      <MenuItemLink
        key={resource.name}
        to={resourcePath}
        primaryText={<div className={classes.itemText}>{name}</div>}
        color="textPrimary"
        leftIcon={
          <>
            {isRenderUnreadCount && (
              <Badge
                badgeContent={unreadCount}
                color="error"
                classes={{ badge: classes.badge, root: classes.badgeRoot }}
                variant="dot"
              >
                {resource.icon ? createElement(resource.icon) : <LabelIcon />}
              </Badge>
            )}
            {!isRenderUnreadCount &&
              (resource.icon ? createElement(resource.icon) : <LabelIcon />)}
          </>
        }
        onClick={(event) => {
          handlerTrack(systemActions, resource.name);
          onMenuClick && onMenuClick(event);
        }}
        exact={pathname === "/OverviewShift/create"}
      />
    );
  });

 
  

  if (userManagement && (role === "admin" || nextGenRole === "Admin")) {
    items.push(
      <MenuItemLink
        key="userManagement"
        to="/UserManagement"
        primaryText="User Management"
        leftIcon={<PeopleIcon />}
        onClick={() => {
          window.location.href = `${NEXTGEN_APP_URL}/users`;
        }}
      />
    );
  }

  items.push(
    <MenuItemLink
      key="hiringform"
      to="../"
      primaryText="Hiring Form"
      leftIcon={<DescriptionIcon />}
      onClick={() => {
        window.location.href =
          "https://docs.google.com/forms/d/e/1FAIpQLSfEfPkP4IR2-F0w3f5LTkjb99lHYQoRbVI-deO5X8mW3sm4Pw/viewform";
      }}
    />
  );

  items.push(
    <MenuItemLink
      key="contactus"
      to="../"
      primaryText="Contact Us"
      leftIcon={<HelpOutlineIcon />}
      onClick={() => {
        mixpanelTrack("MENU_CONTACT_US");
        window.Intercom("showMessages");
      }}
    />
  );

  items.push(
    <div key="logout" className="AppBar__logout">
      {logout}
    </div>
  );

  return items;
};

const mapStateToProps = (state, props) => {
  const {
    location: { pathname },
  } = props;

  let ignoreChannelId = 0;
  const match = pathname.match(/\/Messages\/([0-9]+)\/show/i);
  if (match && match[1]) {
    ignoreChannelId = Number(match[1]);
  }
  const currentUserId = Number(Storage.getParam("user/id"));

  const unreadCount = state.chat.channelsOrder.reduce(
    (accumulator, channelId) => {
      const { members } = state.chat.channels[channelId];
      const member = members.find(
        (member) =>
          member.user.userModel === "facility" &&
          member.user.userId === currentUserId
      ) || { unreadCount: 0 };
      return (
        accumulator +
        (member.unreadCount > 0 && channelId !== ignoreChannelId ? 1 : 0)
      );
    },
    0
  );

  return {
    unreadCount,
    resources: getResources(state),
  };
};

const mapActionToProps = (dispatch) =>
  bindActionCreatorsObjective(
    {
      systemActions,
    },
    dispatch
  );

export default withStyles(styles)(
  translate(
    withRouter(
      connect(
        mapStateToProps,
        mapActionToProps
      )(withLDConsumer()(LayoutMainMenu))
    )
  )
);

import { gql } from 'apollo-boost';
import { eventChannel, END } from 'redux-saga';
import { call, put, take, select, cancel } from 'redux-saga/effects';
import {
  client,
  subscriptionClient,
  SUBSCRIPTION_STATUS_CONNECTED
} from '../../../dataProvider';

import {
  requestChannels,
  receiveChannel,
} from '../actions';

import {
  SUBSCRIPTION_DISCONNECTED,
  SUBSCRIPTION_RECONNECTED,
} from '../../subscription/actions';

export const action = [
  'RA/USER_LOGOUT',
  'RA/USER_LOGIN_SUCCESS',
  'RA/USER_CHECK',
  SUBSCRIPTION_DISCONNECTED,
  SUBSCRIPTION_RECONNECTED,
];

let stopWatchingChannelsUpdates;

function startWatchingChannelsUpdates() {
  return eventChannel((emitter) => {

    stopWatchingChannelsUpdates = () => {
      emitter(END);
    };

    const query = gql`
      subscription {
        channelUpdated {
          id
          name
          members {
            id
            unreadCount
            user {
              id
              name
              userId
              userModel
              image {
                src
              }
            }
          }
          lastMessage {
            id
            text
            createdAt
            member {
              id
            }
          }
          applicant {
            shift {
              id
              facility {
                name
              }
              role
              start_time
              end_time
            }
          }
          createdAt
        }
      }
    `;

    const variables = {};

    const subscribe = client.subscribe({ query, variables }).subscribe({
      next({ data }) {
        if (!data || !data.channelUpdated) {
          return;
        }

        emitter(data.channelUpdated);
      },
      error(err) {
        console.error('err', err);
      },
    });

    return () => {
      if (subscriptionClient.status === SUBSCRIPTION_STATUS_CONNECTED) {
        subscribe.unsubscribe();
      }
    };
  });
}

export default function* ({ type }) {
  if (type === SUBSCRIPTION_DISCONNECTED) {
    if (stopWatchingChannelsUpdates) {
      stopWatchingChannelsUpdates();
    }
    stopWatchingChannelsUpdates = null;
    yield cancel();
  }

  if (type === 'RA/USER_LOGOUT') {
    if (stopWatchingChannelsUpdates) {
      stopWatchingChannelsUpdates();
    }
    setTimeout(() => { stopWatchingChannelsUpdates = null; }, 100);
    yield cancel();
  }

  if (stopWatchingChannelsUpdates) {
    yield cancel();
  }

  stopWatchingChannelsUpdates = () => ({});

  const chat = yield select(state => state.chat);

  if (chat.channels === null || type === SUBSCRIPTION_RECONNECTED) {
    yield put(requestChannels());
  }

  const listener = yield call(startWatchingChannelsUpdates);

  try {
    console.log('start listening channels');
    while (true) {
      const channel = yield take(listener);
      yield put(receiveChannel(channel));
    }
  } catch (error) {
    console.log('error listening channels');
  } finally {
    console.log('finish listening channels');
  }
}

import React, { useEffect } from 'react';
import { NEXTGEN_APP_URL } from '../../config';

const ShiftTemplates = () => {
  useEffect(() => {
    window.location.href = `${NEXTGEN_APP_URL}/shift-templates`;
  }, []);

  return null;
};

export default ShiftTemplates;

import React from 'react';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { groupByDate } from 'lib/utils/group';
import { renderDateFormatterRange } from 'lib/utils/render';
import DateTimeField from 'ui/DateTimeField';
import CloneButton from 'ui/CloneButton';
import ReviewShiftButton from 'components/shift/ReviewShiftButton';
import Applicants from 'components/completedShift/Applicants';
import { cloneShift } from '../ui/utils';

const styles = (theme) => ({
  details: {
    display: 'block',
    paddingBottom: 0,
  },
  shift: {
    cursor: 'pointer',
    paddingRight: theme.spacing.unit * 4,
    [theme.breakpoints.up('sm')]: {
      paddingRight: 0,
    },
  },
  extraPadding: {
    paddingRight: theme.spacing.unit * 10,
  },
  list: {
    padding: 0,
    margin: 0,
    marginBottom: theme.spacing.unit * 2,
  },
  item: {
    marginLeft: -theme.spacing.unit * 2,
    marginRight: -theme.spacing.unit * 2,
    [theme.breakpoints.up('sm')]: {
      marginLeft: -theme.spacing.unit * 3,
      marginRight: -theme.spacing.unit * 3,
    },
    width: 'auto',
  },
});

const Grid = ({
  history, ids = [], data = {}, classes,
}) => {
  const byDate = groupByDate(data, ids);
  const { nextGenShiftPosting } = useFlags();

  const handleCloneClicked = (shiftId) => {
    cloneShift(shiftId, nextGenShiftPosting);
  };

  return Object.keys(byDate).map((date) => (
    <ExpansionPanel key={date} defaultExpanded>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{date}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <List className={classes.list}>
          {byDate[date].map((shift) => (
            <ListItem key={shift.id} className={classes.item} dense button onClick={() => history.push(`/CompletedShift/${shift.id}/show`)}>
              <div className={classNames(classes.shift, shift.shiftStatus === 'pending review' && classes.extraPadding)}>
                <Typography variant="subheading">{shift.role}</Typography>
                <Typography color="textSecondary">{renderDateFormatterRange(shift.start_time, shift.end_time)}</Typography>
                <Applicants record={shift} />
                <Typography color="textSecondary">
                  {'Clock-in: '}
                  <DateTimeField record={shift} source="checkInTime" />
                </Typography>
                <Typography color="textSecondary">
                  {'Clock-out: '}
                  <DateTimeField record={shift} source="checkOutTime" />
                </Typography>
              </div>
              <ListItemSecondaryAction>
                {shift.shiftStatus === 'pending review' && (
                  <ReviewShiftButton
                    record={shift}
                    text="Review"
                  />
                )}
                {shift.shiftStatus !== 'pending review' && (
                  <CloneButton
                    record={shift}
                    ariaLabel="Clone shift"
                    basePath={nextGenShiftPosting ? 'OverviewShift' : null}
                    onClick={!nextGenShiftPosting ? () => {
                      handleCloneClicked(shift?.id);
                    } : null}
                  />
                )}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  ));
};

export default withStyles(styles)(withRouter(Grid));

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  title: {
    marginTop: theme.spacing.unit,
  },
});

const Title = ({
  record, source, text, classes,
}) => (
  <Typography variant="subheading" className={classes.title}>{text || record[source]}</Typography>
);

Title.propTypes = {
  classes: PropTypes.shape({
    title: PropTypes.string,
  }),
  record: PropTypes.shape({}),
  source: PropTypes.string,
  text: PropTypes.string,
};

Title.defaultProps = {
  classes: null,
  record: null,
  source: null,
  text: null,
};

export default withStyles(styles)(Title);

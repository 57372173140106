import React from 'react';
import PropTypes from 'prop-types';
import { NumberInput, SelectInput } from 'react-admin';
import Tooltip from '@material-ui/core/Tooltip';

const BreakTime = ({
  record, source, label, ...props
}) => {
  const disabled = record?.status !== 'opened';

  const breakOptions = [
    { id: 0, name: "No Break" }
    , { id: 15, name: "15 Minutes" }
    , { id: 30, name: "30 Minutes" }
    , { id: 45, name: "45 Minutes" }
    , { id: 60, name: "1 Hour" }
  ];



  if (disabled) {
    return (
      <Tooltip title={'You can edit the field only in status "opened"'} placement="left">
        <NumberInput {...props} record={record} source={source} label={label} disabled />
      </Tooltip>
    );
  }

  return (

    <SelectInput
      {...props}
      record={record}
      source={source}
      label={label}
      choices={breakOptions}
      fullWidth
    />

  );
};

BreakTime.propTypes = {
  record: PropTypes.shape({
    status: PropTypes.string,
  }),
  source: PropTypes.string,
  label: PropTypes.string,
};

BreakTime.defaultProps = {
  record: null,
  source: null,
  label: null,
};

export default BreakTime;

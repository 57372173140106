const LS_SET = 'set';

const LS_REMOVE = 'remove';

const MODE_LOCAL = 'local';

const MODE_SESSION = 'session';

class LocalStorage {
  constructor() {
    /**
     * @private
     * @type {string}
     */
    this.mode = MODE_LOCAL;

    /**
     * @private
     * @type {Array}
     */
    this.triggers = [];

    this.setMode = this.setMode.bind(this);
    this.storage = this.storage.bind(this);
    this.setParam = this.setParam.bind(this);
    this.getParam = this.getParam.bind(this);
    this.removeParam = this.removeParam.bind(this);
    this.trigger = this.trigger.bind(this);
    this.on = this.on.bind(this);
    this.off = this.off.bind(this);
  }

  /**
   * @private
   * @param mode
   */
  setMode(mode) {
    this.mode = mode;
  }

  /**
   * @private
   * @returns {*}
   */
  storage() {
    if (this.mode === MODE_LOCAL) {
      return localStorage;
    }

    if (this.mode === MODE_SESSION) {
      return sessionStorage;
    }

    return {
      setItem: () => { console.error('store is not set'); },
      getItem: () => { console.error('store is not set'); },
      removeItem: () => { console.error('store is not set'); },
    };
  }

  setParam(name, value) {
    if (this.bounce) {
      clearTimeout(this.bounce);
      delete this.bounce;
    }
    const result = this.storage().setItem(name, value);
    this.bounce = setTimeout(() => {
      this.trigger(LS_SET, name, value);
    }, 0);
    return result;
  }

  getParam(name) {
    return this.storage().getItem(name);
  }

  removeParam(name) {
    if (this.bounce) {
      clearTimeout(this.bounce);
      delete this.bounce;
    }
    const result = this.storage().removeItem(name);
    this.bounce = setTimeout(() => {
      this.trigger(LS_REMOVE, name);
    }, 0);
    return result;
  }

  /**
   * @private
   * @param event
   * @param name
   * @param value
   */
  trigger(event, name, value = null) {
    this.triggers.forEach((item) => {
      if (item.event === event) {
        item.trigger(event, name, value);
      }
    });
  }

  /**
   * @param event
   * @param trigger
   */
  on(event, trigger) {
    this.triggers.push({ event, trigger });
  }

  /**
   * @param event
   * @param trigger
   */
  off(event, trigger) {
    const buffer = [];
    this.triggers.forEach((item) => {
      if (item.event === event && item.trigger === trigger) {
        return;
      }
      buffer.push(item);
    });
    this.triggers = buffer;
  }
}

const Storage = new LocalStorage();

export {
  LS_SET, LS_REMOVE, MODE_LOCAL, MODE_SESSION, Storage,
};
export default Storage;

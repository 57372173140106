import React from 'react';
import {
  List as RAList,
} from 'react-admin';

import Pagination from 'ui/Pagination';
import Grid from 'components/completedShift/Grid';
import Filters from 'components/completedShift/Filters';

const CompletedShiftList = (props) => (
  <div className="layout-page" data-page="shifts/completed" data-mode="list">
    <RAList
      sort={{ field: 'start_time', order: 'desc' }}
      {...props}
      pagination={<Pagination />}
      filters={<Filters />}
    >
      <Grid />
    </RAList>
  </div>
);

export default CompletedShiftList;

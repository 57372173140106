import React from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import Storage from 'lib/utils/localStorage';
import { getUrlForMicroShiftAppUrl } from 'utils/getUrlForMicroShiftApp';
import PropTypes from 'prop-types';
import { NEXTGEN_APP_URL } from '../../config';
import { omitFields } from '../ui/utils';

const ShiftExperienceRedirect = ({ flags, record }) => {
  const { nextGenShiftPosting } = flags;
  const role = Storage.getParam('user/role');
  const params = record ? `?${new URLSearchParams(omitFields(record)).toString()}` : '';

  if (nextGenShiftPosting && role === 'facility') {
    window.location.assign(`${NEXTGEN_APP_URL}/shifts/create${params}`);
  } else {
    window.location.assign(getUrlForMicroShiftAppUrl());
  }

  console.log('flags from ShiftExperienceRedirect', flags);
  return <></>;
};

ShiftExperienceRedirect.defaultProps = {
  record: null,
};

ShiftExperienceRedirect.propTypes = {
  flags: PropTypes.shape({
    nextGenShiftPosting: PropTypes.bool,
  }).isRequired,
  record: PropTypes.shape({}),
};

export default withLDConsumer()(ShiftExperienceRedirect);

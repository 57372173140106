import decodeJwt from 'jwt-decode';
import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS,
} from 'react-admin';

import mixpanel, { mixpanelTrack } from 'lib/utils/mixpanel';
import { client, gql } from './dataProvider';
import Fetcher from './Fetcher';
import Storage from './utils/localStorage';
import { NEXTGEN_APP_URL } from '../config';

const getFacilities = () => {
  const query = gql`
    query allFacilities {
      allFacilities {
        id
        name
        address
        areaName
        segmentName
        autoSelectType
        selectionType
        short_name
        email
        timezone
        image {
          src
        }
      }
    }
  `;

  return client.query({ query, fetchPolicy: 'no-cache' });
};

const checkForToken = (token) => new Promise((resolve, reject) => {
  if (!token) {
    window.location.href = `${NEXTGEN_APP_URL}/logout`;
    return reject(new Error('Token is missing.'));
  }

  const decodedToken = decodeJwt(token)['https://nursedash.com/custom'];
  return resolve({ decodedToken, token });
});

const login = (params, { store }) => checkForToken(params.token)
  .then(({ decodedToken, token }) => {
    Storage.setParam('user/token', token);
    Storage.setParam('user/id', decodedToken.iuaId);
    Storage.setParam('user/role', decodedToken.role);
    return { decodedToken, role: decodedToken.role };
  })
  .then(({ token, role }) => {
    if (role !== 'admin') {
      Storage.removeParam('admin/facility/list');
      Storage.removeParam('admin/facility/id');
      store.dispatch({ type: 'AUTH/LOGIN_SUCCESS', payload: { token } });
      return null;
    }

    return getFacilities();
  })
  .then((facilities) => {
    if (!facilities) {
      return null;
    }

    const { allFacilities } = facilities.data;

    Storage.setParam('admin/facility/list', JSON.stringify(allFacilities));

    const facilityId = Number(Storage.getParam('admin/facility/id')) || allFacilities[0].id;
    Storage.setParam('admin/facility/id', facilityId);

    const Me = allFacilities.find((facility) => facility.id === facilityId);

    return Fetcher.put('/authenticate', { params: { facilityId } }).then(
      ({ token }) => {
        Storage.setParam('user/token', token);
        return { data: { Me } };
      },
    );
  })
  .then((facility) => {
    if (facility) {
      return facility;
    }

    const query = gql`
        query Me {
          Me {
            ... on MeFacility {
              id
              name
              address
              areaName
              segmentName
              autoSelectType
              selectionType
              short_name
              email
              timezone
              image {
                src
              }
            }
          }
          IndividualizedUserAccount {
            isLegacyFacilityAdmin
          }
        }
      `;

    return client.query({ query, fetchPolicy: 'no-cache' });
  })
  .then(({ data }) => {
    const {
      id,
      name,
      short_name: shortName,
      email,
      timezone,
      image,
      address,
      areaName,
      segmentName,
      autoSelectType,
      selectionType,
    } = data.Me;

    const isLegacyFacilityAdmin = data.IndividualizedUserAccount ? data.IndividualizedUserAccount.isLegacyFacilityAdmin : false;
    const nextGenRole = isLegacyFacilityAdmin ? 'Admin' : 'Scheduler';

    try {
      mixpanel.identify(id);
      mixpanel.people.set({
        $name: name,
        $email: email,
        $location: address,
        $Area: areaName,
        $Segment: segmentName,
        $AutoselectType: autoSelectType,
        $SelectionType: selectionType,
      });

      if ((name || email) && process.env.INTERCOM_APP_ID) {
        global.window.Intercom('boot', {
          app_id: process.env.INTERCOM_APP_ID,
          user_id: id,
          name,
          email,
          Segment: segmentName,
          'Home Market': areaName,
          'Selection Type': selectionType,
          type: 'facility',
        });
      }
    } catch (e) {
      console.log(e);
    }

    Storage.setParam('facility/id', id);
    Storage.setParam('facility/name', name);
    Storage.setParam('facility/shortName', shortName);
    Storage.setParam('facility/email', email);
    Storage.setParam('facility/timezone', timezone);
    Storage.setParam('facility/image', image && image.src ? image.src : null);
    Storage.setParam('facility/location', areaName);
    Storage.setParam('facility/segment', segmentName);
    Storage.setParam('facility/autoselectType', autoSelectType);
    Storage.setParam('facility/selectionType', selectionType);
    Storage.setParam('facility/nextGenRole', nextGenRole);
    // trigger the change event to force the new shift posting URL to change
    setTimeout(() => Storage.trigger('facility/changed'), 1000);

    const token = Storage.getParam('user/token');
    store.dispatch({ type: 'AUTH/LOGIN_SUCCESS', payload: { token } });

    return Promise.resolve();
  })
  .then(() => {
    window.location.hash = '/Calendar';
    return Promise.resolve();
  })
  .catch((error) => {
    mixpanelTrack('LOGIN_FAILED', error);
    window.location.href = `${NEXTGEN_APP_URL}/logout`;
  });

export const logout = () => {
  const token = Storage.getParam('user/token');

  if (!token) {
    return Promise.resolve();
  }

  Storage.removeParam('user/token');
  Storage.removeParam('user/role');
  Storage.removeParam('user/id');
  Storage.removeParam('admin/facility/id');
  Storage.removeParam('admin/facility/list');
  Storage.removeParam('facility/id');
  Storage.removeParam('facility/name');
  Storage.removeParam('facility/shortName');
  Storage.removeParam('facility/email');
  Storage.removeParam('facility/timezone');
  Storage.removeParam('facility/image');
  Storage.removeParam('facility/list');
  Storage.removeParam('facility/location');
  Storage.removeParam('facility/segment');
  Storage.removeParam('facility/autoselectType');
  Storage.removeParam('facility/selectionType');

  global.window.Intercom('shutdown');

  window.location.href = `${NEXTGEN_APP_URL}/logout`;

  return Promise.resolve();
};

const error = () => {
  // ...
};

const adminFacilityIdFromUrl = (params, history) => {
  const queryString = window.location.hash.replace(/#[^?]+\?/, '') || '';
  const queryParams = {};
  queryString.split('&').forEach((param) => {
    const buffer = param.split(/=/, 2);
    if (buffer.length === 0) return;
    queryParams[buffer.shift()] = buffer.shift();
  });

  const { token, facilityId } = queryParams;
  if (!token || !facilityId) {
    return false;
  }

  const decodedToken = decodeJwt(token);
  Storage.setParam('user/token', token);
  Storage.setParam('user/id', decodedToken.id);
  Storage.setParam('user/role', decodedToken.role);

  return getFacilities().then((facilities) => {
    if (!facilities) {
      return null;
    }

    const { allFacilities } = facilities.data;
    Storage.setParam('admin/facility/list', JSON.stringify(allFacilities));

    history.replace('/');

    return facilityId;
  });
};

const check = async (params, { history }) => {
  const facilityIdFromUrl = await adminFacilityIdFromUrl(params, history);

  const waitBeforeUpdate = facilityIdFromUrl ? 1000 : 0;

  const changeFacilityId = params.changeFacilityId || facilityIdFromUrl;
  const role = Storage.getParam('user/role');

  if (role && history.location.pathname === '/') {
    history.replace('/Calendar');
  }

  if (!changeFacilityId || role !== 'admin') {
    return Storage.getParam('user/token')
      ? Promise.resolve()
      : Promise.reject();
  }

  if (!Storage.getParam('user/token')) {
    return Promise.reject();
  }

  Storage.removeParam('admin/facility/id');

  const query = gql`
    query Facility($id: Int) {
      Facility(id: $id) {
        id
        name
        address
        areaName
        segmentName
        autoSelectType
        selectionType
        short_name
        email
        timezone
        image {
          src
        }
      }
    }
  `;

  const variables = {
    id: Number(changeFacilityId),
  };

  const [{ data }, { token }] = await Promise.all([
    client.query({ query, variables, fetchPolicy: 'no-cache' }),
    Fetcher.put('/authenticate', {
      params: { facilityId: Number(changeFacilityId) },
    }),
  ]);

  const {
    id,
    name,
    short_name: shortName,
    email,
    timezone,
    image,
    address,
    areaName,
    segmentName,
    autoSelectType,
    selectionType,
  } = data.Facility;

  mixpanel.identify(id);
  mixpanel.people.set({
    $name: name,
    $email: email,
    $location: areaName,
    $Area: areaName,
    $Segment: segmentName,
    $AutoselectType: autoSelectType,
    $SelectionType: selectionType,
  });
  Storage.setParam('user/token', token);
  Storage.setParam('admin/facility/id', changeFacilityId);
  Storage.setParam('facility/id', id);
  Storage.setParam('facility/name', name);
  Storage.setParam('facility/shortName', shortName);
  Storage.setParam('facility/email', email);
  Storage.setParam('facility/timezone', timezone);
  Storage.setParam('facility/image', image && image.src ? image.src : null);
  Storage.setParam('facility/location', areaName);
  Storage.setParam('facility/segment', segmentName);
  Storage.setParam('facility/autoselectType', autoSelectType);
  Storage.setParam('facility/selectionType', selectionType);
  setTimeout(() => Storage.trigger('facility/changed'), waitBeforeUpdate);

  mixpanel.identify(id);
  mixpanel.people.set({
    $name: name,
    $email: email,
    $location: address,
    $Area: areaName,
    $Segment: segmentName,
    $AutoselectType: autoSelectType,
    $SelectionType: selectionType,
  });
  return Promise.resolve();
};

const getPermissions = () => {
  const role = Storage.getParam('user/role');
  return role ? Promise.resolve(role) : Promise.reject();
};

const restrictedTimezones = [
  'Asia/Brunei',
  'Asia/Choibalsan',
  'Asia/Hong_Kong',
  'Asia/Irkutsk',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Asia/Macau',
  'Asia/Makassar',
  'Asia/Manila',
  'Asia/Shanghai',
  'Asia/Singapore',
  'Asia/Taipei',
  'Asia/Ulaanbaatar',
  'Australia/Perth',
  'Asia/Krasnoyarsk',
  'Asia/Chita',
  'Asia/Urumqi',
];

export default (context) => (type, params) => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  switch (type) {
    case AUTH_LOGIN:
      if (restrictedTimezones.includes(tz)) {
        return error(params, context);
      }
      return login(params, context);
    case AUTH_LOGOUT:
      return logout();
    case AUTH_ERROR:
      return error(params, context);
    case AUTH_CHECK:
      return check(params, context);
    case AUTH_GET_PERMISSIONS:
      return getPermissions(params, context);
  }
  return Promise.reject(new Error('Unkown method'));
};

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NEXTGEN_APP_URL } from '../../config';
import { client, gql } from '../../lib/dataProvider';

const EditShift = (props) => {
  const [shiftData, setShiftData] = useState();

  useEffect(() => {
    const getTemplateData = gql`
      query NextGenShiftDetail($legacyShiftId: Int) {
        NextGenShiftDetail(legacyShiftId: $legacyShiftId) {
          shiftId
          facilityId
          unitName
          qualificationName
          positionName
          description
          clockInInstructions
          clockOutInstructions
        }
      }
    `;
    client
      .query({
        query: getTemplateData,
        variables: { legacyShiftId: Number(props.id) },
        fetchPolicy: "no-cache",
      })
      .then(({ data }) => {
        setShiftData(data);
      });
  }, []);

  useEffect(() => {
    if (shiftData && shiftData.NextGenShiftDetail.shiftId) {
      window.location.href = `${NEXTGEN_APP_URL}/edit-shift/${shiftData.NextGenShiftDetail.shiftId}/${props.id}`;
    }
  }, [shiftData]);

  return null;
};

EditShift.propsType = {
  id: PropTypes.string.isRequired,
};

export default EditShift;

import moment from 'moment-timezone';
import Storage from 'lib/utils/localStorage';
import currentTimezone from 'lib/utils/currentTimezone';

const validator = (values) => {
  const errors = {};
  const role = Storage.getParam('user/role');
  const timezone = currentTimezone();
  if (!values.unit) {
    errors.unit = ['Please change value'];
  }

  if (values.unit && !values.type) {
    errors.type = ['Please change value'];
  }

  if (!values.start_time) {
    errors.start_time = ['Please change value'];
  }

  if (!values.end_time) {
    errors.end_time = ['Please change value'];
  }

  if (values.start_time && values.end_time && moment(values.start_time) >= moment(values.end_time)) {
    errors.start_time = ['Start time must be earlier than end time'];
  }
  if (role !== 'admin' && values.start_time && moment(values.start_time).seconds(0).milliseconds(0) < moment().tz(timezone).seconds(0).milliseconds(0)) {
    errors.start_time = ['Start date can\'t be earlier than current date'];
  }

  return errors;
};

export default validator;

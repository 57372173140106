export const CALENDAR_RESET = 'CALENDAR_RESET';
export const CALENDAR_GET_DAYS = 'CALENDAR_GET_DAYS';
export const CALENDAR_SET_DAYS = 'CALENDAR_SET_DAYS';
export const CALENDAR_GET_EVENTS = 'CALENDAR_GET_EVENTS';
export const CALENDAR_SET_EVENTS = 'CALENDAR_SET_EVENTS';
export const CALENDAR_SET_FAVORITE_FLAG = 'CALENDAR_SET_FAVORITE_FLAG';

export const reset = (active) => ({
  type: CALENDAR_RESET,
  payload: { active },
});

export const getDays = (dateRangeStart, dateRangeEnd) => ({
  type: CALENDAR_GET_DAYS,
  payload: { dateRangeStart, dateRangeEnd },
});

export const setDays = (days, touchedDays) => ({
  type: CALENDAR_SET_DAYS,
  payload: { days, touchedDays },
});

export const getEvents = (dateRangeStart, dateRangeEnd, facilityFavorites) => ({
  type: CALENDAR_GET_EVENTS,
  payload: { dateRangeStart, dateRangeEnd, facilityFavorites },
});

export const setEvents = (events, touchedDays) => ({
  type: CALENDAR_SET_EVENTS,
  payload: { events, touchedDays },
});

export const setFavoritesFlag = (facilityFavorites) => ({
  type: CALENDAR_SET_FAVORITE_FLAG,
  payload: { facilityFavorites },
});

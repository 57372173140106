import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import { renderDateFormatterRange } from '../../lib/utils/render';

const styles = (theme) => ({
  list: {
    marginLeft: -theme.spacing.unit*2,
    marginRight: -theme.spacing.unit*2,
  },
  event: {
    marginBottom: theme.spacing.unit,
    borderRadius: theme.spacing.unit,
  },
  title: {
    color: 'white',
  }
});

const Event = withStyles(styles)(({ classes, data }) => {
  const { payload } = data;
  const text = `${renderDateFormatterRange(payload.timeStart, payload.timeEnd)} ${payload.assignedApplicantName ? payload.assignedApplicantName : ''}`;
  return <span className={classes.title}>{text}</span>;
});

const Events = withStyles(styles)(({ classes, list, onClick = () => {} }) => (
  <List className={classes.list}>
    {list.map((event) => (
      <ListItem button onClick={() => { onClick(event) }} key={event.id} className={classes.event} style={{ ...event.payload.style }}>
        <ListItemText
          primary={<Event data={event} />}
        />
      </ListItem>
    ))}
  </List>
));

Events.propTypes = {
  classes: PropTypes.object,
  list: PropTypes.array.isRequired,
};

class DialogMore extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      events: [],
      title: '',
      open: false,
    }
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleListItemClick = (event) => {
    this.setState({ open: false });
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  };

  show = (events, date) => {
    this.setState({ open: true, events, title: moment(date).format('dddd, D MMMM YYYY') });
  };

  render() {
    const { open, title, events } = this.state;

    return (
      <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <Events list={events} onClick={this.handleListItemClick} />
        </DialogContent>
      </Dialog>
    );
  }
}

DialogMore.propTypes = {
  onChange: PropTypes.func,
};

export default DialogMore;

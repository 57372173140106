import React from 'react';
import { Layout as AdminLayout } from 'react-admin';

import LayoutAppBar from './LayoutAppBar';
import LayoutMainMenu from './LayoutMainMenu';

const Layout = (props) => (
  <AdminLayout
    {...props}
    menu={LayoutMainMenu}
    appBar={LayoutAppBar}
  />
);

export default Layout;

import React from 'react';
import { pure } from 'recompose';

import { withStyles } from '@material-ui/core/styles';

import Avatar20 from '../../ui/Avatar20';

import '../styles.css';

const styles = theme => ({
  lastMessageBody: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  lastMessageDate: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginTop: '5px'
  },
  avatar: {
    marginTop: -2
  },
  name: {
    marginLeft: 5
  },
  text: {
    flex:1,
    marginLeft: 10
  },
});

const Message = pure(withStyles(styles)(({ avatar, name, text, date, classes }) => (
  <>
    <div className={classes.lastMessageBody}>
      <Avatar20 src={avatar} className={classes.avatar} />
      <div className={classes.name}>{name}:</div>
      <div className={classes.text}>{text}</div>
    </div>
    <div className={classes.lastMessageDate}>{date}</div>
  </>
)));

export default Message;

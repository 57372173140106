import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import PeopleIcon from '@material-ui/icons/People';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Message';
import WarningIcon from '@material-ui/icons/Warning';
import { convertDistance } from 'geolib';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import RelationIcon from '../applicant/RelationIcon';
import { setRelationToFacility } from '../../lib/redux/applicant/actions';
import { useRelationToast } from '../../context/ToastContext';
import ToastMessages from '../applicant/utils/ToastMessages';

const styles = (theme) => ({
  nurse: {
    marginLeft: -theme.spacing.unit * 3,
    marginRight: -theme.spacing.unit * 3,
  },
  nurseAvatar: {
    marginLeft: theme.spacing.unit,
  },
  nurseAction: {
    marginRight: theme.spacing.unit * 2,
  },
  positionType: {
    marginRight: theme.spacing.unit * 2,
  },
  nurseCard: {
    marginBottom: theme.spacing.unit * 2,
    borderBottom: 2,
  },
  nurseRelationAction: {
    marginRight: theme.spacing.unit * 2,
    marginTop: -theme.spacing.unit * 6,
  },
});

const Nurse = ({
  data, classes, history, dispatch, id, location,
}) => {
  const { handleOpen } = useRelationToast();
  const { facilityFavorites } = useFlags();
  const { pathname } = location;
  const resource = pathname.match(/\/OverviewShift\/([0-9]+)\/show/i) ? 'OverviewShift' : 'CompletedShift';
  const handleOpenApplicant = useCallback(() => {
    history.push(`/Applicant/${data.applicantId}/show`);
  }, [data.applicantId]);

  const handleOpenMessages = useCallback(() => {
    history.push(`/Messages/${data.channelId}/show`);
  }, [data.applicantId]);

  const avatarSrc = data.photo || data.image;
  const { isOvertime } = data;

  const undoFavorite = useCallback((action) => {
    dispatch(
      setRelationToFacility(
        data.nurseId,
        'facility-favorite',
        action,
        id,
        resource,
      ),
    );
  }, [data]);

  const showToast = useCallback(() => {
    const action = data.relation === 'facility-favorite' ? 'remove' : 'add';
    const message = ToastMessages['facility-favorite'][action];
    handleOpen({
      relation: data.relation,
      message: `${data.name} ${message.message}`,
      variant: message.variant,
      title: message.title,
    }, undoFavorite);
  }, [data]);

  const handleFavorite = () => {
    dispatch(
      setRelationToFacility(
        data.nurseId,
        'facility-favorite',
        data.relation === 'facility-favorite' ? 'remove' : 'add',
        id,
        resource,
        null,
        showToast,
      ),
    );
  };

  return (
    <ListItem button onClick={handleOpenApplicant}>
      <ListItemAvatar className={classes.nurseAvatar}>
        {isOvertime
          ? <WarningIcon style={{ color: 'brown', fontSize: 60 }} />
          : avatarSrc
            ? <Avatar src={avatarSrc} />
            : <Avatar><PeopleIcon /></Avatar>}
      </ListItemAvatar>
      <ListItemText
        primary={`${data.name}${data.credentialsNames ? `, ${data.credentialsNames}` : ''}`}
        secondary={(
          <div className={classes.nurseCard}>
            <Typography color="textSecondary" className={classes.positionType}>
              {'Position Type: '}
              {(data.positionType || []).join(', ') || 'not set'}
            </Typography>
            <Typography color="textSecondary">
              {'Rating: '}
              {data.rating ? Number(data.rating).toFixed(1) : 'No rating yet'}
            </Typography>

            <Typography color="textSecondary">
              {'Distance: '}
              {data.distance ? `${Number(convertDistance(data.distance, 'mi')).toFixed(1)} miles away` : 'Not available'}
            </Typography>
          </div>
        )}
      />
      <ListItemSecondaryAction className={classes.nurseAction}>
        {data.channelId && (
        <IconButton
          disabled={data.status === 'unavailable'}
          aria-label="Start chat"
          onClick={handleOpenMessages}
        >
          <DeleteIcon />
        </IconButton>
        )}
      </ListItemSecondaryAction>
      { facilityFavorites
      && (
      <ListItemSecondaryAction className={classes.nurseRelationAction}>
        <IconButton
          onClick={handleFavorite}
          // disabled={data.relation === 'do-not-return'}
        >
          <RelationIcon showEmpty relation={data.relation} />
        </IconButton>
      </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

Nurse.propTypes = {
  classes: PropTypes.shape({
    nurseAvatar: PropTypes.string,
    nurseCard: PropTypes.string,
    positionType: PropTypes.string,
    nurseAction: PropTypes.string,
    nurseRelationAction: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  data: PropTypes.shape({
    id: PropTypes.string,
    applicantId: PropTypes.number,
    nurseId: PropTypes.number,
    channelId: PropTypes.number,
    status: PropTypes.string,
    distance: PropTypes.string,
    photo: PropTypes.string,
    isOvertime: PropTypes.bool,
    credentialsNames: PropTypes.string,
    image: PropTypes.shape({
      src: PropTypes.string,
    }),
    name: PropTypes.string,
    credentials: PropTypes.arrayOf(PropTypes.shape({})),
    positionType: PropTypes.arrayOf(PropTypes.string),
    rating: PropTypes.number,
    relation: PropTypes.string,
  }),
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.shape({
      match: PropTypes.func,
    }),
  }).isRequired,
};

Nurse.defaultProps = {
  classes: null,
  history: null,
  data: null,
};

export default compose(
  connect(), withStyles(styles),
)(withRouter(Nurse));

import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import Typography from '@material-ui/core/Typography';

const Applicants = ({ record }) => {
  if (!record || record.applicantCount === null) {
    return <Typography color="textSecondary">Loading applicants</Typography>;
  }

  const unavailableCount = record.applicantList.filter((item) => item.status === 'unavailable').length;

  if (record.applicantCount - unavailableCount === 0 || record.applicantList.length - unavailableCount === 0) {
    return <Typography color="textSecondary">No applicants</Typography>;
  }

  const confirmed = record.applicantList.find((item) => item.status === 'confirmed');

  if (!confirmed) {
    return (
      <Typography color="textSecondary">
        {record.applicantCount - unavailableCount}
        {' '}
        {pluralize('applicant', record.applicantCount - unavailableCount)}
      </Typography>
    );
  }

  const moreApplicants = record.applicantCount - unavailableCount - 1 > 0
    ? `, and ${record.applicantCount - unavailableCount - 1} more ${pluralize('applicant', record.applicantCount - unavailableCount - 1)}`
    : '';

  return (
    <Typography color="textSecondary">
      {'Selected applicant: '}
      {confirmed.name}
      {moreApplicants}
    </Typography>
  );
};

Applicants.propTypes = {
  record: PropTypes.shape({
    applicantCount: PropTypes.number,
    applicantList: PropTypes.arrayOf(PropTypes.shape({
      status: PropTypes.string,
    })),
  }),
};

Applicants.defaultProps = {
  record: null,
};

export default Applicants;

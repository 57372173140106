import React from 'react';
import PropTypes from 'prop-types';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

class CustomToolbar extends React.Component {
    navigate = (action) => {
      this.props.onNavigate(action);
    };

      view = (viewName) => {
        this.props.onView(viewName);
      };

      redirectToWeeklyView = () => {
        const { flags } = this.props;
        if (flags.nextGenCalendar) {
          window.location.href = 'https://nextgen-facility.nursedash.com/Weekly';
        } else {
          this.view('week');
        }
      }; 

      render() {
        return (
          <div className="rbc-toolbar">
            <span className="rbc-btn-group">
              <button type="button" onClick={() => this.navigate('TODAY')}>Today</button>
              <button type="button" onClick={() => this.navigate('PREV')}>Back</button>
              <button type="button" onClick={() => this.navigate('NEXT')}>Next</button>
            </span>
            <span className="rbc-toolbar-label">{this.props.label}</span>
            <span className="rbc-btn-group">
              <button type="button" onClick={() => this.view('month')}>Month</button>
              <button
                type="button"
                onClick={this.redirectToWeeklyView}
                className="rbc-btn rbc-btn-view"
                style={{ fontSize: '13px' }}
              >
                Week
              </button>
              <button type="button" onClick={() => this.view('agenda')}>Agenda</button>
            </span>
          </div>
        );
      }
}

export default withLDConsumer()(CustomToolbar);

CustomToolbar.propTypes = {
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  flags: PropTypes.shape({
    nextGenCalendar: PropTypes.bool
  }).isRequired,
};
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  pages: {
    marginRight: theme.spacing.unit,
  },
  toolbar: {
    justifyContent: 'flex-end',
    borderTop: '1px solid #dfdfdf',
  },
  button: {
    marginTop: '0.1em',
  },
});

const Pagination = ({
  page, perPage, total, setPage, classes, onPage,
}) => {
  const nbPages = Math.ceil(total / perPage) || 1;

  const handlePrevClick = useCallback(() => {
    setPage(page - 1);
    if (onPage) {
      onPage(page - 1);
    }
  }, [page]);

  const handleNextClick = useCallback(() => {
    setPage(page + 1);
    if (onPage) {
      onPage(page + 1);
    }
  }, [page]);

  if (nbPages <= 1) {
    return null;
  }

  return (
    <Toolbar className={classnames(classes.toolbar, 'pagination-toolbar')}>
      <Typography color="textSecondary" variant="caption" className={classes.pages}>
        {`${page * perPage - perPage + 1} - ${page * perPage} of ${total}`}
      </Typography>
      <Button key="prev" disabled={page === 1} onClick={handlePrevClick} size="small">
        <ChevronLeft />
        {' '}
        <Typography variant="caption" className={classes.button}>Prev</Typography>
      </Button>
      <Button key="next" disabled={page === nbPages} onClick={handleNextClick} size="small">
        <Typography variant="caption" className={classes.button}>Next</Typography>
        {' '}
        <ChevronRight />
      </Button>
    </Toolbar>
  );
};

Pagination.propTypes = {
  page: PropTypes.number,
  perPage: PropTypes.number,
  total: PropTypes.number,
  setPage: PropTypes.func,
  classes: PropTypes.shape({
    toolbar: PropTypes.string,
    pages: PropTypes.string,
    button: PropTypes.string,
  }),
  onPage: PropTypes.func,
};

Pagination.defaultProps = {
  page: null,
  perPage: null,
  total: null,
  setPage: null,
  classes: null,
  onPage: null,
};

export default withStyles(styles)(Pagination);

/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  SimpleForm, LongTextInput, RadioButtonGroupInput, FormDataConsumer,
} from 'react-admin';
import { REDUX_FORM_NAME } from 'ra-core';
import { reset } from 'redux-form';
import gql from 'graphql-tag';

import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';
import RatingInput from 'ui/RatingInput';
import ShiftReviewDialog from 'ui/ShiftReviewDialog';
import * as shiftAction from 'lib/redux/shifts/actions';
import { client } from 'lib/dataProvider';
import { useFlags } from 'launchdarkly-react-client-sdk';
import validator from './validator';
import ShiftDetails from './ShiftDetails';
import ReviewToolbarWithConfirmation from './ReviewToolbarWithConfirmation';
import ToastMessages from '../../applicant/utils/ToastMessages';
import { useRelationToast } from '../../../context/ToastContext';
import { setRelationToFacility } from '../../../lib/redux/applicant/actions';

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    maxHeight: `calc(100vh - ${theme.spacing.unit * 2}px);`,
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
    paddingBottom: 0,

    '&>div:first-child': {
      paddingTop: 0,
      flex: 1,
      overflow: 'auto',
    },
  },
  linkButton: {
    background: 'none!important',
    border: 'none',
    padding: '0!important',
    color: '#3f51b5',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: 'none',
  };
};

const ShiftReviewModal = ({
  dispatch, shift, isOpen, handleClose, classes,
}) => {
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showHasReviewDialog, setShowHasReviewDialog] = useState(null);
  const { handleOpen } = useRelationToast();
  const assignedNurse = (shift.applicantList || []).find(({ nurseId }) => nurseId === shift.assigned_nurse_id);
  const { facilityFavorites } = useFlags();
  const getShiftData = (shiftId) => (
    new Promise((resolve, reject) => {
      const query = gql`
        query getCompletedShift($id: Int) {
          CompletedShift(id: $id) {
            id
            checkInTime
            checkInSupervisorName
            checkInSupervisorSignature {
              src
            }
            checkOutTime
            checkOutSupervisorName 
            checkOutSupervisorSignature {
              src
            }
            breakTime
            hasBreakTime
            review {
              overallRating
              nurseSkills
              customerService
              comment
            }
          }
        }
      `;
      const variables = {
        id: shiftId,
      };
      client.query({ query, variables, fetchPolicy: 'no-cache' })
        .then(resolve)
        .catch(reject);
    })
  );

  const setFormFields = async (currentShift) => {
    setLoading(true);
    const { data } = await getShiftData(currentShift.id);
    const shiftData = data.CompletedShift;

    if (shiftData.review) {
      setShowHasReviewDialog(true);
    }

    setFormData({
      checkInTime: shiftData.checkInTime || currentShift.start_time,
      checkOutTime: shiftData.checkOutTime || currentShift.end_time,
      checkInSupervisorName: shiftData.checkInSupervisorName || null,
      checkOutSupervisorName: shiftData.checkOutSupervisorName || null,
      checkInSupervisorSignature: shiftData.checkInSupervisorSignature || {},
      checkOutSupervisorSignature: shiftData.checkOutSupervisorSignature || {},
      overallRating: shiftData.review?.overallRating,
      nurseSkills: shiftData.review?.nurseSkills,
      customerService: shiftData.review?.customerService,
      comment: shiftData.review?.comment,
      breakTime: shiftData.breakTime,
      hasBreakTime: shiftData.hasBreakTime,
      relation: assignedNurse?.relation || null,
      name: assignedNurse?.name || null,
      nurseId: assignedNurse?.nurseId || null,
    });

    setLoading(false);
  };

  useEffect(() => {
    if (!shift) {
      return;
    }

    setFormFields(shift);
    dispatch(reset(REDUX_FORM_NAME));
  }, []);

  const handleHasReviewClose = useCallback(() => {
    setShowHasReviewDialog(false);
    handleClose();
  }, []);

  const checkShowConfirm = useCallback(() => {
    // only show DNR confirmation if the user selected a relation other validation is handled by the form
    if (formData?.relation) {
      return formData?.relation === 'do-not-return';
    }
    return false;
  }, [formData]);

  const undoRelationToFacility = useCallback((action, relation) => {
    if (relation && assignedNurse.nurseId) {
      dispatch(
        setRelationToFacility(
          assignedNurse.nurseId,
          relation,
          'remove',
          shift.id,
          'CompletedShift',
        ),
      );
    }
  }, []);
  const formDataExtractor = ({ formData: newFormData }) => {
    if (JSON.stringify(formData) !== JSON.stringify(newFormData)) {
      setFormData(newFormData);
    }
  };

  const showToast = useCallback((relation) => {
    if (relation) {
      const message = ToastMessages[relation].add;
      handleOpen({
        relation,
        message: `${assignedNurse.name} ${message.message}`,
        variant: message.variant,
        title: message.title,
      }, undoRelationToFacility);
    }
    setTimeout(handleClose, 500);
  }, []);

  const handleSubmit = useCallback((record, reason) => {
    const {
      checkInTime, checkOutTime, hasBreakTime, relation,
    } = record;
    const review = {
      comment: record.comment,
      overallRating: record.overallRating,
      customerService: record.customerService,
      nurseSkills: record.nurseSkills,
    };

    dispatch(shiftAction.sendReview(shift.id, review, checkInTime, checkOutTime, hasBreakTime, showToast, relation, reason));
  }, []);

  const getRelationChoices = () => {
    const choices = [];
    if (assignedNurse.relation !== 'do-not-return') {
      choices.push({ id: 'do-not-return', name: 'Add clinician to Do Not Return list' });
    }
    if (assignedNurse.relation !== 'facility-favorite') {
      choices.push({ id: 'facility-favorite', name: 'Add clinician to Favorites list' });
    }
    return choices;
  };

  const isShowConfirm = checkShowConfirm();
  const relationChoices = getRelationChoices();

  if (!isOpen) {
    return null;
  }

  if (loading) {
    return null;
  }

  return (
    <Modal
      aria-labelledby="shift-review-modal"
      open={isOpen}
      onClose={handleClose}
    >
      <div style={getModalStyle()} className={classes.paper}>
        <ShiftDetails shift={shift} onClose={handleClose} />
        <SimpleForm record={formData} save={(record) => false} toolbar={<ReviewToolbarWithConfirmation isShowConfirm={isShowConfirm} onSubmit={handleSubmit} />} validate={validator} className={classes.form}>
          <FormDataConsumer>{formDataExtractor}</FormDataConsumer>
          <RatingInput source="overallRating" label="Overall Rating" isRequired />
          <RatingInput source="customerService" label="Customer Service" />
          <RatingInput source="nurseSkills" label="Clinician Skills" />
          { facilityFavorites && (
          <RadioButtonGroupInput
            source="relation"
            choices={relationChoices}
            label=""
          />
          )}
          <LongTextInput rows={3} source="comment" label="Comments" />
          <ShiftReviewDialog
            isOpen={showHasReviewDialog}
            title="This shift has already been reviewed"
            message="You cannot review a shift that has already been reviewed"
            onClose={handleHasReviewClose}
          />
        </SimpleForm>
      </div>
    </Modal>
  );
};

ShiftReviewModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    paper: PropTypes.string,
    form: PropTypes.string,
  }).isRequired,
  shift: PropTypes.shape({
    id: PropTypes.number,
    checkInTime: PropTypes.string,
    checkOutTime: PropTypes.string,
    start_time: PropTypes.string,
    end_time: PropTypes.string,
    assigned_nurse_id: PropTypes.number,
    applicantList: PropTypes.arrayOf(PropTypes.shape({
      nurseId: PropTypes.number,
    })),
    review: PropTypes.shape({
      overallRating: PropTypes.number,
      nurseSkills: PropTypes.number,
      customerService: PropTypes.number,
      comment: PropTypes.string,
      relation: PropTypes.string,
    }),
  }),
};

ShiftReviewModal.defaultProps = {
  shift: null,
};

export default compose(
  connect(),
  withStyles(styles),
)(ShiftReviewModal);

// @flow
import React from 'react';
import { Field } from 'redux-form';
import moment from 'moment-timezone';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = (theme) => ({
  wrapper: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
    width: '100%',
    maxWidth: theme.spacing.unit * 32,
  },
  fullWidth: {
    maxWidth: 'none',
  },
  field: {
    width: '100%',
  },
  fieldEdit: {
    width: '90%',
  },
  disabled: {
    opacity: 0.6,
  },
  linkButton: {
    background: 'none!important',
    border: 'none',
    padding: '0!important',
    color: '#3f51b5',
    cursor: 'pointer'
  },
});

export class DateTimeComponentInput extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    const {
      input, timezone, onFieldChange, disabled,
    } = this.props;

    if (disabled) {
      return;
    }

    const date = moment.tz(moment(event.target.value).format('YYYY-MM-DDTHH:mm'), timezone);
    if (date.isValid()) {
      input.onChange(date.toISOString());
    }
    // Only call this if passed in explicitly as a function
    if (typeof onFieldChange === 'function') {
      onFieldChange(event);
    }
  }

  render() {
    const {
      meta, classes, input, timezone, type = 'datetime-local', label, disabled, hasEdit
    } = this.props;

    if (typeof meta === 'undefined') {
      throw new Error(
        "The SelectInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details.",
      );
    }

    const { touched, error, helperText = false } = meta;

    return (
      <TextField
        disabled={disabled}
        label={label}
        type={type}
        value={input.value ? moment(input.value).tz(timezone).format('YYYY-MM-DDTHH:mm') : null}
        onChange={this.onChange}
        error={!!(touched && error)}
        helperText={(touched && error) || helperText}
        className={classnames(hasEdit ? classes.fieldEdit : classes.field , disabled && classes.disabled)}
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }
}

const parse = (date) => date;

const DateTimeSimpleInput = withStyles(styles)(({
  classes, source, name, label, timezone, type, fullWidth, onChange, disabled, hasEdit = false,
}) => {
  return (
    <div className={classnames(classes.wrapper, { [classes.fullWidth]: fullWidth })}>
      <Field
        component={DateTimeComponentInput}
        name={source || name}
        parse={parse}
        label={label || source}
        timezone={timezone}
        type={type}
        classes={classes}
        onFieldChange={onChange}
        disabled={disabled}
        hasEdit={hasEdit}
      />
      {
        hasEdit && <button onClick={onChange} className={classes.linkButton} type="button">Edit</button>
      }
    </div>
  );
});

export default DateTimeSimpleInput;

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toggleSidebar, userLogout, refreshView } from 'react-admin';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import MailIcon from '@material-ui/icons/Mail';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import MoreIcon from '@material-ui/icons/MoreVert';
import RefreshIcon from '@material-ui/icons/Refresh';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { withStyles } from '@material-ui/core/styles';

import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { bindActionCreatorsObjective } from 'lib/redux/utils';
import Storage, { LS_SET } from '../../lib/utils/localStorage';
import mixpanel, { mixpanelTrack } from '../../lib/utils/mixpanel';

import LayoutFacilityChange from './LayoutFacilityChange';
import * as systemActions from '../../lib/redux/system/actions';

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      color: 'white',
    },
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
});

const ItemIconButton = withStyles(styles)(({
  title, icon, badgeContent, onClick, classes,
}) => (
  <>
    <IconButton color="inherit" onClick={onClick}>
      {Boolean(badgeContent) && (
      <Badge className={classes.margin} badgeContent={badgeContent} color="secondary">
        {icon}
      </Badge>
      )}
      {!badgeContent && icon}
    </IconButton>
    {Boolean(title) && <p>{title}</p>}
  </>
));

class LayoutAppBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      mobileMoreAnchorEl: null,
    };

    this.renderMenu = this.renderMenu.bind(this);
    this.renderMobileMenu = this.renderMobileMenu.bind(this);
  }

  componentDidMount() {
    Storage.on(LS_SET, this.update);
  }

  componentWillUnmount() {
    Storage.off(LS_SET, this.update);
  }

  update = () => {
    this.forceUpdate();
  };

  onProfileMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  onMenuClose = () => {
    this.setState({ anchorEl: null });
    this.onMobileMenuClose();
  };

  onMobileMenuOpen = (event) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  onMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  onMessagesClick = () => {
    const { history, systemActions } = this.props;
    history.push('/Messages');
    systemActions.trackOpenScreenMessages('header');
  };

  onRefreshClick = () => {
    const { actionRefreshView } = this.props;
    mixpanelTrack('TOP_REFRESH');
    actionRefreshView();
  };

  onLogoutClick = () => {
    const { actionUserLogout } = this.props;
    mixpanelTrack('TOP_LOGOUT');
    actionUserLogout();
    this.onMenuClose();
  };

  onAttachFileClick = () => {
    const { history } = this.props;

    const id = Storage.getParam('facility/id');

    mixpanelTrack('TOP_SETTINGS');

    history.push(`/Facility/${id}`);
  };

  renderMenu() {
    const { anchorEl } = this.state;
    const isMenuOpen = Boolean(anchorEl);
    return (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.onMenuClose}
      >
        <MenuItem onClick={this.onLogoutClick}>Logout</MenuItem>
      </Menu>
    );
  }

  renderMobileMenu() {
    const { unreadCount } = this.props;
    const { mobileMoreAnchorEl } = this.state;
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const role = Storage.getParam('user/role');

    return (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.onMobileMenuClose}
      >
        {role === 'admin' && (
          <MenuItem>
            <LayoutFacilityChange title="Facility" />
          </MenuItem>
        )}
        {role !== 'admin' && (
          <MenuItem>
            <ItemIconButton
              title="Messages"
              icon={<MailIcon />}
              badgeContent={unreadCount}
              onClick={this.onMessagesClick}
            />
          </MenuItem>
        )}
        <MenuItem>
          <ItemIconButton
            title="Refresh"
            icon={<RefreshIcon />}
            onClick={this.onRefreshClick}
          />
        </MenuItem>
        <MenuItem>
          <ItemIconButton
            title="Orientation materials"
            icon={<AttachFileIcon />}
            onClick={this.onAttachFileClick}
          />
        </MenuItem>
        <MenuItem>
          <ItemIconButton
            title="Logout"
            icon={<LogoutIcon />}
            onClick={this.onLogoutClick}
          />
        </MenuItem>
      </Menu>
    );
  }

  render() {
    const {
      actionToggleSidebar, actionUserLogout, actionRefreshView, staticContext,
      classes, unreadCount, flags, ...props
    } = this.props;

    const { developerMode } = flags;
    const role = Storage.getParam('user/role');
    const token = Storage.getParam('user/token');

    return (
      <>
        <AppBar {...props} position="sticky">
          <Toolbar variant="dense">
            <IconButton className={classes.menuButton} color="inherit" aria-label="Open drawer" onClick={actionToggleSidebar}>
              <MenuIcon />
            </IconButton>
            <Typography variant="title" className={classes.title}>
              <span>
                {Storage.getParam('facility/name')}
                :
                {' '}
              </span>
              <span id="react-admin-title" />
            </Typography>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <LayoutFacilityChange />
              {developerMode && <ItemIconButton icon={<VpnKeyIcon />} badgeContent={unreadCount} onClick={() => { navigator.clipboard.writeText(token); }} />}
              {role !== 'admin' && (
                <ItemIconButton icon={<MailIcon />} badgeContent={unreadCount} onClick={this.onMessagesClick} />
              )}
              <ItemIconButton icon={<RefreshIcon />} onClick={this.onRefreshClick} />
              <ItemIconButton icon={<AttachFileIcon />} onClick={this.onAttachFileClick} />
              <ItemIconButton icon={<LogoutIcon />} onClick={this.onLogoutClick} />
            </div>
            <div className={classes.sectionMobile}>
              <IconButton aria-haspopup="true" onClick={this.onMobileMenuOpen} color="inherit">
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {this.renderMenu()}
        {this.renderMobileMenu()}
      </>
    );
  }
}

const mapActionToProps = (dispatch) => ({
  actionToggleSidebar: () => dispatch(toggleSidebar()),
  actionUserLogout: () => dispatch(userLogout('/NextGenLogout')),
  actionRefreshView: () => dispatch(refreshView()),
  ...bindActionCreatorsObjective({
    systemActions,
  }, dispatch),
});

export default connect(
  (state) => ({
    unreadCount: 0,
  }),
  mapActionToProps,
)(withRouter(withStyles(styles)(withLDConsumer()(LayoutAppBar))));

import { call, put } from 'redux-saga/effects';
import gql from 'graphql-tag';
import { showNotification } from 'ra-core';
import { client } from '../../../dataProvider';
import {
  shiftPolicy, GET_SHIFT_POLICY,
} from '../actions';

export const action = GET_SHIFT_POLICY;

export default function* ({ payload = {} }) {
  const { id } = payload;

  let response;

  try {
    response = yield call(client.query, {
      query: gql`
            query CancellationPolicy($id: Int) {
                CancellationPolicy(id: $id) {
                    id
                    hoursToPay
                    cancellationPolicy
                }
            }
        `,
      variables: { id: Number(id) },
      fetchPolicy: 'no-cache',

    });
  } catch (error) {
    yield put(showNotification(error.message, 'error'));
  }

  if (response?.data.CancellationPolicy) {
    const { hoursToPay, cancellationPolicy } = response.data.CancellationPolicy;
    yield put(shiftPolicy(cancellationPolicy, hoursToPay));
  }
}

import React from 'react';

const UrlField = ({domain = '', text, source, record, renderText, renderLink, ...props}) => {
  let resultLink = record[source];
  let resultText = decodeURIComponent(text || record[source] || '');

  if (renderText) {
    resultText = renderText(resultText);
  }

  if (renderLink) {
    resultLink = renderLink(resultLink);
  }

  const href = `${domain}${resultLink}`;

  if (!href) {
    return null;
  }

  return (
    <a className="link" href={href} target="_blank" rel="noopener noreferrer">{resultText}</a>
  );
};

export default UrlField;

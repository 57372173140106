import { gql } from 'apollo-boost';
import { call, put, cancel } from 'redux-saga/effects';
import { client } from '../../../dataProvider';
import {
  REQUEST_CHANNELS,
  responseChannels,
} from '../actions';

export const action = REQUEST_CHANNELS;

export default function* () {
  yield put({ type: 'RA/FETCH_START' });

  const query = gql`
    query getAllChannels {
      allChannels {
        id
        name
        members {
          id
          unreadCount
          user {
            id
            name
            userId
            userModel
            image{
              src
            }
          }
        }
        lastMessage {
          id
          text
          createdAt
          member {
            id
          }
        }
        applicant {
          shift {
            id
            role
            start_time
            end_time
          }
        }
        createdAt
      }
    }
  `;

  const variables = {};

  let response = {};

  try {
    response = yield call(client.query, { query, variables, fetchPolicy: 'no-cache' });
  } catch (error) {
    console.warn(error.message);
    yield put({ type: 'RA/FETCH_END' });
    yield put(responseChannels([]));
    yield cancel();
  }

  const { data } = response;

  if (data && data.allChannels) {
    yield put(responseChannels(data.allChannels));
  }

  yield put({ type: 'RA/FETCH_END' });
}

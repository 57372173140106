import React from 'react';
import { API_HOST } from '../../config';

export const defaultUserLogo = `${API_HOST}/images/default-user-logo.png`;

const styles = {
  wrapper: {
    display: 'flex',
    width: `${44 / 16}em`,
    height: `${44 / 16}em`,
    borderRadius: '50%',
    backgroundImage: `url(${defaultUserLogo})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    overflow: 'hidden',
  },
  image: {
    flex: 1,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '50%',
    boxShadow: `inset 0 0 ${2 / 16}em rgba(0,0,0,0.1)`,
  }
};

const Avatar = ({src, style = {}}) => (
  <div style={{...styles.wrapper, ...style}}><div style={{...styles.image, backgroundImage: `url(${src})`}} /></div>
);

export default Avatar;

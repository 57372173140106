import React from 'react';
import PropTypes from 'prop-types';
import {
  CardActions, ListButton, RefreshButton, ShowButton,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  actions: {
    zIndex: 2,
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const Actions = ({ basePath, data, classes }) => {
  if (!data) {
    return null;
  }
  return (
    <CardActions className={classes.actions}>
      <ShowButton basePath={basePath} record={data} />
      <ListButton basePath={basePath} record={data} />
      <RefreshButton basePath={basePath} record={data} />
    </CardActions>
  );
};

Actions.propTypes = {
  data: PropTypes.shape({}),
  basePath: PropTypes.string,
  classes: PropTypes.shape({
    actions: PropTypes.string,
  }),
};

Actions.defaultProps = {
  data: null,
  basePath: null,
  classes: null,
};

export default withStyles(styles)(Actions);

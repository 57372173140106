import React from 'react';
import { pure } from 'recompose';

import { withStyles } from '@material-ui/core/styles';

import '../styles.css';

const styles = theme => ({
  container: {
  },
  header: {
    display: 'flex',
    position: 'relative',
  },
  name: {
    flex: 1,
  },
  unconsumed: {
    display: 'flex',
    position: 'absolute',
    right: 0,
    width: 'calc(22em / 16)',
    height: 'calc(22em / 16)',
    backgroundColor: '#e95420',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
  },
  unconsumedText: {
    display: 'block',
    fontSize: 'calc(13em / 16)',
    color: '#fff',
    fontWeight: 'bold',
  },
  message: {
    fontSize: '0.9em',
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0.6em 0 0.4em',
  },
});

const ItemBody = pure(withStyles(styles)(({ name, message, unconsumedCount, classes }) => (
  <div className={classes.container}>
    <span className={classes.header}>
      <span className={classes.name}>{name}</span>
      {unconsumedCount > 0 && <span className={classes.unconsumed}>
        <span className={classes.unconsumedText}>{unconsumedCount}</span>
      </span>}
    </span>
    <div className={classes.message}>{message}</div>
  </div>
)));

export default ItemBody;

/* eslint-disable camelcase */
import getUrlForMicroShiftApp from 'utils/getUrlForMicroShiftApp';

export const omitFields = ({
  id, rate, net_pay, payment, assignedNurse, assigned_nurse_id, applicantCount, applicantList, prevStatus, status, cancellationPolicyId, createdAt, updatedAt, deletedAt, cancelledAt, ...record
}) => record;

export const cloneShift = (shiftId, nextGenShiftPosting) => {
  if (!nextGenShiftPosting) {
    if (shiftId) {
      const url = `${getUrlForMicroShiftApp}/copy/${shiftId}`;
      window.location.href = url;
    }
  }
};

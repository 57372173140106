import React from 'react';
import { NumberField, translate } from 'react-admin';

const MoneyField = ({record, source, translate, ...props}) => {
  const path = source.split(/\./);
  let point = record;
  path.forEach(current => point = point[current]);

  if (!point && point !== 0) {
    return <div style={{ color: '#ccc' }}>не задано</div>;
  }

  return <NumberField
    {...props}
    record={record}
    source={source}
    locales={translate('currency.type')}
    options={{ style: 'currency', currency: translate('currency.code') }}
  />;
};

export default translate(MoneyField);

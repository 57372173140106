import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Nurse from './Nurse';

const styles = (theme) => ({
  nurse: {
    marginLeft: -theme.spacing.unit * 3,
    marginRight: -theme.spacing.unit * 3,
  },
});
const Nurses = ({
  title, list, classes, id,
}) => {
  const result = (list ?? []).map((item) => <Nurse key={item.id} data={item} id={id} />);

  return (
    <>
      <Typography variant="subheading">{title}</Typography>
      <List dense={false} className={classes.nurse}>{result}</List>
    </>
  );
};

Nurses.propTypes = {
  title: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
  classes: PropTypes.shape({
    nurse: PropTypes.string,
  }),
  id: PropTypes.number,
};

Nurses.defaultProps = {
  title: null,
  list: null,
  classes: null,
  id: null,
};

export default withStyles(styles)(Nurses);

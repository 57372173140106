import React from 'react';
import {
  FunctionField,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { API_HOST } from '../../config';

const Resume = ({ record }) => {
  const exceptionUrl = [806, 805, 804, 427].includes(Number(record.facilityId)) ? `${API_HOST}/api/firebasestorage/retrieve?fileTitle=` : null;
  if (!record || !record.resume) {
    return (
      <FunctionField
        record={record}
        source="resume"
        render={() => <Typography color="textSecondary">no profile link</Typography>}
      />
    );
  }

  return (
    <FunctionField
      record={record}
      source="resume"
      render={(rec) => (
        <a
          href={
          exceptionUrl ? `${exceptionUrl}${rec.resume.title}`
            : rec.resume.src
        }
          rel="noopener noreferrer"
          target="_blank"
        >
          View Profile
        </a>
      )}
    />
  );
};
Resume.propTypes = {
  record: PropTypes.shape({
    facilityId: PropTypes.number,
    resume: PropTypes.shape({
      src: PropTypes.string,
    }),
  }).isRequired,
};
export default Resume;

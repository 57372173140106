import moment from 'moment-timezone';

moment.prototype.getHours = function () {
  return Number(this.format('HH'));
};

moment.prototype.getMinutes = function () {
  return Number(this.format('MM'));
};

moment.prototype.getSeconds = function () {
  return Number(this.format('SS'));
};

moment.prototype.getMilliseconds = function () {
  return 0;
};
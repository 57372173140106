/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React from 'react';
import { withRouter } from 'react-router';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import {
  FormControl, InputLabel, MenuItem, Select, Typography,
} from '@material-ui/core';

import classnames from 'classnames';
import { getShiftFacilityPolicy } from 'lib/redux/facilityPolicy/actions';
import Fetcher from '../../lib/Fetcher';
import { bindActionCreatorsObjective } from '../../lib/redux/utils';

import * as systemActions from '../../lib/redux/system/actions';

const styles = (theme) => ({
  button: {
    display: 'flex',
    justifyContent: 'center',
  },
  blueButton: {
    position: 'relative',
    left: '15px',
    bottom: '3px',
  },
  redButton: {
    position: 'relative',
    right: '15px',
    color: '#fff',
    '&:hover': {
    },
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  processWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: 0,
    top: 0,
    background: 'rgba(255,255,255,0.5)',
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 300ms ease',
  },
  processWrapperShow: {
    opacity: 1,
  },
});

class ShiftCancelDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      loading: false,
      shift_cancel_reason: '',
      cancelReasonError: '',
      disableCancelButton: true,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.onResponse = this.onResponse.bind(this);
    this.onError = this.onError.bind(this);
  }

  componentDidMount() {
    const { record: { cancellationPolicyId }, getShiftFacilityPolicy } = this.props;

    getShiftFacilityPolicy(cancellationPolicyId);
  }

  onResponse({ data, errors }) {
    if (errors) {
      console.error(errors);
    }

    const { id, status } = data.cancelShift;
    const { resource, record, history } = this.props;

    record.status = status;

    this.setState({ loading: false, open: false }, () => { history.push(`/${resource}/${id}/show`); });
  }

  onError(e) {
    console.error(e);

    this.setState({ loading: false, open: false });
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    const { systemActions, record: { id, status, start_time } } = this.props;
    const CancellationTimeDifference = moment(start_time).diff(moment(), 'hours');
    const isLessThan24Hours = CancellationTimeDifference <= 24 && status === 'confirmed';
    this.setState({ open: false });
    if (isLessThan24Hours) {
      systemActions.trackDismissCancelShift(id);
    }
  };

  handleCancel = () => {
    this.setState({ loading: true });
    const { shift_cancel_reason, cancelReasonError } = this.state;

    const { systemActions, record: { id }, flags: { requireCancellationReason } } = this.props;

    if (!shift_cancel_reason && requireCancellationReason) {
      this.setState({ cancelReasonError: 'Please select a cancel reason.' });
      return;
    }

    if (cancelReasonError && requireCancellationReason) {
      this.setState({ cancelReasonError: '' });
    }

    const query = `
      mutation cancelShift($id: Int, $shift_cancel_reason: String) {
        cancelShift(id: $id, shift_cancel_reason: $shift_cancel_reason) {
          id
          status
          shift_cancel_reason
        }
      }
    `;

    const variables = { id, shift_cancel_reason: requireCancellationReason ? shift_cancel_reason : null };

    Fetcher.post('/graphql', { operationName: 'cancelShift', query, variables })
      .then(this.onResponse)
      .catch(this.onError);

    systemActions.trackCancelShift(id);
  };

  render() {
    const {
      open, loading, shift_cancel_reason, cancelReasonError, disableCancelButton,
    } = this.state;

    const {
      record: { status, start_time }, classes, flags, facilityCancellationPolicy: { hoursToPay, cancellationPolicy },
    } = this.props;

    const { requireCancellationReason } = flags;
    const CancellationTimeDifference = moment(start_time).diff(moment(), 'minutes') / 60;

    const IfCancellationPolicy = cancellationPolicy !== null ? cancellationPolicy : 24;
    const isLessThan24Hours = status === 'confirmed' && CancellationTimeDifference < IfCancellationPolicy;
    const DialogTitleText = isLessThan24Hours ? 'Are you sure you want to cancel this shift?' : 'Cancel shift';
    const cancellationPolicyMessage = `You are attempting to cancel a confirmed shift within ${cancellationPolicy || 24} hours of the start time of the shift.  As a result your facility will be charged a cancellation fee equivalent to ${hoursToPay || 4} hours of the scheduled shift.`;

    const No_feature_flag_CancellationMessage = isLessThan24Hours
      ? cancellationPolicyMessage : 'This cancellation is final. Please confirm if you would like to cancel.';

    const featureflagCancellationMessage = isLessThan24Hours ? cancellationPolicyMessage : 'We’d like to hear the reason for cancelling your shift. It’ll help us improve care for you and your patients.';

    const CancellationMessage = requireCancellationReason ? featureflagCancellationMessage : No_feature_flag_CancellationMessage;

    if (['completed', 'pending clock', 'pending check-in'].includes(status)) {
      return null;
    }

    if (status === 'cancelled') {
      return (
        <div className={classes.button}>
          <Button variant="contained" color="primary" disabled>{status}</Button>
        </div>
      );
    }

    if (status !== 'opened' && moment(start_time).diff(moment(), 'minutes') <= 0) {
      return null;
    }

    const cancelReasonOptions = [
      { value: 'filled_internally', label: 'Filled internally' },
      { value: 'census_change', label: 'Census change' },
      { value: 'applicant_withdrew', label: 'Applicant withdrew' },
      { value: 'no_applications', label: 'No Applications' },
      { value: 'filled_by_external_source', label: 'Filled by external source' },
    ];

    const buttonClassName = () => {
      if (requireCancellationReason) {
        return classes.redButton;
      }

      if (isLessThan24Hours) {
        return classnames(classes.redButton);
      }
      return null;
    };

    const checkLessThan24HrsButton = () => (isLessThan24Hours ? 'text' : 'contained');

    return (
      <>
        <div className={classes.button}>
          <Button variant="contained" color="primary" onClick={this.handleOpen}>Cancel shift</Button>
        </div>
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className={classnames(classes.processWrapper, loading && classes.processWrapperShow)}>
            <CircularProgress className={classes.progress} />
          </div>
          <DialogTitle>{DialogTitleText}</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ marginBottom: '5px' }}>
              {CancellationMessage}
              {/* This cancellation is final. Please confirm if you would like to cancel. */}
            </DialogContentText>

            {requireCancellationReason
              ? (
                <FormControl fullWidth>
                  <InputLabel htmlFor="cancel-reason-select">Reason for cancellation</InputLabel>
                  <Select
                    value={shift_cancel_reason}
                    onChange={(event) => this.setState({ shift_cancel_reason: event.target.value, disableCancelButton: false })}
                    inputProps={{ id: 'cancel-reason-select' }}
                    error={!!cancelReasonError}
                  >
                    {cancelReasonOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                    ))}
                  </Select>

                  <Typography variant="caption" color="error">
                    {cancelReasonError}
                  </Typography>
                </FormControl>
              ) : null }

          </DialogContent>
          <DialogActions style={requireCancellationReason ? { justifyContent: 'space-between' } : null}>
            <Button onClick={this.handleClose} className={requireCancellationReason ? classes.blueButton : null} variant="contained" color={requireCancellationReason ? 'outlined' : 'primary'} size="small" disabled={loading}>
              {requireCancellationReason ? "Don't Cancel" : 'dismiss' }
            </Button>

            <Button
              onClick={this.handleCancel}
              color="primary"
              size="small"
              disabled={requireCancellationReason ? disableCancelButton : loading}
              autoFocus
              className={buttonClassName}
              variant={requireCancellationReason ? 'contained' : checkLessThan24HrsButton()}
            >
              {requireCancellationReason ? 'Cancel Shift' : 'cancel'}
            </Button>

          </DialogActions>

        </Dialog>
      </>
    );
  }
}
ShiftCancelDialog.defaultProps = {
  classes: null,
  history: null,
  record: null,
  resource: null,
  systemActions: null,
  flags: null,
};
ShiftCancelDialog.propTypes = {
  classes: PropTypes.shape({
    button: PropTypes.string,
    processWrapper: PropTypes.shape({}),
    progress: PropTypes.shape({}),
    processWrapperShow: PropTypes.shape({}),
  }),
  flags: PropTypes.shape({
    requireCancellationReason: PropTypes.bool,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  record: PropTypes.shape({
    status: PropTypes.string,
    start_time: PropTypes.string,
    id: PropTypes.number,
  }),
  resource: PropTypes.string,
  systemActions: PropTypes.shape({
    trackCancelShift: PropTypes.func,
    trackDismissCancelShift: PropTypes.func,
  }),
};

const mapStateToProps = (state) => ({
  facilityCancellationPolicy: state.shiftCancellationPolicy,
});

export default connect(
  mapStateToProps,
  (dispatch) => bindActionCreatorsObjective({ systemActions, getShiftFacilityPolicy }, dispatch),
)(withStyles(styles)(withRouter(withLDConsumer()(ShiftCancelDialog))));

import Storage from 'utils/localStorage';
import { MICRO_APP_URL } from '../../config';

let token = Storage.getParam('user/token');
let facility = Storage.getParam('facility/id');
Storage.on('facility/changed', () => {
  token = Storage.getParam('user/token');
  facility = Storage.getParam('facility/id');
});
export const getUrlForMicroShiftAppUrl = () => `${MICRO_APP_URL}/redirect/create/token/${token}/facility/${facility}`;
export default `${MICRO_APP_URL}/redirect/create/token/${token}/facility/${facility}`;

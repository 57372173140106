import React from 'react';
import {SelectInput, translate} from 'react-admin';

const MoneyField = ({translate, ...props}) => (
  <div style={{width: props.fullWidth ? '100%' : 191}}>
    <SelectInput {...props} choices={[
      {id: 1, name: translate('ra.message.yes')},
      {id: 0, name: translate('ra.message.no')}
    ]} optionText="name" optionValue="id" fullWidth />
  </div>
);

export default translate(MoneyField);
import React, { useEffect } from 'react';
import { NEXTGEN_APP_URL } from '../../config';

const Messages = () => {
  useEffect(() => {
    window.location.href = `${NEXTGEN_APP_URL}/messages`;
  }, []);

  return null;
};

export default Messages;

import { put } from 'redux-saga/effects';
import { trackLogin } from '../actions';

export const action = 'AUTH/LOGIN_SUCCESS';

export default function* ({ payload = {} }) {
  const { token } = payload;

  yield put({ ...trackLogin(), token });
}

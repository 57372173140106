import React, { Component } from 'react';
import Storage from '../../lib/utils/localStorage';

class FacilityList extends Component {
  constructor(props) {
    super(props);
  }

  componentWillUnmount() {
    this.check();
  }

  componentDidUpdate() {
    this.check();
  }

  check = () => {
    const { history } = this.props;
    const facilityId = Storage.getParam('facility/id');

    if (!facilityId) {
      return;
    }

    history.replace(`/Facility/${Number(facilityId)}`);
  };

  render() {
    return null;
  }
}

export default FacilityList;

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SignatureImage from './SignatureImage';

const SupervisorSignatureDialog = ({
  isOpen, onClose, title, source, record, type, nurseName,
}) => {
  const time = type === 'in' ? moment(record.checkInTime).format('h:mma') : moment(record.checkOutTime).format('h:mma');
  const date = type === 'in' ? moment(record.checkInTime).format('dddd, MMMM DD') : moment(record.checkOutTime).format('dddd, MMMM DD');
  const breakTxt = record.hasBreakTime ? ' and took a ' : ' and took ';
  const breakText = record.hasBreakTime ? `${record.breakTime} minute ` : 'no break';
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <div>
          {'By Signing this I verify that '}
          <b>{nurseName}</b>
          {' is '}
          <b>{`clocking ${type}`}</b>
          {' at '}
          <b>{time}</b>
          {' on '}
          <b>{date}</b>
          {type === 'out' ? breakTxt : ''}
          <b>{type === 'out' ? breakText : ''}</b>

        </div>
        <SignatureImage sortable={false} source={source} label="logo" record={record} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
            Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SupervisorSignatureDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  record: PropTypes.shape({
    hasBreakTime: PropTypes.bool,
    checkInTime: PropTypes.string,
    checkOutTime: PropTypes.string,
    breakTime: PropTypes.number,
  }).isRequired,
  source: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  nurseName: PropTypes.string.isRequired,
};

export default SupervisorSignatureDialog;

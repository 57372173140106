import { put, call, select, takeEvery, cancel } from 'redux-saga/effects';
import { userLogout } from 'react-admin';
import { roleSet } from '../actions';
import { client, gql } from '../../../../lib/dataProvider';

const context = {};

function* raCheckUpdate() {
  const isInit = yield select(state => state.authRole.init);

  if (!isInit) {
    const query = gql`
      query {
        Auth {
          Role {
            id
            name
          }
        }
      }
    `;

    const variables = {};

    let data;

    try {
      const result = yield call(client.query, {query, variables, fetchPolicy: 'no-cache'});
      data = result.data;
    } catch (e) {
      yield cancel();
    }

    if (!data || !data.Auth || !data.Auth.Role) {
      yield cancel();
    }

    const startResource = 'Calendar';
    yield put(roleSet(null, null, startResource));
    yield put({type: 'RA/REFRESH_VIEW'});
    if (context.history.location.pathname === '/') {
      context.history.replace(`/${startResource}`);
    }
  }
}

export default (history) => {
  context.history = history;

  return function* () {
    yield takeEvery('RA/USER_CHECK', raCheckUpdate);
  }
}

/**
 * eslint-disable jsx-a11y/interactive-supports-focus
 *
 * @format
 */

// @flow

import React from "react";
import { Confirm } from "react-admin";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import moment from "moment-timezone";
import ReactToPrint from "react-to-print";
import Button from "@material-ui/core/Button";
import * as dates from "react-big-calendar/lib/utils/dates";
import {
  Calendar as BigCalendar,
  momentLocalizer,
  Views,
} from "react-big-calendar";
import { client } from "lib/dataProvider";
import gql from "graphql-tag";
import Storage from "lib/utils/localStorage";
import Tooltip from "@material-ui/core/Tooltip";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "./styles.css";

import { renderDateFormatterRange } from "lib/utils/render";

import * as calendarActions from "lib/redux/calendar/actions";
import { bindActionCreatorsObjective } from "lib/redux/utils";
import { mixpanelTrack } from "lib/utils/mixpanel";

import DialogMore from "./DialogMore";
//import ConfirmCovidStatus from "../ui/ConfirmCovidStatus";
// remove covid modal feb 2024
// TO-DO: The commented code will be re-enabled once the NextGen Calendar is Ready
// import CalendarRedirect from '../ui/CalendarRedirectAlert';
import CustomToolbar from './CustomToolbar'; // Make sure the path is correct

const localizer = momentLocalizer(moment);

const timezone = Storage.getParam("facility/timezone");

moment.tz.setDefault(timezone);

/*
dateArithmetic.startOf = function(date, period) {
  const timezone = currentTimezone();

  let resultPeriod = period;
  if (period === 'week') {
    resultPeriod = 'isoWeek';
  }
  return moment.tz(date, timezone).startOf(resultPeriod).toDate();
};

dateArithmetic.endOf = function(date, period) {
  const timezone = currentTimezone();

  let resultPeriod = period;
  if (period === 'week') {
    resultPeriod = 'isoWeek';
  }
  return moment.tz(date, timezone).endOf(resultPeriod).toDate();
};
*/

// dateArithmetic.add = function (date, count, type) {
//   switch (type) {
//     case 'day':
//       return moment(date).clone().add(24 * count, 'hours').toDate();
//   }
//   return date;
// };

// dateArithmetic.lte = function (date1, date2, period) {
//   const timezone = currentTimezone();

//   const momentDate1 = moment.tz(date1, timezone);
//   const momentDate2 = moment.tz(date2, timezone);

//   const result = momentDate1.diff(momentDate2, period);

//   return result <= 0;
// };

// dateArithmetic.lt = function (date1, date2, period) {
//   const timezone = currentTimezone();

//   const momentDate1 = moment.tz(date1, timezone);
//   const momentDate2 = moment.tz(date2, timezone);

//   const result = momentDate1.diff(momentDate2, period);

//   return result < 0;
// };

// dateArithmetic.gte = function (date1, date2, period) {
//   const timezone = currentTimezone();

//   const momentDate1 = moment.tz(date1, timezone);
//   const momentDate2 = moment.tz(date2, timezone);

//   const result = momentDate1.diff(momentDate2, period);

//   return result >= 0;
// };

// dateArithmetic.gt = function (date1, date2, period) {
//   const timezone = currentTimezone();

//   const momentDate1 = moment.tz(date1, timezone);
//   const momentDate2 = moment.tz(date2, timezone);

//   const result = momentDate1.diff(momentDate2, period);

//   return result > 0;
// };

// dates.visibleDays = function (date, localizer) {
//   const timezone = currentTimezone();
//   let current = dates.firstVisibleDay(date, localizer);
//   const last = dates.lastVisibleDay(date, localizer);
//   const days = [];

//   while (!(moment.tz(current, timezone).diff(moment.tz(last, timezone), 'day') === 0 && days.length % 7 === 0)) {
//     days.push(current);
//     current = dates.add(current, 1, 'day');
//   }
//   console.log('======', dates.week(date));
//   return days;
// };

// localizer.startOfWeek = function () {
//   return 0;
// };

const MonthEvent = ({ event }) => {
  const { tooltip, timeStart, timeEnd, assignedApplicantName } = event.payload;
  if (!tooltip) {
    return <div>{event.title}</div>;
  }
  let text = renderDateFormatterRange(timeStart, timeEnd);
  if (assignedApplicantName) {
    // const name = parseName(assignedApplicantName);
    // text = `${text} - ${name.name} ${name.lastName}.`;
    text = `${text} - ${assignedApplicantName}`;
  }
  return (
    <Tooltip title={tooltip} placement="top">
      <div>{text}</div>
    </Tooltip>
  );
};

MonthEvent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  event: PropTypes.object,
};

MonthEvent.defaultProps = {
  event: {},
};
const AgendaEvent = ({ event, onSelectAgendaEvent }) => {
  const { tooltip, role } = event.payload;
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
  return (
    <Tooltip title={tooltip} placement="top">
      <div
        onClick={() => {
          onSelectAgendaEvent(event);
        }}
        role="button"
        style={{ cursor: "pointer" }}
      >{`${role} - ${tooltip}`}</div>
    </Tooltip>
  );
};

AgendaEvent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  event: PropTypes.object,
  onSelectAgendaEvent: PropTypes.func,
};

AgendaEvent.defaultProps = {
  event: {},
  onSelectAgendaEvent: null,
};
const DayEvent = ({ event }) => {
  const { tooltip, role, timeStart, timeEnd } = event.payload;
  const time = renderDateFormatterRange(timeStart, timeEnd);
  return (
    <Tooltip title={tooltip} placement="top">
      <div>{`${time}: ${role} - ${tooltip}`}</div>
    </Tooltip>
  );
};

DayEvent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  event: PropTypes.object,
};

DayEvent.defaultProps = {
  event: {},
};

const reformatClick = (button) => {
  if (button === "week") {
    return 'WEEK';
  }

  if (button === "month") {
    return "MONTH";
  }

  if (button === "agenda") {
    return "AGENDA";
  }
};

class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.calendar = React.createRef();
    this.dialogMore = React.createRef();
    this.state = {
      printHidden: true,
      currentView: Views.MONTH,
      open: false,
      trackSignatureVerificationPrompt: null,
      width: 0,
      clickedDate: moment
        .tz(moment().format("YYYY-MM-DD"), "UTC")
        .format("YYYY-MM-DD"),
      defaultView: Views.MONTH,
      todayDate: moment().format("YYYY-MM-DD"),
      // isRedirecting: false,
      // showCovidAlert: false,
    };
  }

  componentDidMount() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const viewFromUrl = searchParams.get('view');
    const calendarView = this.getCalendarViewFromUrl(viewFromUrl);
  
    this.setState({ defaultView: calendarView });
    this.handleNavigate(moment(), calendarView, "INIT");
    this.getData();
  }

  // componentWillUnmount() {
  //   clearTimeout(this.timer);
  // }

  getCalendarViewFromUrl = (viewParam) => {
    switch (viewParam?.toLowerCase()) {
      case 'month':
        return Views.MONTH;
      case 'agenda':
        return Views.AGENDA;
      default:
        return Views.MONTH;
    }
  };

  getRouteQuery = (date, id, status) => {
    if (status === "completed") {
      return `/CompletedShift/${id}/show`;
    }

    if (status === "pending review") {
      return `/CompletedShift/${id}/show?reviewModal=open`;
    }

    return `/OverviewShift/${id}/show`;
  };

  handleHideButton = (view) => {
    mixpanelTrack(`CALENDAR_${reformatClick(view)}`);
    this.setState({ defaultView: view });

    const lowerCaseView = view.toLowerCase();
    const baseUrl = window.location.href.split('#')[0];
    const newViewUrl = `${baseUrl}#/Calendar?view=${lowerCaseView}`;
    window.history.pushState({}, '', newViewUrl);

    if (view === Views.MONTH) {
      this.setState({ printHidden: true });
    } else {
      this.setState({ printHidden: false });
    }
  };

  handleSelectEvent = (event) => {
    mixpanelTrack("CALENDAR_SHIFT");
    const { history } = this.props;
    const { date, id, status } = event.payload;

    history.push(this.getRouteQuery(date, id, status));
  };

  updateFacility = () => {
    const mutation = gql`
      mutation updateFacility(
        $id: Int
        $trackSignatureVerificationPrompt: DateTime
      ) {
        updateFacility: updateFacility(
          id: $id
          trackSignatureVerificationPrompt: $trackSignatureVerificationPrompt
        ) {
          id
        }
      }
    `;

    const variables = {
      id: Number(Storage.getParam("facility/id")),
      trackSignatureVerificationPrompt: moment().format(),
    };
    client
      .mutate({ mutation, variables, fetchPolicy: "no-cache" })
      .then(({ data }) => console.log("done"));
  };

  handleDialogClose = () => {
    this.setState({ open: false });
    this.updateFacility();
  };

  getData = () => {
    let id = Number(Storage.getParam("facility/id"));
    let query = gql`
      query Facility($id: Int) {
        Me: Facility(id: $id) {
          id
          isSignatureRequired
          trackSignatureVerificationPrompt
        }
      }
    `;
    let variables = { id };

    const role = Storage.getParam("user/role");
    if (role === "admin") {
      id = Number(Storage.getParam("admin/facility/id"));
      query = gql`
        query Facility($id: Int) {
          Me: Facility(id: $id) {
            id
            isSignatureRequired
            trackSignatureVerificationPrompt
          }
        }
      `;
      variables = { id };
    }

    client
      .query({ query, variables, fetchPolicy: "no-cache" })
      .then(
        ({
          data: {
            Me: { isSignatureRequired, trackSignatureVerificationPrompt },
          },
        }) => {
          this.setState({
            open: !isSignatureRequired,
            trackSignatureVerificationPrompt: moment().diff(
              trackSignatureVerificationPrompt || 5,
              "days"
            ),
          });
        }
      )
      .catch((error) => {
        console.log(error.message);
      });
  };

  handleConfirm = () => {
    this.setState({ open: false });
    this.updateFacility();

    window.location.href = "https://nursedash.typeform.com/to/RRvzSctn";
  };

  handleNavigate = (date) => {
    const { clickedDate, defaultView, todayDate } = this.state;

    const dateDiff = moment(moment(date).format("YYYY-MM-DD")).diff(
      clickedDate,
      defaultView
    );

    const isTodayDate = moment(todayDate).isSame(
      moment(date).format("YYYY-MM-DD")
    );

    this.setState({ clickedDate: moment(date).format("YYYY-MM-DD") });

    const {
      calendarActions: { getEvents, setFavoritesFlag },
      flags,
    } = this.props;
    const { facilityFavorites } = flags;
    // when redirecting from shift posting sometimes the flag become undefined when reloading the page, setting it in state to fix the issue
    setFavoritesFlag(facilityFavorites);
    const startOfMonth = moment.tz(
      moment(dates.firstVisibleDay(date, localizer)).format(
        "YYYY-MM-DDTHH:mm:ss"
      ),
      "UTC"
    );
    const endOfMonth = moment.tz(
      moment(dates.lastVisibleDay(date, localizer)).format(
        "YYYY-MM-DDTHH:mm:ss"
      ),
      "UTC"
    );
    getEvents(
      startOfMonth.toISOString(),
      endOfMonth.toISOString(),
      facilityFavorites
    );

    if (isTodayDate && !date._isAMomentObject) {
      mixpanelTrack("CALENDAR_TODAY");
    }

    if (dateDiff > 0 && !isTodayDate) {
      mixpanelTrack("CALENDAR_NEXT");
    }

    if (dateDiff < 0 && !isTodayDate) {
      mixpanelTrack("CALENDAR_BACK");
    }
  };

  handleShowMore = (events, date) => {
    mixpanelTrack("CALENDAR_MORE");
    this.dialogMore.current.show(events, date);
  };

  // handleRedirect = () => {
  //   this.setState({ isRedirecting: true });
  // };

  render() {
    const { events } = this.props;
    const {
      printHidden,
      open,
      trackSignatureVerificationPrompt,
      defaultView
       /* isRedirecting, showCovidAlert */,
    } = this.state;

    // const facility_id = Number(Storage.getParam("facility/id"));
    const resultEvents = Object.values(events);

    return (
      <>
        <DialogMore ref={this.dialogMore} onChange={this.handleSelectEvent} />
        <BigCalendar
          ref={this.calendar}
          events={resultEvents}
          views={{
            [Views.MONTH]: true,
            [Views.WEEK]: true,
            [Views.AGENDA]: true,
          }}
          step={60}
          maxRows={6}
          showMultiDayTimes
          // max={dates.add(dates.endOf(new Date(2019, 4, 6), 'day'), -1, 'hours')}
          // defaultDate={new Date(2017, 8, 1)}
          localizer={localizer}
          defaultView={defaultView}
          onSelectEvent={this.handleSelectEvent}
          onNavigate={this.handleNavigate}
          onShowMore={this.handleShowMore}
          onView={this.handleHideButton}
          length={7}
          endAccessor={printHidden ? "end" : "actualEnd"}
          eventPropGetter={(event) => ({ style: { ...event.payload.style } })}
          drilldownView="month"
          components={{
            toolbar: CustomToolbar,
            month: { event: MonthEvent },
            day: { event: DayEvent },
            agenda: {
              event: (event) => (
                <AgendaEvent
                  event={event.event}
                  onSelectAgendaEvent={this.handleSelectEvent}
                />
              ),
            },
          }}
        />
        <ReactToPrint
          trigger={() =>
            !printHidden ? (
              <Button
                variant="contained"
                color="primary"
                style={{
                  height: 40,
                  width: 200,
                  alignSelf: "center",
                  marginTop: 20,
                }}
                hidden={printHidden}
              >
                Print this out!
              </Button>
            ) : (
              <p />
            )
          }
          content={() => this.calendar.current}
        />
        <Confirm
          isOpen={!!(open && trackSignatureVerificationPrompt >= 1)}
          loading
          title="Upgraded Clock In/Out Protocol"
          content={`NurseDash has improved their clock in and out process to require a signature when clinicians are arriving and departing for shifts. This means that you will be able to see exactly who NurseDash clinicians are clocking in and out with. It will also significantly reduce the number of time verification requests needed from NurseDash. 

          You have automatically been upgraded to take advantage of this new feature!
          
          To complete the upgrade, we just need a little information about how to set it up. 
          
          Would you like to complete set up now?`}
          onConfirm={this.handleConfirm}
          onClose={this.handleDialogClose}
          confirm="Yes, Let's Go!"
          cancel="Later"
        />
        {/* <ConfirmCovidStatus
          isOpen
          loading
          facility_id={facility_id}
          title=""
          content=""
          onConfirm=""
          onClose=""
          confirm=""
          cancel=""
        /> */}
        {/* {!isRedirecting && showCovidAlert && (
          <ConfirmCovidStatus
          isOpen
          loading
          facility_id={facility_id}
          title=""
          content=""
          onConfirm=""
          onClose=""
          confirm=""
          cancel=""
          />
        )} */}
        {/* {Storage.getParam('user/role') === 'facility' && <CalendarRedirect />} */}
        ;
      </>
    );
  }
}

Calendar.propTypes = {
  events: PropTypes.shape({}),
  calendarActions: PropTypes.shape({
    getEvents: PropTypes.func,
    setFavoritesFlag: PropTypes.func,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  flags: PropTypes.shape({
    facilityFavorites: PropTypes.bool,
  }),
};

Calendar.defaultProps = {
  events: null,
  calendarActions: null,
  history: null,
  flags: {},
};

export default connect(
  (state) => ({
    events: state.calendar.events,
  }),
  (dispatch) => bindActionCreatorsObjective({ calendarActions }, dispatch)
)(withRouter(withLDConsumer()(Calendar)));

import { gql } from 'apollo-boost';
import {
  call, put, cancel,
} from 'redux-saga/effects';
import { showNotification, crudGetMany } from 'ra-core';

import { client } from 'lib/dataProvider';
import {} from 'lib/redux/system/actions';
import { SET_RELATION_TO_FACILITY } from '../actionTypes';

export const action = SET_RELATION_TO_FACILITY;

export default function* ({ payload, callback }) {
  const {
    nurseId,
    relation,
    relationAction,
    shiftApplicationId,
    resource,
    reason,
  } = payload;

  yield put({ type: 'RA/FETCH_START' });
  const mutationName = relationAction === 'remove' ? 'removeRelationToFacility' : 'setRelationToFacility';
  const mutation = gql`
    mutation ${mutationName}($nurseId: Int, $relation: String, $reason: String) {
      ${mutationName}(nurseId: $nurseId, relation: $relation, reason: $reason) {
        nurseId
        relation
        reason
      }
    }
  `;

  const variables = {
    nurseId,
    relation,
    reason,
  };

  try {
    yield call(client.mutate, { mutation, variables, fetchPolicy: 'no-cache' });
    yield put(crudGetMany(resource, [shiftApplicationId]));
    yield put({ type: 'RA/FETCH_END' });
    if (callback) {
      callback(relation, relationAction);
    }
  } catch (error) {
    yield put(showNotification(error.message, 'error'));
    yield put({ type: 'RA/FETCH_END' });
    yield cancel();
  }
}

import { reducerObjective } from 'lib/redux/utils';
import {
  LOADING_START,
  LOADING_END,
} from './actions';

const initialState = {
  stack: {},
};

export default reducerObjective({
  [LOADING_START]: (state, { payload }) => {
    const newState = { stack: { ...state.stack } };
    newState.stack[payload.name] = true;
    return newState;
  },
  [LOADING_END]: (state, { payload }) => {
    const newState = { stack: { ...state.stack } };
    delete newState.stack[payload.name];
    return newState;
  },
}, initialState);

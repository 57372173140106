import React from "react";

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

class DirectionMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = value => {
    const { onChange } = this.props;
    if (typeof value === 'string' && onChange) {
      onChange(value);
    }
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;

    const selected = this.props.options.find(({ value }) => value === this.props.value);

    return (
      <div>
        <Button
          aria-owns={anchorEl ? 'direction-menu' : undefined}
          aria-haspopup="true"
          size="small"
          onClick={this.handleClick}
          className="flatPrimary"
        >
          {selected.icon}{selected.text}
        </Button>
        <Menu
          id="direction-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {this.props.options.map(({ text, value, icon }) => (
            <MenuItem key={value} onClick={this.handleClose.bind(this, value)}>{icon}{text}</MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

export default DirectionMenu;

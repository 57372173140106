import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Edit, SimpleForm, FileInput, FileField, Toolbar, SaveButton, FormDataConsumer, crudGetOne,
  SelectInput, required, TextInput, RadioButtonGroupInput, BooleanInput,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import Storage from 'lib/utils/localStorage';

const styles = (theme) => ({
  fileInput: {
    '& .previews > div': {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing.unit,
    },
  },
  preparing: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing.unit,
    '& > div:first-child': {
      marginRight: theme.spacing.unit,
    },
  },
});

const FormToolbar = ({ classes, ...props }) => (
  <Toolbar {...props} className="toolbar">
    <SaveButton submitOnEnter />
    <>&nbsp; More settings coming soon!</>
  </Toolbar>
);

FormToolbar.propTypes = {
  classes: PropTypes.shape({}),
};

FormToolbar.defaultProps = {
  classes: null,
};

class FacilityEdit extends Component {
  componentDidMount() {
    Storage.on('facility/changed', this.facilityChanged);
  }

  componentDidUpdate() {
    this.check();
  }

  componentWillUnmount() {
    if (this.waitUpdate) {
      clearTimeout(this.waitUpdate);
    }
    Storage.off('facility/changed', this.facilityChanged);
  }

  facilityChanged = () => {
    this.check();
  };

  check = () => {
    const { history, id } = this.props;
    const facilityId = Storage.getParam('facility/id');

    if (!facilityId) {
      return;
    }

    if (Number(id) !== Number(facilityId)) {
      history.push(`/Facility/${Number(facilityId)}`);
    }
  };

  update = (id) => {
    const { resource, dispatch } = this.props;
    if (this.waitUpdate) {
      return;
    }
    this.waitUpdate = setTimeout(() => {
      this.waitUpdate = null;
      dispatch(crudGetOne(resource, id));
    }, 5000);
  };

  handleHelpClick = () => {
    alert(`COVID Statuses:
COVID Free - No known patients or residents are COVID positive.
COVID On-Site - At least 1 patient or resident has tested positive with COVID in the last 7 days and has not have a negative test since then.`);
  }

  render() {
    const { resource, classes } = this.props;
    return (
      <div className="layout-page" data-page={resource} data-mode="edit">
        <Edit {...this.props}>
          <SimpleForm toolbar={<FormToolbar />}>
            <FileInput
              source="orientationMaterials"
              accept="application/pdf"
              placeholder={<p>Drop your pdf file here</p>}
              className={classes.fileInput}
            >
              <FileField source="src" title="title" />
            </FileInput>

            {/* <RadioButtonGroupInput
              source="covidVaccineRequired"
              choices={[
                { id: 'True', name: 'Yes' },
                { id: 'False', name: 'No' },
              ]}
              label="Does your facility require that all staff members be vaccinated for Covid-19?"
            /> */}

            <BooleanInput className={classes.booleanField} source="covidVaccineRequired" label="Does your facility require that all staff members be vaccinated for Covid-19?" defaultValue={false} />

            <FormDataConsumer>
              {({ formData }) => {
                if (formData.covidVaccineRequired) {
                  return (
                    <>
                      <BooleanInput className={classes.booleanField} source="allowSingleDoseVaccine" label="Allow Single Dose Vaccinations" defaultValue={false} />
                      <BooleanInput className={classes.booleanField} source="covidMedicalExemption" label="Allow Medical COVID Vaccine Exemption" defaultValue={false} />
                      <BooleanInput className={classes.booleanField} source="covidReligiousExemption" label="Allow Religious COVID Vaccine Exemption" defaultValue={false} />
                    </>
                  );
                }
                // formData.allowSingleDoseVaccine = false;
              }}
            </FormDataConsumer>


            <TextInput source="surveyUsername" label="what is your name?" validate={[required()]} />
            <TextInput source="surveyUseremail" label="please type your email address" validate={[required()]} />
            <TextInput source="surveyUsertitle" label="what is your title?" validate={[required()]} />

            <div>

              <SelectInput
                source="covidStatus"
                label="Covid Status"
                choices={[
                  { id: '', name: 'Select Status' },
                  { id: 'free', name: 'Covid Free' },
                  { id: 'on_site', name: 'Covid On-site' },
                ]}
                validate={[required()]}
              />
              <HelpOutlineIcon onClick={this.handleHelpClick} />
            </div>
            <FormDataConsumer>
              {(formData) => {
                const { id, orientationMaterials } = formData.record;
                if ((!orientationMaterials || !orientationMaterials.uuid)) {
                  return null;
                }
                const { preparing, pages} = orientationMaterials;
                if (preparing && preparing.__typename) {
                  delete preparing.__typename;
                  formData.record.orientationMaterials.preparing = preparing;
                }
                if (pages) {
                  (pages || []).map((page, index) => {
                    if (page.__typename) {
                      delete page.__typename;
                      formData.record.orientationMaterials.pages[index] = page;
                    }
                  });
                  return null;
                }
                this.update(id);
                return null;
              }}
            </FormDataConsumer>
          </SimpleForm>
        </Edit>
      </div>
    );
  }
}

FacilityEdit.propTypes = {
  id: PropTypes.number.isRequired,
  resource: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    fileInput: PropTypes.string,
    preparing: PropTypes.string,
  }).isRequired,
};

export default connect()(withStyles(styles)(FacilityEdit));

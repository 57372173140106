import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

const theme = createMuiTheme({
  palette: {
    error: red,
    primary: {
      50: '#373882',
      100: '#373882',
      200: '#373882',
      300: '#373882',
      400: '#373882',
      500: '#373882',
      600: '#373882',
      700: '#2b3063',
      800: '#373882',
      900: '#373882',
      A100: '#373882',
      A200: '#373882',
      A400: '#373882',
      A700: '#373882',
    },
  },
  typography: {
    htmlFontSize: 16,
  },
  overrides: {
    MuiAppBar: {
      colorSecondary: {
        backgroundColor: '#353a6f',
      },
    },
    MuiDrawer: {
      docked: {
        backgroundColor: '#ffffff',
      },
    },
    MuiExpansionPanel: {
      root: {
        boxShadow: 'none',
        borderTop: '1px solid rgba(0,0,0,.12)',
      },
      expanded: {
        margin: 0,
        boxShadow: 'none',
        borderTop: '1px solid rgba(0,0,0,.12)',
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        paddingTop: 0,
      },
    },
  },
});

export default theme;

import moment from 'moment-timezone';
import currentTimezone from 'lib/utils/currentTimezone';

export const renderDateFormatterRange = (start, end) => {
  if (!start || !end) {
    return `${start} ${end}`;
  }

  if ((start.length < 22 || end.length < 22)) {
    return `${start} ${end}`;
  }

  const timezone = currentTimezone();
  const startMoment = moment(start).tz(timezone);
  const endMoment = moment(end).tz(timezone);

  /* if (startMoment.format('dddd MMM') !== endMoment.format('dddd MMM')) {
    return `${startMoment.format('h:mma')} - ${endMoment.format('D MMM h:mma')}`;
  }

  if (startMoment.format('dddd MMM D') !== endMoment.format('dddd MMM D')) {
    return `${startMoment.format('h:mma')} - ${endMoment.format('D, h:mma')}`;
  } */

  return `${startMoment.format('h:mma')} - ${endMoment.format('h:mma')}`;
};

export const renderDateFormat = (date) => moment(date).format('dddd M/D, h:mm a');

export const renderQualifications = (record, source) => {
  // console.log('****record****');
  // console.log(record);

  if (record[source] && Array.isArray(record[source]) && record[source].length > 0) {
    if (record.covidVaccineRequired && moment(record.end_time).isSameOrAfter(moment('2021-12-05')) && !record[source].includes('COVID Vaccine')) {
      record[source].push('COVID Vaccine');
    }
    const res = record[source].join(', ');
    // console.log(res);
    return res;
  }

  return 'not set';
};
  // taking into consideration that some people can have complex name like Anne Mary and more that one last name
export const parseName = (name) => {
  const fullName = name || '';
  const result = {};

  if (fullName.length > 0) {
    const nameTokens = fullName.match(/[A-ZÁ-ÚÑÜ][a-zá-úñü]+|([aeodlsz]+\s+)+[A-ZÁ-ÚÑÜ][a-zá-úñü]+/g) || [];
    if (nameTokens.length > 3) {
      result.name = nameTokens.slice(0, 2).join(' ');
    } else {
      result.name = nameTokens.slice(0, 1).join(' ');
    }

    if (nameTokens.length > 2) {
      result.lastName = nameTokens.slice(-2, -1).join(' ').substr(0, 1);
      // result.secondLastName = nameTokens.slice(-1).join(' ');
    } else {
      result.lastName = nameTokens.slice(-1).join(' ').substr(0, 1);
      // result.secondLastName = "";
    }
  }

  return result;
};

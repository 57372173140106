export const SHIFT_FACILITY_POLICY = 'SHIFT_FACILITY_POLICY';
export const GET_SHIFT_POLICY = 'GET_SHIFT_POLICY';

export const getShiftFacilityPolicy = (id) => ({
  type: GET_SHIFT_POLICY,
  payload: {
    id,
  },
});

export const shiftPolicy = (cancellationPolicy, hoursToPay) => ({
  type: SHIFT_FACILITY_POLICY,
  payload: {
    hoursToPay,
    cancellationPolicy,
  },

});

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { showNotification, crudGetMany, Confirm } from 'react-admin';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import { client, gql } from 'lib/dataProvider';
import { bindActionCreatorsObjective } from 'lib/redux/utils';
import * as systemActions from 'lib/redux/system/actions';
import Storage from 'lib/utils/localStorage';

const styles = theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  button: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  }
});

class ApplicantSelectButton extends Component {
  constructor(props){
    super(props);
    this.state = { show: true, open: false };
    this.handleClick = this.handleClick.bind(this);
    this.onResponse = this.onResponse.bind(this);
    this.onError = this.onError.bind(this);
    this.button = this.button.bind(this);
    this.onOvertime = this.onOvertime.bind(this);
  }

  button() {
    const { record } = this.props;

    const object = { message: record.shift.status, action: null, comment: null };

    if (record.shift.status === 'selected') {
      return { ...object, message: 'unselect', action:'unselectApplicant' }
    }

    if (record.shift.status === 'applied') {
      return { ...object, message: 'select', action: 'selectApplicant' }
    }

    if (record.shift.status === 'unavailable') {
      return { ...object, comment: 'The clinician has been selected for another shift.  To ensure you get to select your preferred individual in the future, please be advised to select your applicants as soon as possible.' };
    }

    return object;
  }

  onOvertime = () => {
    this.setState({ open: true });
  }

  handleClick() {
    const { show } = this.state;

    if (!show){
      return console.log('prevent second click');
    }

    this.setState({ open: false });

    const { record } = this.props;
    this.setState({ show: false });

    const mutation = gql`
      mutation ${this.button().action}($id: Int) {
        ${this.button().action}(id: $id) {
          id
          status
        }
      }
    `;

    const variables = { id: Number(record.id) };

    client.mutate({ mutation, variables, fetchPolicy: 'no-cache' })
      .then(this.onResponse)
      .catch(this.onError);
  }

  onResponse({ data, errors }) {
    const { record, actions, resource, systemActions } = this.props;

    if (errors) {
      return;
    }

    const { action } = this.button();

    if (!data[action]?.status) {
      return;
    }

    actions.crudGetMany(resource, [record.id]);
    actions.showNotification(`Shift application ${data[action]?.status}`);
    this.setState({ show: true });

    switch (action) {
      case 'unselectApplicant': systemActions.trackUnselectApplicant(record.shift.shiftId, record.shift.role, record.id, record.name); break;
      case 'selectApplicant': systemActions.trackSelectApplicant(record.shift.shiftId, record.shift.role, record.id, record.name); break;
    }
  }

  onError(e) {
    const { actions } = this.props;
    actions.showNotification(e.message.replace(/GraphQL error:/, '').trim(), 'warning');
    this.setState({ show: true });
  }

  render() {
    const { classes } = this.props;
    const { open } = this.state;
    const { shift, isOvertime, overtimeDuration } = this.props.record;

    const button = this.button();
    const role = Storage.getParam('user/role');

    return (
      <div className={classes.wrapper}>
        <Button
          variant="contained"
          color="primary"
          disabled={role !== 'facility' || !Boolean(button.action)}
          onClick={isOvertime && shift.status === 'applied' ? this.onOvertime : this.handleClick}
          className={classes.button}
        >
          {button.message}
        </Button>
        {Boolean(button.comment) && <Typography variant="caption">{button.comment}</Typography>}
        <Confirm
              isOpen={open}
              title="Overtime Alert"
              content={`This nurse would go into overtime if they work this shift. Are you sure you want to select this nurse?`}
              confirm="Ok"
              confirmColor="primary"
              cancel="Cancel"
              onConfirm={this.handleClick}
              onClose={() => { this.setState({ open: false }); }}
            />
      </div>
    )
  }
}

ApplicantSelectButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
  crudGetMany: PropTypes.func,
};

export default connect(null, dispatch => bindActionCreatorsObjective({
  systemActions,
  actions: {
    showNotification,
    crudGetMany,
    push,
  }
}, dispatch))(withStyles(styles)(ApplicantSelectButton));

import React from 'react';
import {
  FunctionField,
} from 'react-admin';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { API_HOST } from '../../config';

const CredentialSection = ({ record, documents, classes }) => {
  const exceptionUrl = [806, 805, 804, 427].includes(Number(record.facilityId)) ? `${API_HOST}/api/firebasestorage/retrieve?fileTitle=` : null;
  return (
    <>
      {
        documents.map((credential) => {
          if (credential === null) {
            return null;
          }
          const {
            front, name, expiration, expires,
          } = credential;
          return (
            <FunctionField
              key={name}
              record={record}
              source="credentials"
              className={classes.docName}
              render={() => (
                <div>
                  { front && front.src ? (
                    <a
                      href={
                        exceptionUrl ? `${exceptionUrl}${front.title}`
                          : front.src
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {`${name}`}
                    </a>
                  ) : (
                    <span>
                      {name}
                    </span>
                  )}
                  {expires && (
                    <span className={classes.expiration}>
                      {` expires ${moment(expiration).format('MM/DD/YYYY')}`}
                    </span>
                  )}
                </div>
              )}
            />
          );
        })
      }
    </>
  );
};
CredentialSection.defaultProps = {
  documents: [],
};
CredentialSection.propTypes = {
  record: PropTypes.shape({
    facilityId: PropTypes.number,
  }).isRequired,
  classes: PropTypes.shape({
    expiration: PropTypes.string,
    docName: PropTypes.string,
  }).isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({
    map: PropTypes.shape({}),
  })),
};
export default CredentialSection;

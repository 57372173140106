import { gql } from 'apollo-boost';
import { call, put, cancel } from 'redux-saga/effects';
import { client } from '../../../dataProvider';
import {
  REQUEST_MESSAGES,
  responseChannels,
  responseMessages,
} from '../actions';

export const action = REQUEST_MESSAGES;

export default function* ({ payload }) {
  yield put({ type: 'RA/FETCH_START' });

  const { channelId, first, count, after = '' } = payload;

  const query = gql`
    query Channel($id: Int, $page: Int, $perPage: Int, $after: String) {
      Channel(id: $id) {
        id
        name
        members {
          id
          unreadCount
          user {
            id
            name
            userId
            userModel
            image{
              src
            }
          }
        }
        lastMessage {
          id
          text
          createdAt
          member {
            id
          }
        }
        applicant {
          shift {
            id
            role
            start_time
            end_time
          }
        }
        messages(page: $page, perPage: $perPage, sortOrder: "desc", sortField: "createdAt", after: $after) {
          id
          text
          createdAt
          channelId
          member {
            id
          }
        }
        createdAt
      }
    }
  `;

  const variables = {
    id: Number(channelId),
    page: Number(first),
    perPage: Number(count) + 1,
    after: after,
  };

  let response = {};

  try {
    response = yield call(client.query, { query, variables, fetchPolicy: 'no-cache' });
  } catch (error) {
    console.warn(error.message);
    yield put({ type: 'RA/FETCH_END' });
    yield cancel();
  }

  const { data } = response;

  if (data && data.Channel) {
    const messages = data.Channel.messages || [];
    const hasPrev = messages.length === (Number(count) + 1);
    yield put(responseChannels([{ ...data.Channel, messages: null }]));
    yield put(responseMessages(messages.slice(0, Number(count)), hasPrev));
  }

  yield put({ type: 'RA/FETCH_END' });
}

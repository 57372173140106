/* global window */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { userLogin } from 'react-admin';
import { NEXTGEN_APP_URL } from '../../config';

class CustomLoginPage extends Component {
  componentDidMount() {
    const { onUserLogin } = this.props;
    const token = this.getTokenFromUrl();

    if (!token) {
      window.location.href = `${NEXTGEN_APP_URL}/logout`;
      return;
    }

    onUserLogin({ token });
  }
  /* eslint-disable class-methods-use-this */

  getTokenFromUrl() {
    const { hash } = window.location;
    if (hash) {
      const hashParams = new URLSearchParams(hash.split('?')[1]);
      return hashParams.get('token');
    }

    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('token');
  }

  render() {
    return null;
  }
}

CustomLoginPage.propTypes = {
  onUserLogin: PropTypes.func.isRequired,
};

export default connect(undefined, { onUserLogin: userLogin })(CustomLoginPage); //

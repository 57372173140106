import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import { useShiftReviewModal } from 'context/CommonModalContext';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Storage from 'utils/localStorage';

const styles = () => ({
  centered: {
    textAlign: 'center',
  },
});

const ReviewShiftButton = ({
  text, record, center, classes, autoShowModal,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { handleOpen } = useShiftReviewModal();
  const { shiftStatus } = record;

  const role = Storage.getParam('user/role');
  const isAdmin = role === 'admin';

  const handleNavigateToShiftReview = useCallback(() => {
    handleOpen(record);
  }, []);

  const handleDialogHide = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleDialogShow = useCallback(() => {
    setIsOpen(true);
  }, []);

  useEffect(() => {
    if (autoShowModal && !isAdmin) {
      handleNavigateToShiftReview();
    }
  }, []);

  if (shiftStatus !== 'pending review') {
    return null;
  }

  return (
    <>
      <div className={center && classes.centered}>
        <Button onClick={isAdmin ? handleDialogShow : handleNavigateToShiftReview} variant="contained" color="primary">
          {text}
        </Button>
      </div>
      {isAdmin && (
        <Dialog
          open={isOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>Not allowed</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Only facility user can make review.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogHide} color="primary" autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

ReviewShiftButton.propTypes = {
  classes: PropTypes.shape({
    centered: PropTypes.string,
  }).isRequired,
  record: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  text: PropTypes.string,
  center: PropTypes.bool,
  autoShowModal: PropTypes.bool,
};

ReviewShiftButton.defaultProps = {
  text: 'Review Shift',
  center: false,
  autoShowModal: false,
};

export default withStyles(styles)(ReviewShiftButton);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import pluralize from 'pluralize';
import {
  Create,
  SimpleForm,
  LongTextInput,
  SelectInput,
  SelectArrayInput,
  translate,
  FormDataConsumer,
  CardActions,
  Confirm,
  required,
} from 'react-admin';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { formValueSelector, change, untouch } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import currentTimezone from 'lib/utils/currentTimezone';
import Storage from 'lib/utils/localStorage';
import { mixpanelTrack } from 'lib/utils/mixpanel';
import { client } from 'lib/dataProvider';
import * as systemActions from 'lib/redux/system/actions';

import DateTimeSimpleInput from 'components/ui/DateTimeSimpleInput';
import FormToolbar from 'components/shift/elements/FormToolbar';

import './styles.css';
import validator from './validator';
import ShiftExperienceRedirect from './ShiftExperienceRedirect';

const styles = () => ({
  show: {
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  toolbar: {
    display: 'flex',
  },
});

const breakOptions = [
  { id: 0, name: 'No Break' },
  { id: 15, name: '15 Minutes' },
  { id: 30, name: '30 Minutes' },
  { id: 45, name: '45 Minutes' },
  { id: 60, name: '1 Hour' },
];

class ShiftCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      allShiftUnits: [],
      rates: null,
      unitChoices: [],
      typeChoices: [],
      unitDescriptionChoices: [],
      allowedQualifications: [],
      defaultsSet: false,
      defaultShiftDescription: null,
      defaultShiftQualifications: null,
      defaultConfirmOpen: false,
      diffDescription: false,
      diffQualifications: false,
      useCloned: false,
      unitDescriptionMandatory: false,
      covidVaccineRequired: null,
      covidMedicalExemption: null,
      covidReligiousExemption: null,
    };
  }

  componentDidMount() {
    this.getData();

    Storage.on('facility/changed', () => {
      this.getData();
    });
  }

  componentDidUpdate(prevProps) {
    this.update(prevProps);
  }

  componentWillUnmount() {
    Storage.off('facility/changed', this.getData);
  }

  getData = () => {
    let query = gql`
      query Me {
        Me {
          ... on MeFacility {
            id
            name
            email
            login
            timezone
            allowedQualifications
            covidStatus
            rates {
              unit
              type
              description
              defaultShiftDescription
              defaultShiftQualifications
            }
            covidVaccineRequired
            covidMedicalExemption
            covidReligiousExemption
          }
        }
        allShiftUnits {
          id
          name
        }
        allQualificationTypes {
          id: name
          name
        }
      }
    `;
    let variables = {};

    const role = Storage.getParam('user/role');
    if (role === 'admin') {
      const id = Number(Storage.getParam('admin/facility/id'));
      query = gql`
        query Facility($id: Int) {
          Me: Facility(id: $id) {
            id
            name
            email
            login
            timezone
            allowedQualifications
            covidStatus
            rates {
              unit
              type
              description
              defaultShiftDescription
              defaultShiftQualifications
            }
            covidVaccineRequired
            covidMedicalExemption
            covidReligiousExemption
          }
          allShiftUnits {
            id
            name
          }
          allQualificationTypes {
            id: name
            name
          }
        }
      `;
      variables = { id };
    }

    client
      .query({ query, variables, fetchPolicy: 'no-cache' })
      .then(this.onGetData)
      .catch((error) => {
        console.log(error.message);
      });

    this.setState({ isLoading: true });
  };

  onGetData = ({ data }) => {
    const { clearFields } = this.props;
    const { Me, allShiftUnits, allQualificationTypes } = data;

    let allowedQualifications = (Me.allowedQualifications || []).map(
      (name) => ({ id: name, name })
    );
    if (
      !Array.isArray(allowedQualifications) ||
      !allowedQualifications.length
    ) {
      allowedQualifications = allQualificationTypes;
    }

    if (this?.state?.allShiftUnits?.length > 0) {
      clearFields('unit', 'type', 'unitDescription');
    }
    this.setState(
      {
        isLoading: false,
        allShiftUnits,
        rates: Me.rates,
        allowedQualifications,
        covidStatus: Me.covidStatus,
        covidVaccineRequired: Me.covidVaccineRequired,
        covidMedicalExemption: Me.covidMedicalExemption,
        covidReligiousExemption: Me.covidReligiousExemption,
      },
      this.update
    );
  };

  update = (prevProps) => {
    const { unitValue, typeValue } = this.props;

    if (!prevProps) {
      this.updateUnitChoices(
        prevProps && prevProps.unitValue && prevProps.typeValue
      );
    }
    if (!prevProps || prevProps.unitValue !== unitValue) {
      this.updateTypeChoices(
        prevProps && prevProps.unitValue && prevProps.typeValue
      );
    }
    if (!prevProps || prevProps.typeValue !== typeValue) {
      this.updateUnitDescriptionChoices(
        prevProps && prevProps.unitValue && prevProps.typeValue
      );
    }

    if (!prevProps || prevProps.typeValue !== typeValue) {
      this.updateDefaults();
    }
  };

  updateDefaults = () => {
    const {
      formData,
      rates,
      useCloned,
    } = this.state;
    const { unit, type } = formData;
    let rate = false;
    let diffDescription = false;
    let diffQualifications = false;

    if (rates) {
      rate = rates.filter(
        (f) =>
          f.unit == unit &&
          f.type == type &&
          (f.description == formData.unitDescription || !f.description)
      )[0];
    }

    if (unit && type) {
      // && !defaultsSet
      setTimeout(() => {
        if (!rate.defaultShiftDescription && !rate.defaultShiftQualifications) {
          return null;
        }

        if (
          formData?.description === '' ||
          !formData.description
          // && (!formData.qualifications || formData.qualifications.length === 0)
        ) {
          return this.handleDefaultsConfirm();
        }

        if (
          formData.description
            ?.replace(/[^\w\s]|_/g, '')
            .replace(/\s+/g, '')
            .toLowerCase() !==
            rate.defaultShiftDescription
              ?.replace(/[^\w\s]|_/g, '')
              .replace(/\s+/g, '')
              .toLowerCase() &&
          rate.defaultShiftDescription &&
          !useCloned
        ) {
          diffDescription = true;
        }

        if (
          JSON.stringify(formData.qualifications?.sort())?.toLowerCase() !==
            JSON.stringify(
              rate.defaultShiftQualifications?.sort()
            )?.toLowerCase() &&
          rate.defaultShiftQualifications &&
          rate.defaultShiftQualifications.length > 0 &&
          !useCloned
        ) {
          diffQualifications = true;
        }

        if (!diffDescription && !diffQualifications) {
          return null;
        }

        // this.updateAllowedQualificationTypes();
        this.setState({
          defaultConfirmOpen: true,
          diffDescription,
          diffQualifications,
          defaultShiftDescription: rate.defaultShiftDescription,
          defaultShiftQualifications: rate.defaultShiftQualifications,
          useCloned: false,
        });
      }, 1000);
    }
  };

  updateUnitChoices = (allowUpdate) => {
    const { clearFields } = this.props;
    const { allShiftUnits, rates } = this.state;
    const facilityIdValue = Storage.getParam('facility/id');

    if (!facilityIdValue || !allShiftUnits.length || !rates) {
      return;
    }

    const unitChoices = [];

    rates.forEach((rate) => {
      const unit = allShiftUnits.find((item) => item.id === rate.unit);
      if (!unit) {
        return;
      }
      const isExists = unitChoices.find((item) => item.id === unit.id);
      if (isExists) {
        return;
      }
      unitChoices.push({
        id: unit.id,
        name: unit.name,
      });
    });

    this.setState({ unitChoices });

    if (allowUpdate) {
      clearFields('unit', 'type', 'unitDescription');
    }
  };

  updateTypeChoices = (allowUpdate) => {
    const { unitValue = 0, clearFields } = this.props;
    const { rates } = this.state;
    const facilityIdValue = Storage.getParam('facility/id');

    if (!facilityIdValue || !rates) {
      return;
    }

    const typeChoices = rates
      .filter((rate) => rate.unit === unitValue)
      .map((rate) => ({
        id: rate.type,
        name: rate.type,
      }));

    const uniqueTypeChoices = [];
    for (const item of typeChoices) {
      const obj = uniqueTypeChoices.find((choice) => choice.id === item.id);
      if (!obj) {
        uniqueTypeChoices.push(item);
      }
    }

    this.setState({ typeChoices: uniqueTypeChoices });

    if (allowUpdate) {
      clearFields('type', 'unitDescription');
    }
  };

  updateUnitDescriptionChoices = (allowUpdate) => {
    const { rates } = this.state;
    const { unitValue = 0, typeValue = 0, clearFields } = this.props;
    const facilityIdValue = Storage.getParam('facility/id');

    if (!facilityIdValue || !rates) {
      return;
    }

    const typeChoices = rates
      .filter((rate) => rate.unit === unitValue)
      .map((rate) => ({
        id: rate.type,
        name: rate.type,
      }));

    const unitDescriptionChoices = rates
      .filter((rate) => rate.unit === unitValue && rate.type === typeValue)
      .filter((rate) => rate.description)
      .map((rate) => ({
        id: rate.description,
        name: rate.description,
      }));

    let unitDescriptionMandatory = false;
    if (
      unitDescriptionChoices.length > 0 &&
      unitDescriptionChoices.length === typeChoices.length
    ) {
      unitDescriptionMandatory = true;
    }

    this.setState({ unitDescriptionChoices, unitDescriptionMandatory });

    if (allowUpdate) {
      clearFields('unitDescription');
    }
  };

  handleChange = (event) => {
    this.setState({ defaultsSet: false });
  };

  formDataExtractor = ({ formData }) => {
    const {
      formData: stateFormData,
      rates,
      defaultsSet,
      defaultShiftDescription,
      defaultShiftQualifications,
    } = this.state;

    if (JSON.stringify(stateFormData) !== JSON.stringify(formData)) {
      if (formData.type && formData.type != '') {
        let rate = false;

        if (rates) {
          rate = rates.filter(
            (f) =>
              f.unit == formData.unit &&
              f.type == formData.type &&
              (f.description == formData.unitDescription || !f.description)
          )[0];
        }

        if (rate && !defaultsSet) {
          if (rate.defaultShiftDescription) {
            formData.description = rate.defaultShiftDescription;
          } else if (defaultShiftDescription) {
            if (
              formData.description
                .replace(/[^\w\s]|_/g, '')
                .replace(/\s+/g, '')
                .toLowerCase() ===
              defaultShiftDescription
                .replace(/[^\w\s]|_/g, '')
                .replace(/\s+/g, '')
                .toLowerCase()
            ) {
              formData.description = rate.defaultShiftDescription;
            }
          }
          if (rate.defaultShiftQualifications) {
            formData.qualifications = rate.defaultShiftQualifications;
          } else if (defaultShiftQualifications) {
            if (
              JSON.stringify(formData.qualifications?.sort()).toLowerCase() ===
              JSON.stringify(defaultShiftQualifications?.sort()).toLowerCase()
            ) {
              formData.qualifications = rate.defaultShiftQualifications;
            }
          }

          this.setState(
            {
              defaultShiftDescription: rate.defaultShiftDescription,
              defaultShiftQualifications: rate.defaultShiftQualifications,
              defaultsSet: true,
            },
            () => {
              this.forceUpdate();
            }
          );
        }
      } else {
        formData.description = '';
        formData.qualifications = [];
        this.setState({
          defaultShiftDescription: '',
          defaultShiftQualifications: [],
        });
      }
      this.setState({ formData });
    }
  };

  checkShowConfirm = () => {
    const { formData, covidStatus } = this.state;
    const { start_time: timeStart, end_time: timeEnd } = formData ?? {};

    if (!timeStart || !timeEnd) {
      return null;
    }
    const timezone = currentTimezone();
    const role = Storage.getParam('user/role');
    // if the start time is in the past no need to show the alert the data are invalid
    if (
      role !== 'admin' &&
      timeStart &&
      moment(timeStart).seconds(0).milliseconds(0) <
        moment().tz(timezone).seconds(0).milliseconds(0)
    ) {
      return null;
    }
    if (
      covidStatus !== 'on_site' &&
      formData?.unitDescription?.toLowerCase() === 'covid unit'
    ) {
      return {
        isShow: true,
        data: {
          title: 'Confirmation',
          content:
            'The COVID status for this location is currently COVID-Free, but you are posting a shift within a COVID Unit. Do you now have COVID cases at this location?',
          confirm: 'Yes',
          confirmColor: 'primary',
          cancel: 'Cancel',
        },
      };
    }

    const scheduleDiff = moment(timeEnd).diff(moment(timeStart), 'hours');
    if (scheduleDiff < 13) {
      return null;
    }

    return {
      isShow: true,
      data: {
        title: 'Confirmation',
        content: `This shift will be scheduled for a total duration of ${scheduleDiff} ${pluralize(
          'hour',
          Number(scheduleDiff)
        )}. Are you sure you want to schedule a shift this long?`,
        confirm: 'Submit',
        confirmColor: 'primary',
        cancel: 'Cancel',
      },
    };
  };

  handleDefaultsConfirm = () => {
    const { formData, rates } = this.state;
    const { unit, type } = formData;
    let rate = false;

    if (rates) {
      rate = rates.filter(
        (f) =>
          f.unit == unit &&
          f.type == type &&
          (f.description == formData.unitDescription || !f.description)
      )[0];
    }

    formData.description = rate.defaultShiftDescription;
    formData.qualifications = rate.defaultShiftQualifications;

    // this.updateAllowedQualificationTypes();
    this.setState(
      {
        formData,
        defaultsSet: false,
        defaultConfirmOpen: false,
        defaultShiftDescription: rate.defaultShiftDescription,
        defaultShiftQualifications: rate.defaultShiftQualifications,
        useCloned: false,
      },
      () => {
        this.forceUpdate();
        document.getElementById('description').focus();
      }
    );
  };

  handleDefaultsCancel = () => {
    this.setState({
      defaultConfirmOpen: false,
      defaultsSet: true,
      useCloned: true,
    });
  };

  covidVaccineRequiredOutput = () => {
    const {
      covidVaccineRequired,
      covidMedicalExemption,
      covidReligiousExemption,
    } = this.state;
    let exceptions = '';
    if (covidVaccineRequired) {
      if (covidMedicalExemption && covidReligiousExemption) {
        exceptions = 'Religious and Medical exemptions are accepted.';
      }
      if (covidMedicalExemption && !covidReligiousExemption) {
        exceptions = 'Medical exemptions are accepted.';
      }
      if (!covidMedicalExemption && covidReligiousExemption) {
        exceptions = 'Religious exemptions are accepted.';
      }

      return (
        <>
          {'Select any additional qualifications required for this shift. '}
          <div style={{ fontWeight: 'bold' }}>
            {'COVID Vaccination is required for all shifts. '}
            {exceptions}
          </div>
        </>
      );
    }
    return <>Select any qualifications required for this shift.</>;
  };

  render() {
    const {
      isLoading,
      unitChoices,
      typeChoices,
      unitDescriptionChoices,
      allowedQualifications,
      defaultShiftDescription,
      defaultShiftQualifications,
      defaultConfirmOpen,
      formData,
      diffDescription,
      diffQualifications,
      covidStatus,
      unitDescriptionMandatory,
    } = this.state;

    const {
      classes, resource, location,
    } = this.props;
    const timezone = currentTimezone();
    const hasDescription = Boolean(unitDescriptionChoices.length);

    const showConfirm = this.checkShowConfirm();
    const record = location?.state && location.state.record ? location.state.record : null;

    let rate = false;
    if (this.state.rates) {
      rate = this.state.rates.filter(
        (f) => f.unit == formData.unit && f.type == formData.type
      )[0];
    }

    return (
      <div className="layout-page" data-page={resource} data-mode="create">
        <Create
          {...this.props}
          actions={<CardActions />}
          className={hasDescription ? 'with-description' : null}
        >
          <SimpleForm
            redirect={(basePath, id) => `/OverviewShift/${id}/show`}
            validate={validator}
            toolbar={
              <FormToolbar
                isShowConfirm={showConfirm?.isShow}
                confirmation={showConfirm?.data}
                submitLabel="resources.OverviewShift.action.add"
                covidStatus={covidStatus}
                unitDescription={formData?.unitDescription?.toLowerCase()}
              />
            }
          >
            <FormDataConsumer>{this.formDataExtractor}</FormDataConsumer>
            <SelectInput
              source="unit"
              label="Shift unit"
              choices={unitChoices}
              onChange={this.handleChange}
              fullWidth
              disabled={isLoading}
            />
            <SelectInput
              source="type"
              label="Shift type"
              choices={typeChoices}
              onChange={this.handleChange}
              fullWidth
              disabled={isLoading}
            />
            <SelectInput
              source="unitDescription"
              label="Unit Classification"
              choices={unitDescriptionChoices}
              fullWidth
              disabled={isLoading}
              className={
                unitDescriptionChoices.length ? classes.show : classes.hide
              }
              validate={unitDescriptionMandatory ? [required()] : false}
            />

            <DateTimeSimpleInput
              source="start_time"
              label="Start time"
              timezone={timezone}
              fullWidth
            />
            <DateTimeSimpleInput
              source="end_time"
              label="End time"
              timezone={timezone}
              fullWidth
            />

            <LongTextInput
              source="description"
              label="Shift description"
              onFocus={(e) => mixpanelTrack('CREATE_SHIFT_DESCRIPTION')}
            />
            <Typography variant="caption" color="textSecondary" fullWidth>
              Provide any specialty requirements, desired expertise or
              instructions if any.
            </Typography>

            <SelectArrayInput
              label="Required qualification"
              source="qualifications"
              choices={allowedQualifications}
              fullWidth
              disabled={isLoading}
              onChange={(e) => mixpanelTrack('CREATE_SHIFT_QUALIFICATION')}
            />
            <Typography variant="caption" color="textSecondary" fullWidth>
              {this.covidVaccineRequiredOutput()}
            </Typography>

            <SelectInput
              source="breakTime"
              label="Break time (minutes)"
              choices={breakOptions}
              initialValue={0}
              fullWidth
              onChange={(e) => mixpanelTrack('CREATE_SHIFT_BREAK')}
            />
          </SimpleForm>
        </Create>
        <Confirm
          isOpen={defaultConfirmOpen}
          title={
            <div>
              Confirm
              {diffDescription && ' Description '}
              {diffDescription && diffQualifications && ' and '}
              {diffQualifications && ' Qualifications '}
            </div>
          }
          content={
            <div
              style={{ maxHeight: '300px', overflowY: 'auto', maxWidth: '99%' }}
            >
              <div>
                The
                {diffDescription && ' description '}
                {diffDescription && diffQualifications && ' and '}
                {diffQualifications && ' qualifications '}
                of the shift you are cloning differs from the default(s) for
                this shift type/unit. Which
                {diffDescription && ' description '}
                {diffDescription && diffQualifications && ' and '}
                {diffQualifications && ' qualifications '}
                would you like to use?
              </div>
              {diffQualifications && (
                <>
                  <div style={{ marginTop: '10px' }}>
                    <strong>Cloned Qualifications</strong>
                    <br />
                    {formData?.qualifications?.join(', ')}
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    <strong>Default Qualifications</strong>
                    <br />
                    {defaultShiftQualifications?.join(', ')}
                  </div>
                </>
              )}
              {diffDescription && (
                <>
                  <div style={{ marginTop: '10px' }}>
                    <strong>Cloned Description</strong>
                    <br />
                    {formData?.description}
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    <strong>Default Description</strong>
                    <br />
                    {defaultShiftDescription}
                  </div>
                </>
              )}
            </div>
          }
          onConfirm={this.handleDefaultsConfirm}
          onClose={this.handleDefaultsCancel}
          confirm="Use Default"
          cancel="Use Cloned"
        />
        <ShiftExperienceRedirect record={record} />
      </div>
    );
  }
}

ShiftCreate.propTypes = {
  classes: PropTypes.shape({
    show: PropTypes.string,
    hide: PropTypes.string,
  }),
  resource: PropTypes.string,
  unitValue: PropTypes.number,
  typeValue: PropTypes.number,
  clearFields: PropTypes.func,
  trackPostShift: PropTypes.func,
  location: PropTypes.shape({
    state: PropTypes.shape({
      record: PropTypes.shape({}),
    }),
  }),
};

ShiftCreate.defaultProps = {
  classes: {},
  resource: PropTypes.string,
  unitValue: 0,
  typeValue: 0,
  clearFields: () => {},
  trackPostShift: () => {},
  location: {
    state: {
      record: null,
    },
  },
};

export default connect(
  (state) => ({
    unitValue: formValueSelector('record-form')(state, 'unit'),
    typeValue: formValueSelector('record-form')(state, 'type'),
  }),
  (dispatch) => ({
    clearFields: (...fields) => {
      fields.forEach((fieldKey) => {
        dispatch(change('record-form', fieldKey, null));
        dispatch(untouch('record-form', fieldKey));
      });
    },
    trackPostShift: () => {
      dispatch(systemActions.trackPostShift());
    },
  })
)(translate(withStyles(styles)(ShiftCreate)));

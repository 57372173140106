import { onError } from 'apollo-link-error';
import { userLogout, showNotification } from 'react-admin';
import { trackForcedLogout } from '../../../redux/system/actions';

import context from '../../context';

let timeout = null;

const showNotify = (message, type) => {
  const { store } = context;

  if (timeout) {
    clearTimeout(timeout);
  }
  timeout = setTimeout(() => {
    store.dispatch(showNotification(message, type));
  }, 100);
};

const isHide = (error) => {
  if (/allEmailThreads|allChannels/i.test(error.message)) {
    return true;
  }
  return false;
};

export default onError(({
  operation, response, graphQLErrors, networkError,
}) => {
  const { store } = context;

  if (graphQLErrors && store && store.dispatch) {
    if (response && response.errors) {
      response.errors = [
        {
          ...response.errors[0],
          extensions: {
            ...(response.errors[0]?.extensions ?? {}),
            isCaught: true,
          },
        },
      ];
    }

    if (graphQLErrors.filter((error) => error.message === 'SESSION_ERROR_TOKEN').length > 0) {
      store.dispatch(trackForcedLogout({ graphQLErrors }));
      store.dispatch(userLogout('/'));
      showNotify('SESSION_ERROR_TOKEN', 'warning');
      return;
    }

    const error = graphQLErrors[0];
    if (!isHide(error)) {
      showNotify(error.message, 'warning');
    }
  }
  if (networkError) {
    // store.dispatch(trackForcedLogout({ networkError }));
    // store.dispatch(userLogout('/'));
    console.log('error.message22', networkError.message);
    showNotify(networkError.message, 'warning');
  }
});

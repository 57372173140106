const prepare = (source) => {
  const keys = Object.keys(source);
  const target = source instanceof Array ? [...source] : {...source};

  for (let i=0; i<keys.length; i++) {
    const key = keys[i];

    if (target[key] && target[key].__typename && /^Type/.test(target[key].__typename)) {
      const redefine = {...target[key]};
      delete redefine.__typename;
      target[key] = redefine;
      continue;
    }

    if (target[key] instanceof Object || target[key] instanceof Array) {
      target[key] = prepare(target[key]);
    }
  }

  return target;
};

const removeTypeName = fetcher => (uri, options) => {
  const body = JSON.parse(options.body);
  const variables = prepare(body.variables);

  return fetcher(uri, {...options, body: JSON.stringify({...body, variables})});
};

export default removeTypeName;

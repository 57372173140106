import { ApolloClient, HttpLink, from, split, InMemoryCache, gql } from 'apollo-boost';
import { WebSocketLink } from 'apollo-link-ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { getMainDefinition } from 'apollo-utilities';

import buildGraphQLProvider from 'ra-data-graphql-simple';

import { API_GQL as uri, API_WS as wsUri } from '../../config';

import { removeTypeName } from './middlewares/fetcher';
import { errorCatcher, authWS, authHTTP, fileToBase64 } from './middlewares/apollo';

import context from './context';

const fetcher = removeTypeName((uri, options) => {
  return fileToBase64.isPrepared(options).then(preparedOptions => fetch(uri, preparedOptions));
});

const httpLink = new HttpLink({ uri, fetch: fetcher });

export const SUBSCRIPTION_STATUS_CONNECTED = 1;

export const subscriptionClient = new SubscriptionClient(wsUri, {
  reconnect: true,
  timeout: 30000,
});
const wsLink = new WebSocketLink(subscriptionClient);

const wsConnect = from([
  errorCatcher,
  authWS,
  wsLink,
]);

const httpConnect = from([
  errorCatcher,
  authHTTP,
  fileToBase64,
  httpLink,
]);

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return (
      kind === 'OperationDefinition' && operation === 'subscription'
    );
  },
  wsConnect,
  httpConnect,
);

const options = {
  link
};

export { gql };

export const client = new ApolloClient({...options, cache: new InMemoryCache().restore({})});

export default (sourceContext) => {
  context.set(sourceContext);
  return buildGraphQLProvider({
    clientOptions: options,
  });
};

import { ApolloLink } from 'apollo-link';

const filePrepare = (source, path = []) => {
  const keys = Object.keys(source);
  const result = [];

  for (let i=0; i<keys.length; i++) {
    const key = keys[i];

    // if (source[key] && source[key].__typename && source[key].src) {
    //   result.push(new Promise(resolve => {
    //     resolve({
    //       path: [...path, key],
    //       src: source[key].src,
    //     });
    //   }));
    //   continue;
    // }

    if (source[key] && source[key].rawFile instanceof File) {
      result.push(new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(source[key].rawFile);

        reader.onload = () => resolve({
          path: [...path, key],
          data: reader.result,
          title: source[key].title,
        });
        reader.onerror = reject;
      }));
      continue;
    }

    if (source[key] instanceof Object || source[key] instanceof Array) {
      result.push(...filePrepare(source[key], [...path, key]));
    }
  }

  return result.filter(item => item !== undefined);
};

const merge = (variables, preparedValue) => {
  const {path, data, title, src} = preparedValue;

  if (!path || path.length === 0) {
    return false;
  }

  if (path.length === 1) {
    return variables[path.shift()] = { data, title, src };
  }

  let point = variables[path.shift()];
  while (path.length > 2) {
    point = point[path.shift()];
  }

  return variables[path.shift()] = { data, title, src };
};

/**
 * For save promise list, for waiting upload.
 */
let wait;

/**
 * @todo Implement middleware without "wait"
 * @type {ApolloLink}
 */
const fileToBase64 = new ApolloLink((operation, forward) => {
  if (!operation.variables) {
    return forward(operation);
  }

  wait = filePrepare(operation.variables);

  return forward(operation);
});

fileToBase64.isPrepared = (options) => {
  const body = JSON.parse(options.body);

  const result = Promise.all([...wait]).then(values => {
    values.forEach(preparedValue => merge(body.variables, preparedValue));

    return {...options, body: JSON.stringify(body)};
  });

  wait = null;
  return result;
};

export default fileToBase64;

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DateTimeField from 'ui/DateTimeField';

const styles = (theme) => ({
  wrapper: {
    marginBottom: theme.spacing.unit * 2,
  },
  field: {
    fontSize: '80%',
  },
  linkButton: {
    background: 'none!important',
    border: 'none',
    padding: '0!important',
    color: '#3f51b5',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

const CheckEvent = ({
  record, checkIn, checkOut, classes, checkinSupervisor, checkoutSupervisor, showCheckinPopup, showCheckoutPopup,
}) => (
  <div className={classes.wrapper}>
    <Typography variant="body1" color="textSecondary" className={classes.field}>
      {'Clock-in: '}
      <DateTimeField record={record} source={checkIn} />
      {record[checkinSupervisor] ? ` - Verified by ${record[checkinSupervisor]} ` : ''}
      {record[checkinSupervisor] ? <button type="button" onClick={showCheckinPopup} className={classes.linkButton}>(view)</button> : ''}
    </Typography>
    <Typography variant="body1" color="textSecondary" className={classes.field}>
      {'Clock-out: '}
      <DateTimeField record={record} source={checkOut} />
      {record[checkoutSupervisor] ? ` - Verified by ${record[checkoutSupervisor]} ` : ''}
      {record[checkoutSupervisor] ? <button type="button" onClick={showCheckoutPopup} className={classes.linkButton}>(view)</button> : ''}

    </Typography>
    <Typography variant="body1" color="textSecondary" className={classes.field}>
      {`Break: ${record.hasBreakTime ? `${record.breakTime} minutes` : 'None'}`}
    </Typography>
  </div>
);

CheckEvent.propTypes = {
  classes: PropTypes.shape({
    wrapper: PropTypes.string,
    field: PropTypes.string,
    linkButton: PropTypes.string,
  }),
  record: PropTypes.shape({
    hasBreakTime: PropTypes.bool,
    breakTime: PropTypes.number,
  }),
  checkIn: PropTypes.string,
  checkOut: PropTypes.string,
  checkinSupervisor: PropTypes.string,
  checkoutSupervisor: PropTypes.string,
  showCheckinPopup: PropTypes.func,
  showCheckoutPopup: PropTypes.func,
};

CheckEvent.defaultProps = {
  classes: null,
  record: null,
  checkIn: null,
  checkOut: null,
  checkinSupervisor: null,
  showCheckinPopup: null,
  checkoutSupervisor: null,
  showCheckoutPopup: null,
};

export default withStyles(styles)(CheckEvent);

import {
  SET_RELATION_TO_FACILITY,
} from './actionTypes';

export const setRelationToFacility = (nurseId, relation, relationAction, shiftApplicationId, resource, reason, callback) => ({
  type: SET_RELATION_TO_FACILITY,
  payload: {
    nurseId,
    relation,
    relationAction,
    shiftApplicationId,
    resource,
    reason,
  },
  callback,
});

import React from 'react';
import { pure } from 'recompose';

import { withStyles } from '@material-ui/core/styles';

import Avatar44 from '../../ui/Avatar44';

const styles = theme => ({
  header: {
    display: 'flex',
    background: '#fff',
    boxShadow: '0 0 1px rgba(0,0,0,0.2)',
    padding: theme.spacing.unit * 2,
  },
  info: {
    flex: 1,
    marginLeft: theme.spacing.unit * 2,
  },
  title: {

  },
  dateRange: {
    marginTop: Math.round(theme.spacing.unit * 1.25),
  },
  span: {
    color: '#999',
    fontSize: '0.8em',
  },
});

const Header = pure(({ image, title, role, dateRange, classes }) => (
  <div className={classes.header}>
    <Avatar44 src={image} />
    <div className={classes.info}>
      <div className={classes.title}>{title}</div>
      <div className={classes.dateRange}>{role}, <span className={classes.span}>{dateRange}</span></div>
    </div>
  </div>
));

export default withStyles(styles)(Header);

import React from 'react';
import { pure } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import moment from 'moment-timezone';
import CredentialSection from './CredentialSection';

const docTypes = {
  vaccination: 'Vaccinations',
  test: 'Test Results',
  certification: 'Certifications',
  other: 'Other',
};

const styles = (theme) => ({
  credentials: {
    marginTop: theme.spacing.unit * 3,
  },
  title: {
    fontSize: '1em',
    color: '#000',
    fontWeight: 'bold',
  },
  docName: {
    marginLeft: theme.spacing.unit * 1.5,
  },
  expiration: {
    fontSize: '0.8em',
    color: 'grey',
  },

});

const Credentials = pure(withStyles(styles)(({ record, classes }) => {
  const { credentials } = record;
  if (!record || !credentials || credentials.length === 0) {
    return (
      <></>
    );
  }
  const sortOrder = ['certification', 'vaccination', 'test', 'other'];
  // remove credentials without front or credentials that are expired or credentials that has no expiration date
  const filteredCredentials = credentials.filter((credential) => !(credential.expires && (!credential.expiration || moment(credential.expiration).isBefore(moment()))));
  filteredCredentials.sort((cred1, cred2) => sortOrder.indexOf(cred1.type) - sortOrder.indexOf(cred2.type));
  const groupByType = filteredCredentials.reduce((hash, obj) => ({ ...hash, [obj.type]: (hash[obj.type] || []).concat(obj) }), {});
  return (
    <Grid container spacing={2} className={classes.credentials}>
      {
          Object.keys(groupByType).map((type) => (
            <Grid item xs={6} className={classes.credentials}>
              <span className={classes.title}>{docTypes[type]}</span>
              <CredentialSection documents={groupByType[type]} record={record} classes={classes} />
            </Grid>
          ))
    }
    </Grid>
  );
}));
export default Credentials;

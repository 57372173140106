import { call, cancel } from 'redux-saga/effects';
import gql from 'graphql-tag';
import { showNotification } from 'ra-core';
import { client } from '../../../dataProvider';

import {
  TRACK_EVENT_SEND,
} from '../actions';
import Storage from '../../../utils/localStorage';
import mixpanel, { mixpanelTrack } from '../../../utils/mixpanel';

export const action = TRACK_EVENT_SEND;

const checkSource = {
  'from header': 'TOP',
  'from main menu': 'MENU',
  'from clone button': 'CLONE',
};

const track = async (payload) => {
  const { event, extra } = payload;
  const mutation = gql`
    mutation trackApplicationEvent($event: String, $extra: String) {
      trackApplicationEvent(event: $event, extra: $extra) {
        status
      }
    }
  `;

  const variables = { event, extra: JSON.stringify(extra) };

  try {
    await client.mutate({ fetchPolicy: 'no-cache', mutation, variables });
  } catch (e) {
    showNotification(e.message, 'warning');
  }
};

export default function* ({ payload = {} }) {
  const role = Storage.getParam('user/role');
  const token = Storage.getParam('user/token');
  const { event, extra } = payload;

  if (event === 'LOG_IN' && role === 'facility') {
    global.window.Intercom('trackEvent', 'MENU_LOG_IN');
  }

  let getSource;

  if (extra) {
    const { from } = extra;
    getSource = checkSource[from];
  }

  const stripEvents = {
    OPEN_SCREEN_MESSAGES: 'MESSAGES',
    OPEN_SCREEN_CALENDAR: 'CALENDAR',
    OPEN_SCREEN_SHIFT_OVERVIEW: 'SHIFT_OVERVIEW',
    OPEN_SCREEN_COMPLETED_SHIFTS: 'COMPLETED_SHIFTS',
    OPEN_SCREEN_CREATE_SHIFT: getSource === 'CLONE' ? 'SHIFT' : 'CREATE_SHIFT',
    OPEN_SCREEN_SETTINGS: 'SETTINGS',
    CANCEL_SHIFT: 'SHIFT_CANCEL',
    VIEW_APPLICANT: 'SHIFT_APPLICANT',
  };

  // THIS CHECKS IF THE EVENT GOT TRIGGERED FROM THE MENU OR TOP BUTTON
  mixpanelTrack(extra ? `${getSource ? `${getSource}_` : ''}${stripEvents[event] ? stripEvents[event] : event}` : `MENU_${stripEvents[event] ? stripEvents[event] : event}`, extra);

  if (!token || (role === 'admin' && event !== 'FORCED_LOGOUT')) {
    yield cancel();
  }

  yield call(track, payload, token);
}

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  image: {
    display: 'block',
    maxHeight: '100em',
  },
});

const SignatureImage = ({ record, source }) => {
  if (!record || !Array.isArray(record[source]) || record[source].length == 0) {
    return null;
  }
const signatures = record[source][0];
  return (
    <div>
      <img
        alt={'supervisor signasture'}
        src={signatures.src}
        style={{width: 500, height: 200}}
      />
    </div>
  );
};

SignatureImage.propTypes = {
  sortable: PropTypes.bool,
  source: PropTypes.string.isRequired,
  record: PropTypes.shape({
    src: PropTypes.string,
  }),
};

SignatureImage.defaultProps = {
  record: null,
  sortable: false,
};

export default withStyles(styles)(SignatureImage);

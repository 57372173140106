import './lib/polyfills';

import React, { Component } from 'react';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { Admin, Resource } from 'react-admin';

import CircularProgress from '@material-ui/core/CircularProgress';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import MessageIcon from '@material-ui/icons/Message';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import SettingsIcon from '@material-ui/icons/Settings';
import ListAltIcon from '@material-ui/icons/ListAlt';
import RegisterLaunchDarklyMetadata from 'utils/RegisterLaunchDarklyMetadata';
import Users from 'components/users/Users';
import nextGenAuthProviderBuilder from 'lib/nextGenAuth';
import authProviderBuilder from 'lib/authProvider';
import history from 'lib/history';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import CommonModalProvider from './context/CommonModalContext';
import CommonToastProvider from './context/ToastContext';

import createAdminStore from './lib/redux/createAdminStore';
import englishMessages from './lib/i18/en';
import russianMessages from './lib/i18/ru';
import dataProviderBuilder from './lib/dataProvider';

import { OverviewShiftList } from './components/overviewShift';
import { CompletedShiftList } from './components/completedShift';
import { ShiftShow, ShiftCreate, ShiftEdit } from './components/shift';
import { ApplicantShow } from './components/applicant';
import { FacilityList, FacilityEdit } from './components/facility';
import { ChatChannels, ChatChannel } from './components/chat';
import Calendar from './components/calendar/Calendar';
import CustomLogin from './components/customLogin/CustomLogin';
import ShiftTemplates from './components/shiftTemplates/ShiftTemplates';

import Layout from './components/layout/Layout';
import theme from './theme';
import NextGenLogout from './components/nextgenLogout/nextgenLogout';
import Messages from './components/messages/Messages';
import Settings from './components/settings/Settings';
import EditShift from './components/editShift/EditShift';

const Dashboard = () => <div />;

const messages = {
  en: englishMessages,
  ru: russianMessages,
};
const i18nProvider = (locale) => messages[locale];

class App extends Component {
  constructor({ flags }) {
    super();
    const { newLoginScreen } = flags;

    this.state = {
      ready: false,
    };
    this.newLoginScreen = newLoginScreen;

    const context = {
      store: {},
      history,
    };

    const apb = this.newLoginScreen
      ? nextGenAuthProviderBuilder
      : authProviderBuilder;
    this.authProvider = apb(context);
    dataProviderBuilder(context).then((dataProvider) => {
      this.dataProvider = dataProvider;
      this.store = createAdminStore({
        authProvider: this.authProvider,
        dataProvider,
        i18nProvider,
        history,
      });
      context.store.dispatch = this.store.dispatch;
      this.setState({ ready: true });
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    const { ready } = this.state;
    const { authProvider, store } = this;

    if (!ready) {
      return (
        <div className="loading">
          <CircularProgress size={50} />
        </div>
      );
    }

    return (
      <Provider store={store}>
        <CommonToastProvider>
          <CommonModalProvider>
            <Admin
              theme={theme}
              title="NurseDash Facility"
              appLayout={Layout}
              locale="en"
              clocale="en"
              loginPage={this.newLoginScreen ? CustomLogin : undefined}
              authProvider={authProvider}
              history={history}
              dashboard={Dashboard}
            >
              <RegisterLaunchDarklyMetadata />
              <Resource
                name="Calendar"
                icon={CalendarTodayIcon}
                list={Calendar}
              />

              <Resource
                name="Weekly Calendar"
                icon={CalendarTodayIcon}
                options={{isHide: true}}
              />

              <Resource
                name="OverviewShift"
                icon={PlaylistPlayIcon}
                list={OverviewShiftList}
                show={ShiftShow}
                create={ShiftCreate}
                edit={EditShift}
              />
              <Resource
                name="CompletedShift"
                icon={PlaylistAddCheckIcon}
                list={CompletedShiftList}
                show={ShiftShow}
              />
              <Resource
                name="Applicant"
                show={ApplicantShow}
                options={{ isHide: true }}
              />
              <Resource name="OverviewShift/create" icon={PlaylistAddIcon} />
              <Resource
                name="Shift Templates"
                icon={ListAltIcon}
                list={ShiftTemplates}
                options={{
                  isHide: true,
                }}
              />

              <Resource
                name="Messages"
                icon={MessageIcon}
                // list={ChatChannels}
                list={Messages}
                show={ChatChannel}
              />
              <Resource
                name="Facility"
                icon={SettingsIcon}
                list={FacilityList}
                edit={Settings}
                options={{ isHide: false }}
              />
              <Resource
                name="UserManagement"
                list={Users}
                options={{ isHide: true }}
              />
              <Resource
                name="NextGenLogout"
                list={NextGenLogout}
                options={{ isHide: true }}
              />
            </Admin>
          </CommonModalProvider>
        </CommonToastProvider>
      </Provider>
    );
  }
}

export default withLDConsumer()(App);

App.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  flags: PropTypes.any.isRequired,
};

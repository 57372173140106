import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { compose } from 'redux';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import Storage from 'utils/localStorage';
import * as shiftAction from 'lib/redux/shifts/actions';

const styles = (theme) => ({
  container: {
    paddingTop: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit,

    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing.unit * 1,
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2,
    },
  },
  headline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  title: {
    marginBottom: theme.spacing.unit * 1.75,
  },
  applicant: {
    marginTop: theme.spacing.unit * 1.5,
  },
  closeButton: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'inline-flex',
    },
    boxShadow: theme.shadows[1],
    '&:active': {
      boxShadow: theme.shadows[1],
    },
  },
});

const CloseButton = compose(
  connect((state) => ({
    isLoading: state.loading.stack[shiftAction.SHIFT_SEND_REVIEW],
  })),
  withStyles(styles),
)(({ classes, onClick, isLoading }) => (
  <Button variant="fab" mini className={classes.closeButton} onClick={onClick} disabled={isLoading}>
    <CloseIcon className={classes.closeIcon} />
  </Button>
));

const ShiftDetails = ({ classes, shift, onClose }) => {
  const timezone = Storage.getParam('facility/timezone');

  const getAssignedNurseName = useCallback(({
    applicantList, assigned_nurse_id: assignedNurseId,
  }) => applicantList.find(({ nurseId }) => nurseId === assignedNurseId)?.name, []);

  const handleClose = useCallback(() => {
    if (!onClose) {
      return;
    }
    setTimeout(() => {
      onClose();
    }, 200);
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.headline}>
        <Typography variant="headline" className={classes.title}>
          Review Shift
        </Typography>
        {onClose && <CloseButton onClick={handleClose} />}
      </div>
      <Typography variant="p" component="div">
        {shift.role}
      </Typography>
      <Typography color="textSecondary" variant="caption" component="div">
        {`${moment(shift.start_time).tz(timezone).format('dddd DD MMM h:mma')} - ${moment(shift.end_time).tz(timezone).format('h:mma')}`}
      </Typography>
      <Typography variant="p" component="div" className={classes.applicant}>
        {'Selected applicant: '}
        {getAssignedNurseName(shift)}
      </Typography>
    </div>
  );
};

ShiftDetails.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
    headline: PropTypes.string,
    title: PropTypes.string,
    applicant: PropTypes.string,
    closeButton: PropTypes.string,
    closeIcon: PropTypes.string,
  }),
  shift: PropTypes.shape({
    start_time: PropTypes.string,
    end_time: PropTypes.string,
    role: PropTypes.string,
  }),
  onClose: PropTypes.func,
};

ShiftDetails.defaultProps = {
  classes: null,
  shift: null,
  onClose: null,
};

export default withStyles(styles)(ShiftDetails);

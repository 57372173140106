import { reducerObjective } from '../utils';

import {
  SHIFT_FACILITY_POLICY,
} from './actions';

const initialState = {
  hoursToPay: null,
  cancellationPolicy: null,
};

const reducers = {
  [SHIFT_FACILITY_POLICY]: (state, { payload }) => {
    const { hoursToPay, cancellationPolicy } = payload;

    return ({
      ...state,
      hoursToPay,
      cancellationPolicy,
    });
  },
};

export default reducerObjective(reducers, initialState);

import React from 'react';
import PropTypes from 'prop-types';
import Rater from 'react-rater';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  wrapper: {
    marginBottom: theme.spacing.unit * 2,
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 12,
  },
});

const fetchNestedObjectData = (key, obj) => key.split('.').reduce(
  (item, nestedKey) => item && item[nestedKey],
  { ...obj },
);

const RatingField = ({
  record, source, label, classes,
}) => (
  <div className={classes.wrapper}>
    <div>
      <label className={classes.label} htmlFor={source}>{label}</label>
    </div>
    <Rater
      total={5}
      rating={fetchNestedObjectData(source, record)}
      interactive={false}
    />
  </div>
);

RatingField.propTypes = {
  classes: PropTypes.shape({
    wrapper: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  label: PropTypes.string,
  source: PropTypes.string.isRequired,
  record: PropTypes.shape({}).isRequired,
};

RatingField.defaultProps = {
  label: null,
};

export default withStyles(styles)(RatingField);

import { ApolloLink } from 'apollo-boost';

import { getHeaders } from './utils';

const authWS = new ApolloLink((operation, forward) => {
  operation.extensions = {
    ...operation.extensions,
    headers: getHeaders(),
  };

  return forward(operation);
});

export default authWS;

import React from 'react';
import { Field } from 'redux-form'
import { translate } from 'react-admin';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';

const styles = {
  editor: {
    border: '1px solid #F1F1F1',
    marginTop: -6,
    paddingLeft: 10,
    paddingRight: 10,
  },
  component: {
    borderBottom: '1px solid #939393',
    marginTop: 40
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginBottom: 5,
    fontSize: '1rem',
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left'
  }
};

class ConnectEditor extends React.Component {

  constructor(props) {
    super(props);
    const { value } = props.input;

    this.defaultEditorState = null;

    const contentBlock = htmlToDraft(value);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      this.defaultEditorState = EditorState.createWithContent(contentState);
    }

    this.onChange = this.onChange.bind(this);
  }

  onChange(editorState) {
    const { onChange } = this.props.input;

    onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  }

  render() {
    return <Editor
      editorStyle={styles.editor}
      onEditorStateChange={this.onChange}
      defaultEditorState={this.defaultEditorState}
    />;
  }
}

const HtmlInput = ({source, label, resource, translate}) => {
  return (
    <div style={styles.component}>
      <div style={styles.label}>
        {label || translate(`resources.${resource}.fields.${source}`, { _: source })}
      </div>
      <Field
        name={source}
        component={ConnectEditor}
      />
    </div>
  );
};

export default translate(HtmlInput)
import React from 'react';
import { pure } from 'recompose';

import { withStyles } from '@material-ui/core/styles';

import '../styles.css';

const styles = theme => ({
  shift: {
    margin: `${theme.spacing.unit * 2}px 0 0 0`,
  },
  role: {
    fontSize: '1em',
  },
  date: {
    fontSize: 'calc(13em / 16)',
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

const Shift = pure(withStyles(styles)(({ role, date, classes }) => (
  <div className={classes.shift}>
    <span className={classes.role}>{role}</span>, <span className={classes.date}>{date}</span>
  </div>
)));

export default Shift;

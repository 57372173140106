import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Rater from 'react-rater';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import 'react-rater/lib/react-rater.css';

const styles = (theme) => ({
  wrapper: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
  },
  rater: {
    display: 'flex',
    marginBottom: theme.spacing.unit * 0.5,
    '&>div': {
      marginRight: theme.spacing.unit * 0.25,
      '&>div': {
        fontSize: theme.spacing.unit * 3,
      },
      '&>div.will-be-active': {
        color: '#ffda80',
      },
      '&>div.is-active': {
        color: '#ffb400',
      },
    },
  },
});

const RatingInputComponent = ({
  input, meta, classes, label, isRequired,
}) => {
  const handleRatingChange = useCallback(({ rating }) => {
    input.onChange(rating);
  }, []);

  if (typeof meta === 'undefined') {
    throw new Error(`
      The RatingInput component wasn't called within a redux-form <Field>.
      Did you decorate it and forget to add the addField prop to your component?
      See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details.
    `);
  }

  const { touched } = meta;
  const error = isRequired && touched && !input.value;
  const errorText = 'This field is required';

  return (
    <div className={classes.wrapper}>
      <Typography variant="caption" component="div" color={error ? 'error' : 'textSecondary'}>{label}</Typography>
      <Rater
        className={classes.rater}
        total={5}
        rating={input.value}
        onRate={handleRatingChange}
      />
      {error && (
        <Typography variant="caption" component="div" color="error">{errorText}</Typography>
      )}
    </div>
  );
};

RatingInputComponent.propTypes = {
  classes: PropTypes.shape({
    wrapper: PropTypes.string,
    rater: PropTypes.string,
  }),
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
  }),
  label: PropTypes.string,
  isRequired: PropTypes.bool,
};

RatingInputComponent.defaultProps = {
  classes: null,
  input: null,
  meta: null,
  label: null,
  isRequired: false,
};

const RatingInput = withStyles(styles)(({
  classes, source, label, isRequired,
}) => (
  <Field
    component={RatingInputComponent}
    name={source}
    label={label}
    classes={classes}
    isRequired={isRequired}
  />
));

RatingInput.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  source: PropTypes.string.isRequired,
  label: PropTypes.string,
};

RatingInput.defaultProps = {
  label: null,
};

export default withStyles(styles)(RatingInput);

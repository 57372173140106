import React, { useEffect } from 'react';
import Storage from 'lib/utils/localStorage';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';

const RegisterLaunchDarklyMetadata = () => {
  const ldClient = useLDClient();
  const flags = useFlags();

  useEffect(() => {
    const userId = Storage.getParam('user/id');
    const ldContext = {
      kind: 'multi',
      facility: {
        facilityName: Storage.getParam('facility/name'),
        key: Storage.getParam('facility/id'),
        facilityLocation: Storage.getParam('facility/location'),
        facilitySegment: Storage.getParam('facility/segment'),
      },
      user: {
        key: userId,
        userRole: Storage.getParam('user/role'),
      },
    };

    ldClient.identify(ldContext);
  }, []);

  return <></>;
};
export default RegisterLaunchDarklyMetadata;

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Show,
  SimpleShowLayout,
  TextField,
  FunctionField,
  CardActions,
  EditButton,
  ListButton,
  RefreshButton,
  CloneButton,
} from 'react-admin';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { renderDateFormatterRange, renderQualifications } from 'lib/utils/render';
import * as systemAction from 'lib/redux/system/actions';
import SupervisorSignatureDialog from 'ui/SupervisorSignatureDialog';
import { mixpanelTrack } from 'lib/utils/mixpanel';
import getUrlForMicroShiftApp from 'utils/getUrlForMicroShiftApp';
import { withRouter } from 'react-router';
import Storage from '../../lib/utils/localStorage';

import Title from './Title';
import ShiftCancelDialog from './ShiftCancelDialog';
import ReviewShiftButton from './ReviewShiftButton';
import ReviewCompletedDetails from './ReviewCompletedDetails';
import Nurses from './Nurses';

const styles = (theme) => ({
  actions: {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
  },
  schedule: {
    marginBottom: theme.spacing.unit * 2,
  },
  applicants: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
  },
  applicantsIsEmpty: {
    textAlign: 'center',
  },
  loading: {
    display: 'flex',
    paddingTop: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 2,
    justifyContent: 'center',
  },
});

const Schedule = withStyles(styles)(({
  record, fieldStart, fieldEnd, classes,
}) => (
  <Typography variant="body1" color="textSecondary" className={classes.schedule}>
    {renderDateFormatterRange(record[fieldStart], record[fieldEnd])}
  </Typography>
));

const Actions = connect()(withStyles(styles)(withRouter(({
  dispatch, basePath, data, classes, match,
}) => {
  const { nextGenShiftPosting } = useFlags();
  const handleCloneClick = useCallback(() => {
    dispatch(systemAction.trackOpenScreenShiftCreate('clone button'));
    if (!nextGenShiftPosting) {
      const { id } = match.params;
      if (id) {
        const url = `${getUrlForMicroShiftApp}/copy/${id}`;
        window.location.href = url;
      }
    }
  }, [dispatch]);

  const handleRefreshButton = useCallback(() => {
    mixpanelTrack('SHIFT_REFRESH');
  }, [dispatch]);

  const handleEditButton = useCallback(() => {
    mixpanelTrack('SHIFT_EDIT');
  }, [dispatch]);

  const handleListButton = useCallback(() => {
    mixpanelTrack('SHIFT_LIST');
  }, [dispatch]);

  return (
    <CardActions className={classes.actions}>
      <CloneButton basePath={nextGenShiftPosting ? '/OverviewShift' : ''} record={data} onClick={handleCloneClick} />
      <EditButton basePath={basePath} record={data} onClick={handleEditButton} />
      <ListButton basePath={basePath} record={data} onClick={handleListButton} />
      <RefreshButton basePath={basePath} record={data} onClick={handleRefreshButton} />
    </CardActions>
  );
})));

const Applicants = withStyles(styles)(({ record, classes }) => {
  if (record.applicantCount === null) {
    return (
      <div className={classes.loading}>
        <CircularProgress className={classes.progress} />
      </div>
    );
  }

  record.applicantList = record.applicantList.sort((a, b) => {
    if (b.isReturningUser || a.isReturningUser) {
      if (b.completedShiftCount !== a.completedShiftCount) {
        return b.completedShiftCount - a.completedShiftCount;
      }

      return b.totalAutoselectPoint - a.totalAutoselectPoint;
    }

    return b.totalAutoselectPoint - a.totalAutoselectPoint;
  });

  const hasApplicants = record.applicantList.filter((item) => ['confirmed', 'selected', 'applied', 'interested'].includes(item.status)).length;

  if (hasApplicants === 0) {
    return (
      <div className={classes.applicants}>
        <Typography color="textSecondary" className={classes.applicantsIsEmpty} variant="caption">No applicants</Typography>
      </div>
    );
  }

  const confirmedList = record.applicantList.filter((item) => item.status === 'confirmed');
  const selectedList = record.applicantList.filter((item) => item.status === 'selected');
  const appliedList = record.applicantList.filter((item) => item.status === 'applied');
  const interestedList = record.applicantList.filter((item) => item.status === 'interested');

  return (
    <div className={classes.applicants}>
      {Boolean(confirmedList.length) && <Nurses title="Confirmed" list={confirmedList} id={record.id} />}
      {Boolean(selectedList.length) && <Nurses title="Selected" list={selectedList} id={record.id} />}
      {Boolean(appliedList.length) && <Nurses title="Applied" list={appliedList} id={record.id} />}
      {Boolean(interestedList.length) && <Nurses title="Previously interested" list={interestedList} id={record.id} />}
    </div>
  );
});

const SignaturePopup = ({
  record, isOpen, source, type, title, onClose,
}) => {
  const getAssignedNurseName = useCallback(({
    applicantList, assigned_nurse_id: assignedNurseId,
  }) => applicantList.find(({ nurseId }) => nurseId === assignedNurseId)?.name, []);
  const nurseName = getAssignedNurseName(record);

  return (
    <SupervisorSignatureDialog
      isOpen={isOpen}
      source={source}
      type={type}
      title={title}
      onClose={onClose}
      nurseName={nurseName}
      record={record}
    />
  );
};

SignaturePopup.propTypes = {
  source: PropTypes.string.isRequired,
  record: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

const ShiftShow = (props) => {
  const { resource, match, location } = props;
  const [showCheckInSignature, setShowCheckInSignature] = useState(null);
  const [showCheckOutSignature, setShowCheckOutSignature] = useState(null);

  const autoShowModal = location?.search === '?reviewModal=open';

  const handleShowCheckInSignature = useCallback(() => {
    setShowCheckInSignature(true);
  }, []);

  const handleShowCheckOutSignature = useCallback(() => {
    setShowCheckOutSignature(true);
  }, []);

  const handleSupervisorClose = useCallback(() => {
    setShowCheckInSignature(false);
    setShowCheckOutSignature(false);
  }, []);

  if (Storage.getParam('covidChangedFromShift') === 'true') {
    Storage.setParam('covidChangedFromShift', 'false');
    setTimeout(() => {
      alert('Thanks for letting us know. The COVID Status for your location has been updated to COVID On-Site.');
    }, 1000);
  }

  return (
    <div className="layout-page" data-page={resource} data-mode="show" data-item={match.params.id}>
      <Show actions={<Actions />} {...props}>
        <SimpleShowLayout>
          <Title source="role" />
          <Schedule fieldStart="start_time" fieldEnd="end_time" />
          <TextField source="description" />
          <FunctionField source="qualifications" render={renderQualifications} />
          <ShiftCancelDialog />
          <Applicants />
          <ReviewShiftButton autoShowModal={autoShowModal} center />
          <ReviewCompletedDetails handleShowCheckInSignature={handleShowCheckInSignature} handleShowCheckOutSignature={handleShowCheckOutSignature} />

          <SignaturePopup
            isOpen={showCheckInSignature}
            source="checkInSupervisorSignature"
            type="in"
            title="Supervisor Signature"
            onClose={handleSupervisorClose}
          />
          <SignaturePopup
            isOpen={showCheckOutSignature}
            source="checkOutSupervisorSignature"
            type="out"
            title="Supervisor Signature"
            onClose={handleSupervisorClose}
          />
        </SimpleShowLayout>
      </Show>
    </div>
  );
};

ShiftShow.propTypes = {
  resource: PropTypes.string.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  }).isRequired,
};

ShiftShow.defaultProps = {
  location: {},
};

export default ShiftShow;

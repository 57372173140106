import React from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getUserPhoto } from '../../../lib/chat/Utils';
import { renderDateFormatterRange } from '../../../lib/utils/render';
import { bindActionCreatorsObjective } from '../../../lib/redux/utils';

import '../styles.css';
import * as chatActions from '../../../lib/redux/chat/actions';

import ScreenLayout from '../../ui/ScreenLayout';
import Header from './Header';
import List from './List';

const styles = theme => ({
  paper: {
    display: 'flex',
    height:'100%',
  },
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  send: {
    display: 'flex',
    background: '#fff',
    boxShadow: '0 0 1px rgba(0,0,0,0.2)',
  },
  input: {
    flex: 1,
    border: 0,
    background: 0,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
});

class ChatChannel extends React.Component {

  constructor(props) {
    super(props);

    this.input = React.createRef();
    this.state = {
      text: '',
    };
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    window.requestAnimationFrame(() => {
      if (!this.messageChat) {
        return false;
      }

      this.messageChat.style.height = 0;
      this.messageChat.style.height = `${this.messageChat.parentNode.clientHeight}px`;
    });
  };

  handleTextChange = (event) => {
    this.setState({text: event.target.value});
  };

  handleSendQuery = (event) => {
    if (!event || ((event.keyCode || event.charCode) !== 13)) {
      return;
    }

    this.handleMessage();
  };

  handleMessage = () => {
    const channelId = Number(this.props.channelId);
    const { text } = this.state;

    this.props.chatActions.sendMessage(channelId, text);

    this.setState({ text: '' });
  };

  componentDidUpdate() {
    if (this.input) {
      this.input.current.focus();
    }
  }

  render() {
    const { channelId, channel, messages, messagesOrder, classes, hasPrev, ...props } = this.props;

    const { text } = this.state;

    const { applicant = {}, members = [] } = channel || {};
    const { shift = {}} = applicant;

    const { role, start_time, end_time } = shift;

    const membersMap = {};
    members.forEach(member => membersMap[member.id] = member);
    members.forEach(member => membersMap[member.user.userModel] = member);

    const nurse = membersMap['nurse'];

    return (
      <ScreenLayout {...props} item={channelId}>
        <Paper zDepth={1} className={classes.paper}>
          <div className={classes.container} ref={ref => this.messageChat = ref}>
            {channel && (
              <Header
                image={getUserPhoto(nurse.user)}
                title={nurse.user.name}
                role={role}
                dateRange={renderDateFormatterRange(start_time, end_time)}
              />
            )}
            {(!channel || !messages) && (
              <div className={classes.loader}>
                <CircularProgress />
              </div>
            )}
            {channel && messages && (
              <List
                members={membersMap}
                messagesOrder={messagesOrder}
                messages={messages}
                hasPrev={hasPrev}
              />
            )}
            <div className={classes.send}>
              <input
                className={classes.input}
                ref={this.input}
                onChange={this.handleTextChange}
                value={text}
                onKeyPress={this.handleSendQuery}
                placeholder="Type message here" />
              <Button onClick={this.handleMessage} disabled={!text.trim().length}>
                <SendIcon />
              </Button>
            </div>
          </div>
        </Paper>
      </ScreenLayout>
    );
  }
}

export default connect(
  (state, props) => ({
    channelId: (props.match && props.match.params && props.match.params.id) ? props.match.params.id : 0,
    channel: state.chat.channels === null ? null : (state.chat.channels || {})[props.match.params.id],
    messages: state.chat.messages,
    hasPrev: state.chat.hasPrev,
    messagesOrder: state.chat.messagesOrder,
  }),
  dispatch => bindActionCreatorsObjective({ chatActions }, dispatch)
)(withStyles(styles)(ChatChannel));

import russianMessages from 'ra-language-russian';

export default {
  ...russianMessages,
  resources: {
    User: {
      name: 'Пользователь |||| Пользователи',
      fields: {
        name: 'Имя',
        ['role.id']: 'Роль',
        password: 'Пароль',
        email: 'E-Mail',
        description: 'Описание',
        createdAt: 'Создано',
        updatedAt: 'Обновлено',
      }
    },
    Role: {
      name: 'Роль |||| Роли',
      fields: {
        alias: 'Псевдоним',
        title: 'Имя роли',
        grant: 'Права',
        grantType: 'Тип',
        grantName: 'Операция',
        grantFields: 'Поля',
        createdAt: 'Создано',
        updatedAt: 'Обновлено',
      }
    }
  }
}
import { takeEvery } from 'redux-saga/effects';

import trackApplicationEventSaga, { action as trackApplicationEventSagaAction } from './trackApplicationEvent';
import trackLoginEventSaga, { action as trackLoginEventSagaAction } from './trackLogin';
import trackLogoutEventSaga, { action as trackLogoutEventSagaAction } from './trackLogout';
import redirectToNextgen, { action as trackRedirectToNextgenAction } from './redirectToNextgen';

export default () => function* () {
  yield takeEvery(trackApplicationEventSagaAction, trackApplicationEventSaga);
  yield takeEvery(trackLoginEventSagaAction, trackLoginEventSaga);
  yield takeEvery(trackLogoutEventSagaAction, trackLogoutEventSaga);
  yield takeEvery(trackRedirectToNextgenAction, redirectToNextgen);
};

export const SUBSCRIPTION_DISCONNECTED = 'SUBSCRIPTION_DISCONNECTED';
export const SUBSCRIPTION_RECONNECTED = 'SUBSCRIPTION_RECONNECTED';

export const subscriptionDisconnected = () => ({
  type: SUBSCRIPTION_DISCONNECTED,
});

export const subscriptionReconnected = () => ({
  type: SUBSCRIPTION_RECONNECTED,
});

export const RESET_CHAT = 'RESET_CHAT';

export const REQUEST_CHANNELS = 'REQUEST_CHANNELS';
export const RESPONSE_CHANNELS = 'RESPONSE_CHANNELS';

export const RECEIVE_CHANNEL = 'RECEIVE_CHANNEL';

export const REQUEST_MESSAGES = 'REQUEST_MESSAGES';
export const RESPONSE_MESSAGES = 'RESPONSE_MESSAGES';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';

export const SEND_MESSAGE = 'SEND_MESSAGE';
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';

export const REQUEST_CHANNELS_BY_IDS = 'REQUEST_CHANNELS_BY_IDS';
export const RESPONSE_CHANNELS_BY_IDS = 'REQUEST_CHANNELS_BY_IDS';

export const START_CHANNEL_LISTENING = 'START_CHANNEL_LISTENING';
export const FINISH_CHANNEL_LISTENING = 'FINISH_CHANNEL_LISTENING';

export const resetChat = () => ({
  type: RESET_CHAT,
});

export const receiveChannel = (channel) => ({
  type: RECEIVE_CHANNEL,
  payload: { channel },
});

export const requestChannels = (first = 0, count = 20) => ({
  type: REQUEST_CHANNELS,
  payload: { first, count },
});

export const responseChannels = (channels) => ({
  type: RESPONSE_CHANNELS,
  payload: { channels },
});

export const requestMessages = (channelId, first = 0, count = 25, after = '') => ({
  type: REQUEST_MESSAGES,
  payload: { channelId, first, count, after },
});

export const responseMessages = (messages, hasPrev = false) => ({
  type: RESPONSE_MESSAGES,
  payload: { messages, hasPrev },
});

export const clearMessages = () => ({
  type: CLEAR_MESSAGES,
  payload: null,
});

export const sendMessage = (channelId, text) => ({
  type: SEND_MESSAGE,
  payload: { channelId: Number(channelId), text },
});

export const receiveMessage = (message) => ({
  type: RECEIVE_MESSAGE,
  payload: { message },
});

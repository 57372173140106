import { v4 as uuidv4 } from 'uuid';
import Bowser from 'bowser';

import Storage from 'utils/localStorage';

export const getHeaders = () => {
  const token = Storage.getParam('user/token');
  const device = Bowser.parse(window.navigator.userAgent);

  const result = {
    request: JSON.stringify({
      id: uuidv4(),
      datetime: (new Date()).toISOString(),
    }),
    device: JSON.stringify({
      browserName: device?.browser?.name,
      browserVersion: device?.browser?.version,
      osName: device?.os?.name,
      osVersion: device?.os?.version,
      osVersionName: device?.os?.versionName,
      platformModel: device?.platform?.model,
      platformType: device?.platform?.type,
    }),
  };

  if (token) {
    result.authorization = `Bearer ${token}`;
  }

  return result;
};

export const AUTH_ROLE_SET = 'AUTH_ROLE_SET';
export const AUTH_ROLE_REQUEST = 'AUTH_ROLE_REQUEST';

export const roleSet = (grants = [], showResources = [], startResource = null) => {
  return {
    type: AUTH_ROLE_SET,
    payload: {
      grants,
      showResources,
      startResource,
    },
  }
};

export const roleRequest = () => {
  return {
    type: AUTH_ROLE_REQUEST,
  }
};

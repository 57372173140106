import englishMessages from 'ra-language-english';

export default {
  ...englishMessages,
  resources: {
    OverviewShift: {
      name: 'Shift Overview |||| Shift Overview',
      fields: {
      },
      action: {
        add: 'Post shift',
        save: 'Save shift',
      },
    },
    CompletedShift: {
      name: 'Past Shifts |||| Past Shifts',
      fields: {
      },
    },
    CreateShift: {
      name: 'Create Shift |||| Create Shift',
      fields: {
      },
    },
    Messages: {
      name: 'Messages |||| Messages',
      fields: {
      },
    },
    'OverviewShift/create': {
      name: 'Create Shift |||| Create Shift',
    },
    Applicant: {
      name: 'Applicant |||| Applicants',
    },
    Facility: {
      name: 'Settings |||| Settings',
      fields: {
      },
    },
    Calendar: {
      name: 'Calendar |||| Calendar',
    },
    'Shift Templates': {
      name: 'Shift Templates |||| Shift Templates',
      fields: {
      },
    },
  },
  'Shift application selected': 'Shift application selected',
  'Shift application applied': 'Shift application applied',

  Forbidden: 'Access denied',
  'TypeError: Cannot read property \'data\' of undefined': 'Server error, please try later',
  'Shift application select': 'Shift application select',
  'Shift application unselect': 'Shift application unselect',
  'GraphQL error: Could not query "allOverviewShifts" (permission: query::OverviewShift) with your permissions for role: guest GraphQL error: Could not query "_allOverviewShiftsMeta" (permission: query::OverviewShift) with your permissions for role: guest': 'Please sign-in',

  'Need auth': 'Please sign-in',
  SESSION_ERROR_TOKEN: 'Your session expired',
  ERROR_CONNECTION: 'Error internet connection',
};

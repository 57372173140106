import { reducerObjective } from '../utils';

import {
  RESPONSE_CHANNELS,
  RESPONSE_MESSAGES,
  RESET_CHAT,
  RECEIVE_MESSAGE,
  RECEIVE_CHANNEL,
  START_CHANNEL_LISTENING,
  FINISH_CHANNEL_LISTENING, CLEAR_MESSAGES,
} from './actions';

const initialState = {
  listenChannel: 0,
  channels: null,
  channelsOrder: [],
  messages: null,
  messagesOrder: [],
  hasPrev: false,
};

const reducers = {
  [RESET_CHAT]: (state, { payload }) => {
    return initialState;
  },

  [RESPONSE_CHANNELS]: (state, { payload }) => {
    const channels = { ...state.channels };

    payload.channels.forEach((item) => {
      channels[item.id] = item;
    });

    const channelsOrder = Object.values(channels)
      .sort((channelLeft, channelRight) => {
        return (channelLeft.lastMessage || channelLeft).createdAt < (channelRight.lastMessage || channelRight).createdAt ? 1 : -1;
      })
      .map(item => Number(item.id));

    return { ...state, channels, channelsOrder };
  },

  [RESPONSE_MESSAGES]: (state, { payload }) => {
    const messages = { ...state.messages };
    payload.messages.forEach((item) => messages[item.id] = item);
    const messagesOrder = Object.keys(messages)
      .sort((item1, item2) => (messages[item1].createdAt < messages[item2].createdAt ? -1 : 1))
      .map(item => item);

    return { ...state, messages, messagesOrder, hasPrev: payload.hasPrev };
  },

  [RECEIVE_MESSAGE]: (state, { payload }) => {
    const { message } = payload;

    return {
      ...state,
      messages: { ...state.messages, [message.id]: message },
      messagesOrder: [ ...state.messagesOrder, message.id ]
    };
  },

  [CLEAR_MESSAGES]: (state) => {
    return { ...state, messages: null, messagesOrder: [] };
  },

  [RECEIVE_CHANNEL]: (state, { payload }) => {
    const { channel } = payload;

    const channels = { ...state.channels };

    channels[channel.id] = {
      ...channels[channel.id],
      ...channel
    };

    const channelsOrder = Object.values(channels)
      .sort((channelLeft, channelRight) => {
        return (channelLeft.lastMessage || channelLeft).createdAt < (channelRight.lastMessage || channelRight).createdAt ? 1 : -1;
      })
      .map(item => Number(item.id));

    return { ...state, channels, channelsOrder };
  },
  [START_CHANNEL_LISTENING]: (state, { payload }) => {
    return { ...state, listenChannel: payload.channelId };
  },
  [FINISH_CHANNEL_LISTENING]: (state) => {
    return { ...state, listenChannel: 0 };
  },
};

export default reducerObjective(reducers, initialState);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import pluralize from 'pluralize';
import {
  Edit, SimpleForm, LongTextInput, SelectInput, SelectArrayInput, translate, FormDataConsumer,
} from 'react-admin';

import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { formValueSelector, change, untouch } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import currentTimezone from 'lib/utils/currentTimezone';
import Storage from 'lib/utils/localStorage';
import { client } from 'lib/dataProvider';

import DateTimeSimpleInput from 'components/ui/DateTimeSimpleInput';
import BreakTime from 'components/shift/elements/BreakTime';
import Actions from 'components/shift/elements/Actions';
import FormToolbar from 'components/shift/elements/FormToolbar';

import './styles.css';
import validator from './validator';

const styles = () => ({
  show: {
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  toolbar: {
    display: 'flex',
  },
});

class ShiftEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      allShiftUnits: [],
      rates: null,
      unitChoices: [],
      typeChoices: [],
      unitDescriptionChoices: [],
      allowedQualifications: [],
      formData: {},
      covidVaccineRequired: null,
      covidMedicalExemption: null,
      covidReligiousExemption: null,
    };
  }

  componentDidMount() {
    this.getData();
    Storage.on('facility/changed', this.getData);
  }

  componentDidUpdate(prevProps) {
    this.update(prevProps);
  }

  componentWillUnmount() {
    Storage.off('facility/changed', this.getData);
  }

  getData = () => {
    let query = gql`
      query Me {
        Me {
          ... on MeFacility {
            id
            name
            email
            login
            timezone
            allowedQualifications
            rates {
              unit
              type
              description
            }
            covidVaccineRequired
            covidMedicalExemption
            covidReligiousExemption
          }
        }
        allShiftUnits {
          id
          name
        }
        allQualificationTypes {
          id:name
          name
        }
      }
    `;
    let variables = {};

    const role = Storage.getParam('user/role');
    if (role === 'admin') {
      const id = Number(Storage.getParam('admin/facility/id'));
      query = gql`
        query Facility($id: Int) {
          Me:Facility(id: $id) {
            id
            name
            email
            login
            timezone
            allowedQualifications
            rates {
              unit
              type
              description
            }
            covidVaccineRequired
            covidMedicalExemption
            covidReligiousExemption
          }
          allShiftUnits {
            id
            name
          }
          allQualificationTypes {
            id:name
            name
          }
        }
      `;
      variables = { id };
    }

    client
      .query({ query, variables, fetchPolicy: 'no-cache' })
      .then(this.onGetData)
      .catch((error) => {
        console.log(error.message);
      });

    this.setState({ isLoading: true });
  }

  onGetData = ({ data }) => {
    const { clearFields } = this.props;
    const { Me, allShiftUnits, allQualificationTypes } = data;

    let allowedQualifications = (Me.allowedQualifications || []).map((name) => ({ id: name, name }));
    if (!Array.isArray(allowedQualifications) || !allowedQualifications.length) {
      allowedQualifications = allQualificationTypes;
    }

    if (this?.state?.allShiftUnits?.length > 0) {
      clearFields('unit', 'type', 'unitDescription');
    }
    this.setState({
      isLoading: false,
      allShiftUnits,
      rates: Me.rates,
      allowedQualifications,
      covidVaccineRequired: Me.covidVaccineRequired,
      covidMedicalExemption: Me.covidMedicalExemption,
      covidReligiousExemption: Me.covidReligiousExemption,
    }, this.update);
  }

  update = (prevProps) => {
    const { unitValue, typeValue } = this.props;

    if (!prevProps) {
      this.updateUnitChoices(prevProps && prevProps.unitValue && prevProps.typeValue);
    }
    if (!prevProps || prevProps.unitValue !== unitValue) {
      this.updateTypeChoices(prevProps && prevProps.unitValue && prevProps.typeValue);
    }
    if (!prevProps || prevProps.typeValue !== typeValue) {
      this.updateUnitDescriptionChoices(prevProps && prevProps.unitValue && prevProps.typeValue);
    }
  }

  updateUnitChoices = (allowUpdate) => {
    const { clearFields } = this.props;
    const { allShiftUnits, rates } = this.state;
    const facilityIdValue = Storage.getParam('facility/id');

    if (!facilityIdValue || !allShiftUnits.length || !rates) {
      return;
    }

    const unitChoices = [];

    rates.forEach((rate) => {
      const unit = allShiftUnits.find((item) => item.id === rate.unit);
      if (!unit) {
        return;
      }
      const isExists = unitChoices.find((item) => item.id === unit.id);
      if (isExists) {
        return;
      }
      unitChoices.push({
        id: unit.id,
        name: unit.name,
      });
    });

    this.setState({ unitChoices });

    if (allowUpdate) {
      clearFields('unit', 'type', 'unitDescription');
    }
  }

  updateTypeChoices = (allowUpdate) => {
    const { unitValue = 0, clearFields } = this.props;
    const { rates } = this.state;
    const facilityIdValue = Storage.getParam('facility/id');

    if (!facilityIdValue || !rates) {
      return;
    }

    const typeChoices = rates.filter((rate) => rate.unit === unitValue).map((rate) => ({
      id: rate.type,
      name: rate.type,
    }));

    this.setState({ typeChoices });

    if (allowUpdate) {
      clearFields('type', 'unitDescription');
    }
  }

  updateUnitDescriptionChoices = (allowUpdate) => {
    const { rates } = this.state;
    const { unitValue = 0, typeValue = 0, clearFields } = this.props;
    const facilityIdValue = Storage.getParam('facility/id');

    if (!facilityIdValue || !rates) {
      return;
    }

    const unitDescriptionChoices = rates
      .filter((rate) => (rate.unit === unitValue && rate.type === typeValue))
      .filter((rate) => rate.description)
      .map((rate) => ({
        id: rate.description,
        name: rate.description,
      }));

    this.setState({ unitDescriptionChoices });

    if (allowUpdate) {
      clearFields('unitDescription');
    }
  }

  formDataExtractor = ({ formData }) => {
    const { formData: stateFormData } = this.state;
    if (JSON.stringify(stateFormData) !== JSON.stringify(formData)) {
      this.setState({ formData });
    }
  };

  checkShowConfirm = () => {
    const { formData } = this.state;
    const {
      start_time: timeStart,
      end_time: timeEnd,
    } = formData;

    const scheduleDiff = moment(timeEnd).diff(moment(timeStart), 'hours');
    const timezone = currentTimezone();
    const role = Storage.getParam('user/role');
    if (role !== 'admin' && timeStart && moment(timeStart).seconds(0).milliseconds(0) < moment().tz(timezone).seconds(0).milliseconds(0)) {
      return null;
    }
    if (scheduleDiff < 13) {
      return null;
    }

    return {
      isShow: true,
      data: {
        title: 'Confirmation',
        content: `This shift will be scheduled for a total duration of ${scheduleDiff} ${pluralize('hour', Number(scheduleDiff))}. Are you sure you want to schedule a shift this long?`,
        confirm: 'Submit',
        confirmColor: 'primary',
        cancel: 'Cancel',
      },
    };
  };

  covidVaccineRequiredOutput = () => {
    const { covidVaccineRequired, covidMedicalExemption, covidReligiousExemption } = this.state;
    let exceptions = '';
    if (covidVaccineRequired) {
      if (covidMedicalExemption && covidReligiousExemption) {
        exceptions = 'Religious and Medical exemptions are accepted.';
      }
      if (covidMedicalExemption && !covidReligiousExemption) {
        exceptions = 'Medical exemptions are accepted.';
      }
      if (!covidMedicalExemption && covidReligiousExemption) {
        exceptions = 'Religious exemptions are accepted.';
      }

      return (
        <>
          {'Select any additional qualifications required for this shift. '}
          <div style={{ fontWeight: 'bold' }}>
            {'COVID Vaccination is required for all shifts. '}
            {exceptions}
          </div>
        </>
      );
    }
    return (<>Select any qualifications required for this shift.</>);
  };

  render() {
    const {
      isLoading,
      unitChoices,
      typeChoices,
      unitDescriptionChoices,
      allowedQualifications,
    } = this.state;
    const { classes, resource } = this.props;
    const timezone = currentTimezone();
    const hasDescription = Boolean(unitDescriptionChoices.length);

    const showConfirm = this.checkShowConfirm();


    return (
      <div className="layout-page" data-page={resource} data-mode="edit">
        <Edit {...this.props} actions={<Actions />} className={hasDescription ? 'with-description' : null}>
          <SimpleForm
            redirect={(basePath, id) => `/OverviewShift/${id}/show`}
            validate={validator}
            toolbar={(
              <FormToolbar
                isShowConfirm={showConfirm?.isShow}
                confirmation={showConfirm?.data}
                submitLabel="resources.OverviewShift.action.save"
              />
            )}
          >
            <FormDataConsumer>{this.formDataExtractor}</FormDataConsumer>
            <SelectInput
              source="unit"
              label="Shift unit"
              choices={unitChoices}
              fullWidth
              disabled={isLoading}
            />
            <SelectInput
              source="type"
              label="Shift type"
              choices={typeChoices}
              fullWidth
              disabled={isLoading}
            />
            <SelectInput
              source="unitDescription"
              label="Unit Classification"
              choices={unitDescriptionChoices}
              fullWidth
              disabled={isLoading}
              className={unitDescriptionChoices.length ? classes.show : classes.hide}
            />

            <DateTimeSimpleInput
              source="start_time"
              label="Start time"
              timezone={timezone}
              fullWidth
            />
            <DateTimeSimpleInput
              source="end_time"
              label="End time"
              timezone={timezone}
              fullWidth
            />

            <LongTextInput source="description" label="Shift description" />
            <Typography variant="caption" color="textSecondary" fullWidth>
              Provide any specialty requirements, desired expertise or instructions if any.
            </Typography>

            <SelectArrayInput
              label="Required qualification"
              source="qualifications"
              choices={allowedQualifications}
              fullWidth
              disabled={isLoading}
            />
            <Typography variant="caption" color="textSecondary" fullWidth>
              {this.covidVaccineRequiredOutput()}
            </Typography>
            <BreakTime source="breakTime" label="Break time (minutes)" fullWidth />
          </SimpleForm>
        </Edit>
      </div>
    );
  }
}

ShiftEdit.propTypes = {
  classes: PropTypes.shape({
    show: PropTypes.string,
    hide: PropTypes.string,
  }),
  resource: PropTypes.string,
  unitValue: PropTypes.number,
  typeValue: PropTypes.number,
  clearFields: PropTypes.func,
};

ShiftEdit.defaultProps = {
  classes: {},
  resource: PropTypes.string,
  unitValue: 0,
  typeValue: 0,
  clearFields: () => {},
};

export default connect((state) => ({
  unitValue: formValueSelector('record-form')(state, 'unit'),
  typeValue: formValueSelector('record-form')(state, 'type'),
}), (dispatch) => ({
  clearFields: (...fields) => {
    fields.forEach((fieldKey) => {
      dispatch(change('record-form', fieldKey, null));
      dispatch(untouch('record-form', fieldKey));
    });
  },
}))(translate(withStyles(styles)(ShiftEdit)));

import { takeEvery } from 'redux-saga/effects';

import sagaSendMessage, { action as sagaSendMessageAction } from './sagaSendMessage';
import sagaControllerChannels, { action as sagaControllerChannelsAction } from './sagaControllerChannels';
import sagaControllerChannel, { action as sagaControllerChannelAction } from './sagaControllerChannel';
import sagaRequestChannels, { action as sagaRequestChannelsAction } from './sagaRequestChannels';
import sagaRequestMessages, { action as sagaRequestMessagesAction } from './sagaRequestMessages';

export default () => {
  return function* () {
    yield takeEvery(sagaSendMessageAction, sagaSendMessage);
    yield takeEvery(sagaControllerChannelsAction, sagaControllerChannels);
    yield takeEvery(sagaControllerChannelsAction, sagaControllerChannels);
    yield takeEvery(sagaControllerChannelAction, sagaControllerChannel);
    yield takeEvery(sagaRequestChannelsAction, sagaRequestChannels);
    yield takeEvery(sagaRequestMessagesAction, sagaRequestMessages);
  };
};

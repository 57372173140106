import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { getUserPhoto, supportUserLogo } from '../../../lib/chat/Utils';

import {bindActionCreatorsObjective} from '../../../lib/redux/utils';
import * as chatActions from '../../../lib/redux/chat/actions';

import '../styles.css';

import Message from './Message';
import Storage from "../../../lib/utils/localStorage";

const styles = theme => ({
  list: {
    flex: 1,
    overflow: 'auto',
  },
  noMessages: {
    display: 'flex',
    flex: 1,
    height: '100%',
    color: '#999',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadMessages: {
    backgroundColor: '#eee',
    height: 40,
    width:'100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
});

class List extends React.PureComponent {

  constructor(props) {
    super(props);

    this.elementList = React.createRef();
  }

  scrollOffset = 0;

  componentDidMount() {
    this.scrollToLastMessage();
  }

  componentDidUpdate() {
    this.scrollToLastMessage();
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.messagesOrder) {
      return true;
    }

    if (this.props.messagesOrder.length !== nextProps.messagesOrder.length) {
      return true;
    }

    if (Object.keys(this.props.members).length !== Object.keys(nextProps.members).length) {
      return true;
    }

    return false;
  }

  // for autoload: this.elementList.current.addEventListener('scroll',(e) => e.target.scrollTop < 25
  loadPrev = () => {
    const { messagesOrder, messages } = this.props;

    const { channelId, id } = messages[messagesOrder[0]];
    this.props.chatActions.requestMessages(channelId, 0, 100, id);

    const list = this.elementList.current;
    this.scrollOffset = list.scrollHeight - list.clientHeight;
  };

  scrollToLastMessage() {
    if (!this.elementList) {
      return;
    }

    const list = this.elementList.current;

    list.scrollTop = list.scrollHeight - list.clientHeight - this.scrollOffset;

    this.scrollOffset = 0;
  }

  isMerged(currentMessage, nextMessage) {
    const maxSec = 120;
    if (!nextMessage) {
      return false;
    }
    if (currentMessage.member.id !== nextMessage.member.id) {
      return false;
    }
    return (new Date(nextMessage.createdAt) - new Date(currentMessage.createdAt)) / 1000 < maxSec;
  }

  render() {
    const { members, messagesOrder, messages, classes, hasPrev } = this.props;
    const currentUserId = Number(Storage.getParam('user/id'));

    return (
      <div className={classes.list} ref={this.elementList}>
        {!messagesOrder.length && (
          <div className={classes.noMessages}>
            <div>No chat messages</div>
          </div>
        )}
        {hasPrev && (
          <div className={classes.loadMessages} onClick={this.loadPrev}> load messages </div>
        )}
        {!!messagesOrder.length && messagesOrder.map((messageId, key) => {
          const message = messages[messageId];
          const author = members[message.member.id];

          if (!author) {
            return;
          }

          const isSelf = author.user.userModel === 'facility' && author.user.userId === currentUserId;
          const isAdmin = author.user.userModel === 'user';

          return(
            <Message
              key={message.id}
              avatar={isAdmin ? supportUserLogo : getUserPhoto(author.user)}
              text={message.text}
              date={message.createdAt}
              isMerged={this.isMerged(message, messages[messagesOrder[key+1]])}
              isSelf={isSelf}
              isAdmin={isAdmin}
            />);
          }
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreatorsObjective({ chatActions }, dispatch);

export default connect(null, mapDispatchToProps)(withStyles(styles)(List));

import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Favorite, FavoriteBorder, NotInterested } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setRelationToFacility } from '../../lib/redux/applicant/actions';
import ToastMessages from './utils/ToastMessages';
import { useRelationToast } from '../../context/ToastContext';
import DNRDialog from '../ui/DNRDialog';

const styles = (theme) => ({
  menu: {
    marginTop: theme.spacing.unit * 10,
  },
  columns: {
    maxHeight: '21rem',
  },
  primary: {
    color: '#373882',
  },
  textSecondary: {
    color: '#6B6B6B',
  },
});

const RelationMenu = ({
  classes,
  dispatch,
  record,
  resource,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { handleOpen } = useRelationToast();
  const [isOpen, setIsOpen] = useState(false);
  const renderMenuItems = () => {
    switch (record.relation) {
      case 'facility-favorite':
        return [{
          title: 'Remove from Favorites list', icon: <Favorite color="primary" />, id: 'facility-favorite', color: 'primary', action: 'remove',
        }, {
          title: 'Add to Do Not Return list', icon: <NotInterested />, id: 'do-not-return', color: 'textSecondary', action: 'add',
        }];
      case 'do-not-return':
        return [{
          title: 'Add to Favorites list', icon: <FavoriteBorder />, id: 'facility-favorite', color: 'textSecondary', action: 'add',
        }, {
          title: 'Remove from Do Not Return list', icon: <NotInterested color="primary" />, id: 'do-not-return', color: 'primary', action: 'remove',
        }];
      default:
        return [{
          title: 'Add to Favorites list', icon: <FavoriteBorder />, id: 'facility-favorite', color: 'textSecondary', action: 'add',
        }, {
          title: 'Add to Do Not Return list', icon: <NotInterested />, id: 'do-not-return', color: 'textSecondary', action: 'add',
        }];
    }
  };

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    if (isOpen) {
      setIsOpen(false);
    }
    setAnchorEl(null);
  };

  const undoFavorite = useCallback((action, relation) => {
    dispatch(
      setRelationToFacility(
        record.nurseId,
        relation,
        action,
        record.id,
        resource,
      ),
    );
  }, [record]);

  const showToast = useCallback((relation, relationAction) => {
    const message = ToastMessages[relation][relationAction];
    handleOpen({
      relation,
      message: `${record.name} ${message.message}`,
      variant: message.variant,
      title: message.title,
    }, undoFavorite);
  }, [record]);

  const setRelation = (selectedColumn, action, reason) => {
    dispatch(
      setRelationToFacility(
        record.nurseId,
        selectedColumn,
        action,
        record.id,
        resource,
        reason,
        showToast,
      ),
    );
    setTimeout(handleClose, 300);
  };

  const handleClick = (selectedColumn, action) => {
    if (selectedColumn === 'facility-favorite' || (selectedColumn === 'do-not-return' && action === 'remove')) {
      setRelation(selectedColumn, action);
    } else {
      setIsOpen(true);
    }
  };
  const confirmDNR = (reason) => {
    setRelation('do-not-return', 'add', reason);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  const availableRelations = renderMenuItems();
  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleOpenMenu}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        classes={{
          paper: classes.menu,
        }}
      >
        <div className={classes.columns}>
          {availableRelations.map((relation) => (
            <MenuItem key={relation.id} onClick={() => handleClick(relation.id, relation.action)} divider>
              <ListItemIcon className={classes[relation.color]}>
                {relation.icon}
              </ListItemIcon>
              <Typography variant="body1" color={relation.color}>{relation.title}</Typography>
            </MenuItem>
          ))}
        </div>
      </Menu>
      <DNRDialog isOpen={isOpen} onConfirm={confirmDNR} onClose={closeDialog} onCancel={closeDialog} />
    </div>
  );
};

RelationMenu.propTypes = {
  classes: PropTypes.shape({
    columns: PropTypes.string,
    menu: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  record: PropTypes.shape({
    nurseId: PropTypes.number,
    relation: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  resource: PropTypes.string.isRequired,
};
export default compose(
  connect(),
  withStyles(styles),
)(RelationMenu);

import { API_HOST } from '../config';

class Fetcher {
  constructor() {

  }

  /**
   * @private
   */
  run(url, method, data = {}, withToken = false) {
    const auth = withToken
      ? { Authorization: `Bearer ${localStorage.getItem('user/token')}` }
      : {};

    const body = !['GET'].includes(method) ? JSON.stringify(data) : null;
    const headers = new Headers({ 'Content-Type': 'application/json', ...auth });

    const request = new Request(url, { method, body, headers });

    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
  }

  /**
   *
   * @param url
   * @param data
   * @param withToken
   * @param host
   * @returns {*}
   */
  get(url, data, withToken = true, host = null) {
    return this.run(`${host || API_HOST}${url}`, 'GET', data, withToken);
  }

  /**
   *
   * @param url
   * @param data
   * @param withToken
   * @param host
   * @returns {*}
   */
  put(url, data, withToken = true, host = null) {
    return this.run(`${host || API_HOST}${url}`, 'PUT', data, withToken);
  }

  /**
   *
   * @param url
   * @param data
   * @param withToken
   * @param host
   * @returns {*}
   */
  post(url, data, withToken = true, host = null) {
    return this.run(`${host || API_HOST}${url}`, 'POST', data, withToken);
  }

  /**
   *
   * @param url
   * @param withToken
   * @param host
   * @returns {*}
   */
  delete(url, withToken = true, host = null) {
    return this.run(`${host || API_HOST}${url}`, 'DELETE', {}, withToken);
  }
}

export default new Fetcher();

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
  List as RAList, Filter, CardActions, CreateButton,
  SelectInput, Link,
} from 'react-admin';
import pluralize from 'pluralize';
import URLSearchParams from 'url-search-params';
import moment from 'moment-timezone';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { useFlags } from 'launchdarkly-react-client-sdk';
import DirectionMenu from './DirectionMenu';

import CloneButton from '../ui/CloneButton';

import { groupByDate } from '../../lib/utils/group';
import { renderDateFormatterRange } from '../../lib/utils/render';
import { bindActionCreatorsObjective } from '../../lib/redux/utils';

import * as systemActions from '../../lib/redux/system/actions';

import { Pagination } from '../ui';
import { cloneShift } from '../ui/utils';

const styles = (theme) => ({
  details: {
    display: 'block',
    paddingBottom: 0,
  },
  shift: {
    cursor: 'pointer',
    paddingRight: theme.spacing.unit * 4,
    [theme.breakpoints.up('sm')]: {
      paddingRight: 0,
    },
  },
  list: {
    padding: 0,
    margin: 0,
    marginBottom: theme.spacing.unit * 2,
  },
  item: {
    marginLeft: -theme.spacing.unit * 2,
    marginRight: -theme.spacing.unit * 2,
    [theme.breakpoints.up('sm')]: {
      marginLeft: -theme.spacing.unit * 3,
      marginRight: -theme.spacing.unit * 3,
    },
    width: 'auto',
  },
  icon: {
    fontSize: theme.spacing.unit * 2.5,
    marginRight: theme.spacing.unit / 2,
  },
});

const Status = ({ record }) => {
  let color = 'textSecondary';

  switch (record.status) {
    case 'cancelled':
    case 'deleted':
      color = 'error';
      break;
    case 'pending clock':
      color = 'primary';
  }

  return (
    <Typography color="textSecondary" style={{ display: 'flex' }}>
      <Typography color="textSecondary">Status:&nbsp;</Typography>
      <Typography color={color}>{record.status}</Typography>
    </Typography>
  );
};

const Applicants = ({ record }) => {
  if (!record || record.applicantCount === null) {
    return <Typography color="textSecondary">Loading applicants</Typography>;
  }

  const unavailableCount = record.applicantList.filter((item) => item.status === 'unavailable').length;

  if (record.applicantCount - unavailableCount === 0 || record.applicantList.length - unavailableCount === 0) {
    return <Typography color="textSecondary">No applicants</Typography>;
  }

  const selected = record.applicantList.find((item) => item.status === 'selected');

  if (!selected) {
    return (
      <Typography color="textSecondary">
        {record.applicantCount - unavailableCount}
        {' '}
        {pluralize('applicant', record.applicantCount - unavailableCount)}
      </Typography>
    );
  }

  const moreApplicants = record.applicantCount - unavailableCount - 1 > 0
    ? `, and ${record.applicantCount - unavailableCount - 1} more ${pluralize('applicant', record.applicantCount - unavailableCount - 1)}`
    : '';

  return (
    <Typography color="textSecondary">
      Selected applicant:
      {selected.name}
      {moreApplicants}
    </Typography>
  );
};

const Grid = withStyles(styles)(withRouter(({
  history, ids = [], data = {}, basePath, classes,
}) => {
  const byDate = groupByDate(data, ids);
  const { nextGenShiftPosting } = useFlags();

  const handleCloneButtonClicked = (clonedShiftId) => {
    cloneShift(clonedShiftId, nextGenShiftPosting);
  };
  return Object.keys(byDate).map((date) => (
    <ExpansionPanel key={date} defaultExpanded>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{date}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <List className={classes.list}>
          {byDate[date].map((shift) => (
            <ListItem key={shift.id} className={classes.item} dense button onClick={() => history.push(`/OverviewShift/${shift.id}/show`)}>
              <div className={classes.shift}>
                <Typography variant="subheading">{shift.role}</Typography>
                <Typography color="textSecondary">{renderDateFormatterRange(shift.start_time, shift.end_time)}</Typography>
                <Status record={shift} />
                <Applicants record={shift} />
              </div>
              <ListItemSecondaryAction>
                <CloneButton
                  record={shift}
                  ariaLabel="Clone shift"
                  basePath={nextGenShiftPosting ? 'OverviewShift' : null}
                  onClick={!nextGenShiftPosting ? () => {
                    handleCloneButtonClicked(shift?.id);
                  } : null}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  ));
}));

const Filters = (props) => (
  <Filter {...props}>
    <SelectInput
      source="status"
      label="Status"
      choices={[
        { name: 'Opened', id: 'opened' },
        { name: 'Confirmed', id: 'confirmed' },
        { name: 'Pending clock', id: 'pending clock' },
        { name: 'Cancelled', id: 'cancelled' },
      ]}
      canEmpty
    />
  </Filter>
);

const Actions = connect(
  null,
  (dispatch) => bindActionCreatorsObjective({ systemActions }, dispatch),
)(({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  direction,
  systemActions,
}) => (
  <CardActions>
    {filters && React.cloneElement(filters, {
      resource,
      showFilter,
      displayedFilters,
      filterValues,
      context: 'button',
    }) }
    <CreateButton basePath={basePath} onClick={() => { systemActions.trackOpenScreenShiftCreate('list button'); }} />
    {direction}
  </CardActions>
));

class OverviewShiftList extends Component {
  constructor(props) {
    super(props);

    const queryParams = new URLSearchParams(props.location.search);

    this.state = {
      direction: queryParams.get('direction') || 'new',
    };
  }

  onDirectionChange = (value) => {
    const { history, location } = this.props;
    this.setState({ direction: value });

    const queryParams = new URLSearchParams(location.search);
    const filter = JSON.parse(queryParams.get('filter')) || {};

    switch (value) {
      case 'new':
        queryParams.set('direction', 'new');
        queryParams.set('sort', 'start_time');
        queryParams.set('order', 'asc');
        filter.start_time = `gte#${moment().format('YYYY-MM-DD')}`;
        break;
      case 'old':
        queryParams.set('direction', 'old');
        queryParams.set('sort', 'start_time');
        queryParams.set('order', 'desc');
        filter.start_time = `lte#${moment().format('YYYY-MM-DD')}`;
        break;
    }

    queryParams.set('page', '1');
    queryParams.set('filter', JSON.stringify(filter));

    history.push(`${location.pathname}?${queryParams.toString()}`);
  };

  onPage = (newPage) => {
    const { history, location } = this.props;
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('page', newPage);
    history.replace(`${location.pathname}?${queryParams.toString()}`);
  };

  renderActions() {
    const { direction } = this.state;
    const { classes } = this.props;

    return (
      <Actions direction={(
        <DirectionMenu
          value={direction}
          options={[
            { text: 'new shifts', value: 'new', icon: <ChevronRightIcon className={classes.icon} /> },
            { text: 'old shifts', value: 'old', icon: <ChevronLeftIcon className={classes.icon} /> },
          ]}
          onChange={this.onDirectionChange}
        />
)}
      />
    );
  }

  renderNew() {
    return (
      <RAList
        sort={{ field: 'start_time', order: 'asc' }}
        filter={{ start_time: `gte#${moment().format('YYYY-MM-DD')}` }}
        perPage={10}
        {...this.props}
        title="Shifts overview"
        filters={<Filters />}
        pagination={<Pagination onPage={this.onPage} />}
        actions={this.renderActions()}
      >
        <Grid />
      </RAList>
    );
  }

  renderOld() {
    return (
      <RAList
        sort={{ field: 'start_time', order: 'desc' }}
        filter={{ start_time: `lte#${moment().format('YYYY-MM-DD')}` }}
        perPage={10}
        {...this.props}
        title="Shifts overview"
        filters={<Filters />}
        pagination={<Pagination onPage={this.onPage} />}
        actions={this.renderActions()}
      >
        <Grid />
      </RAList>
    );
  }

  render() {
    const { direction } = this.state;

    return (
      <div className="layout-page" data-page="shifts/overview" data-mode="list">
        {direction === 'new' && this.renderNew()}
        {direction === 'old' && this.renderOld()}
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(OverviewShiftList));

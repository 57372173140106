import React from 'react';
import PropTypes from 'prop-types';
import { NotInterested, Favorite, FavoriteBorder } from '@material-ui/icons';

const RelationIcon = ({ relation, showEmpty }) => {
  if (relation === 'do-not-return' && !showEmpty) {
    return (
      <NotInterested color="primary" fontSize="default" />
    );
  }
  if (relation === 'facility-favorite') {
    return (
      <Favorite color="primary" fontSize="default" />
    );
  }

  if (showEmpty) {
    return (
      <FavoriteBorder color="primary" fontSize="default" />
    );
  }

  return null;
};
RelationIcon.defaultProps = {
  relation: null,
  showEmpty: false,
};
RelationIcon.propTypes = {
  relation: PropTypes.string,
  showEmpty: PropTypes.bool,
};
export default RelationIcon;

const ToastMessages = {
  'do-not-return': {
    add: {
      title: 'Added to DNR list',
      message: 'will no longer be able to apply to shifts at your Facility',
      variant: 'DNR',
    },
    remove: {
      title: 'Removed from DNR list',
      message: 'will be able to apply to shifts at your Facility once again',
      variant: 'removeDNR',
    },
  },
  'facility-favorite': {
    add: {
      title: 'Favorite User',
      message: 'has been added to your Favorites list',
      variant: 'favorite',
    },
    remove: {
      title: 'Removed from Favorites list',
      message: 'has been removed from your Favorites list',
      variant: 'removeFavorite',
    },
  },
};
export default ToastMessages;

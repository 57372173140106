import { gql } from 'apollo-boost';
import { showNotification, crudGetMany } from 'ra-core';
import {
  call, put, all, cancel, select,
} from 'redux-saga/effects';

import { client } from 'lib/dataProvider';
import * as loadingAction from 'lib/redux/loading/actions';
import { SHIFT_SEND_REVIEW } from '../actions';

export const action = SHIFT_SEND_REVIEW;

export default function* ({ payload, callback }) {
  yield put({ type: 'RA/FETCH_START' });
  yield put(loadingAction.start(SHIFT_SEND_REVIEW));

  const {
    shiftId,
    review,
    relation,
    reason,
  } = payload;

  const shift = yield select((state) => state.admin.resources?.CompletedShift?.data[shiftId] ?? state.admin.resources?.OverviewShift?.data[shiftId]);

  if (!shift) {
    yield put(showNotification('Error send review, please try again.', 'warning'));
    yield put(loadingAction.end(SHIFT_SEND_REVIEW));
    yield put({ type: 'RA/FETCH_END' });
    yield cancel();
  }

  const mutations = [];

  if (mutations.length > 0) {
    try {
      yield all(mutations);
    } catch (error) {
      if (!error?.graphQLErrors) {
        yield put(showNotification(error.message, 'warning'));
      }
      yield put(loadingAction.end(SHIFT_SEND_REVIEW));
      yield put({ type: 'RA/FETCH_END' });
      yield cancel();
    }
  }

  const mutation = gql`
    mutation setShiftReview($shiftId: Int, $review: TypeSetShiftReviewInput, $relation: String, $reason: String ) {
      setShiftReview(shiftId: $shiftId, review: $review, relation: $relation, reason: $reason) {
        id
      }
    }
  `;

  try {
    yield call(client.mutate, {
      mutation,
      variables: {
        shiftId, review, relation, reason,
      },
      fetchPolicy: 'no-cache',
    });
  } catch (error) {
    if (!error?.graphQLErrors) {
      yield put(showNotification(error.message, 'warning'));
    }
    yield put(loadingAction.end(SHIFT_SEND_REVIEW));
    yield put({ type: 'RA/FETCH_END' });
    yield cancel();
  }

  yield put(loadingAction.end(SHIFT_SEND_REVIEW));
  yield put({ type: 'RA/FETCH_END' });
  yield put(crudGetMany('CompletedShift', [shiftId]));

  if (callback) {
    callback(relation);
  }
}

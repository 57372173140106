import React from 'react';
import { Filter, SelectInput } from 'react-admin';

const Filters = (props) => (
  <Filter {...props}>
    <SelectInput
      source="status"
      label="Status"
      choices={[
        { name: 'Completed', id: 'completed' },
        { name: 'Pending review', id: 'pending review' },
      ]}
      canEmpty
    />
  </Filter>
);

export default Filters;

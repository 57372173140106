import { ApolloLink } from 'apollo-boost';

import { getHeaders } from './utils';

const authHTTP = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: getHeaders(),
  });

  return forward(operation);
});

export default authHTTP;

import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import App from './App';
import './index.css';
import { LAUNCH_DARKLY_CLIENT_ID } from './config';

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: LAUNCH_DARKLY_CLIENT_ID,
    context: {
      kind: 'user',
      anonymous: true,
    },
    options: {
      streaming: true,
    },
  });

  // eslint-disable-next-line max-len
  (function () { const w = window; const ic = w.Intercom; if (typeof ic === 'function') { ic('reattach_activator'); ic('update', w.intercomSettings); } else { const d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (APP_ID) { i.q.push(args); }; w.Intercom = i; const l = function () { const s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = `https://widget.intercom.io/widget/${process.env.INTERCOM_APP_ID}`; const x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (document.readyState === 'complete') { l(); } else if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } } }());

  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENV,
  });

  ReactDOM.render(<LDProvider><App /></LDProvider>, document.getElementById('app'));
})();

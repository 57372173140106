export const SHIFT_SEND_REVIEW = 'SHIFT_SEND_REVIEW';

export const sendReview = (shiftId, review, checkInTime, checkOutTime, hasBreakTime, callback, relation, reason) => ({
  type: SHIFT_SEND_REVIEW,
  payload: {
    shiftId,
    review,
    checkInTime,
    checkOutTime,
    hasBreakTime,
    relation,
    reason,
  },
  callback,
});

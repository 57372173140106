import moment from 'moment-timezone';
import currentTimezone from 'lib/utils/currentTimezone';

export const groupByDate = (data, ids) => {
  const list = {};
  const timezone = currentTimezone();

  ids.forEach((id) => {
    const item = data[id];
    const date = moment(item.start_time).tz(timezone).format('DD MMMM YYYY');

    if (!list[date]) {
      list[date] = [item];
      return;
    }

    list[date].push(item);
  });

  return list;
};

export default null;

import React from 'react';
import { pure } from 'recompose';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';

import Avatar44 from '../../ui/Avatar44';

import { renderDateFormat } from '../../../lib/utils/render';

import '../styles.css';
import './ChatChannel.css';

const styles = theme => ({});

const Message = pure(({ avatar, name, text, date, isMerged, isSelf, isAdmin }) => (
  <div className={`messageChat__list__message${isMerged?' messageChat__list__message--merged':''}${isSelf?' messageChat__list__message--self':''}${isAdmin?' messageChat__list__message--admin':''}`}>
    <div className="messageChat__list__message__author">
      <Avatar44 src={avatar} />
      {!!name && <div className="messageChat__list__message__author__name">{name}</div>}
    </div>
    <div className="messageChat__list__message__body">
      <div className="messageChat__list__message__body__arr" />
      <div className="messageChat__list__message__body__text">{text}</div>
      <div className="messageChat__list__message__body__date">{renderDateFormat(date)}</div>
    </div>
  </div>
));

export default withStyles(styles)(Message);

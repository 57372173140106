import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import RelationToast from 'components/applicant/RelationToast';

const ToastContext = React.createContext({
  isOpen: false,
  toast: {
    relation: null,
    message: null,
    variant: null,
    title: null,
  },
  callBack: null,
});

export const useRelationToast = () => {
  const context = React.useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useRelationToast must be used within the ShiftReviewModalProvider');
  }

  return context;
};

const CommonToastContext = ({ children }) => {
  const [modalProps, setModalProps] = useState({ isOpen: false, toast: null, callBack: null });

  const handleOpen = useCallback((toast, callBack) => setModalProps({ isOpen: true, toast, callBack }), []);
  const handleClose = useCallback(() => setModalProps({ isOpen: false, toast: null }), []);
  const values = useMemo(() => ({ modalProps, handleOpen, handleClose }), []);

  return (
    <ToastContext.Provider value={values}>
      {children}
      {
        modalProps.isOpen
        && <RelationToast {...modalProps} onClose={handleClose} message={modalProps.toast.message} title={modalProps.toast.title} variant={modalProps.toast.variant} relation={modalProps.toast.relation} isOpen={modalProps.isOpen} onUndo={modalProps.callBack} />
      }
    </ToastContext.Provider>
  );
};

CommonToastContext.propTypes = {
  children: PropTypes.node,
};

CommonToastContext.defaultProps = {
  children: null,
};

export default CommonToastContext;

import React from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
// import UsersOptIn from 'components/users/UsersOptIn';

const Users = ({ flags }) => {
  const redirectPlaceholder = 'Flag is on, so redirect configuration will take effect.';
  // const { userManagement } = flags;
  return (
    <>
      <div className="layout-page">
        {{ redirectPlaceholder }}
      </div>
      <div>Redirecting you to the user management page...</div>
      )
    </>
  );
};

Users.propTypes = {
  flags: PropTypes.shape({ userManagement: PropTypes.bool.isRequired }).isRequired,
};

export default withLDConsumer()(Users);

import { put } from 'redux-saga/effects';
import { trackLogout, trackRedirectToNextgen } from '../actions';

export const action = 'AUTH/LOGOUT_SUCCESS';

export default function* ({ payload = {} }) {
  const { token } = payload;

  yield put({ ...trackLogout(), token });
  yield put({ ...trackRedirectToNextgen() });
}

/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import shouldUpdate from 'recompose/shouldUpdate';
import QueueIcon from '@material-ui/icons/Queue';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';

export const CloneButton = ({
  basePath = '',
  ariaLabel = '',
  record = {},
  ...rest
}) => (
  <IconButton
    component={Link}
    aria-label={ariaLabel}
    to={{
      pathname: `${basePath}/create`,
      state: { record },
    }}
    {...rest}
  >
    <QueueIcon />
  </IconButton>
);

CloneButton.defaultProps = {
  ariaLabel: '',
  onClick: null,
  basePath: '',
};

CloneButton.propTypes = {
  basePath: PropTypes.string,
  ariaLabel: PropTypes.string,
  record: PropTypes.shape({
  }).isRequired,
  onClick: PropTypes.func,
};

const enhance = shouldUpdate(
  (props, nextProps) => props.translate !== nextProps.translate
    || (props.record
      && nextProps.record
      && props.record !== nextProps.record)
    || props.basePath !== nextProps.basePath
    || (props.record == null && nextProps.record != null),
);

export default enhance(CloneButton);

import moment from 'moment-timezone';

const validator = (values) => {
  const errors = {};

  if (!values.checkInTime) {
    errors.checkInTime = ['Please change value'];
  }

  if (!values.checkOutTime) {
    errors.checkOutTime = ['Please change value'];
  }

  if (!values.overallRating) {
    errors.overallRating = ['Please change value'];
  }

  if (values.checkInTime && values.checkOutTime && moment(values.checkInTime) >= moment(values.checkOutTime)) {
    errors.checkInTime = ['Clock-in time must be earlier than clock-out time'];
  }

  return errors;
};

export default validator;

import React from 'react';
import { defaultUserLogo } from "./Avatar44";

const styles = {
  wrapper: {
    display: 'flex',
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundImage: `url(${defaultUserLogo})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    overflow: 'hidden',
  },
  image: {
    flex: 1,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '50%',
    boxShadow: `inset 0 0 ${2 / 16}em rgba(0,0,0,0.1)`,
  }
};

const AvatarMini = ({src, style = {}}) => (
  <div style={{...styles.wrapper, ...style}}><div style={{...styles.image, backgroundImage: `url(${src})`}} /></div>
);

export default AvatarMini;

import { eventChannel } from 'redux-saga';
import { cancel, call, take, put } from 'redux-saga/effects';
import { subscriptionClient } from '../../../../lib/dataProvider';
import {
  SUBSCRIPTION_DISCONNECTED,
  SUBSCRIPTION_RECONNECTED,
  subscriptionReconnected,
  subscriptionDisconnected,
} from '../actions'

export const action = 'READY';

let started = false;
let lastEmitStatus;

const listenSubscriptionEvents = () => eventChannel((emitter) => {

  subscriptionClient.on('disconnected', () => {
    if (lastEmitStatus === SUBSCRIPTION_DISCONNECTED) {
      return;
    }
    lastEmitStatus = SUBSCRIPTION_DISCONNECTED;
    console.log('subscriptionClient disconnected');
    emitter(SUBSCRIPTION_DISCONNECTED);
  });

  subscriptionClient.on('reconnected', () => {
    if (lastEmitStatus === SUBSCRIPTION_RECONNECTED) {
      return;
    }
    lastEmitStatus = SUBSCRIPTION_RECONNECTED;
    console.log('subscriptionClient reconnected');
    emitter(SUBSCRIPTION_RECONNECTED);
  });

  return () => {};
});

export default function* ({ type, payload }) {
  if (started) {
    yield cancel();
  }

  started = true;
  const listener = yield call(listenSubscriptionEvents);

  try {
    console.log('listen start');
    while (true) {
      const event = yield take(listener);

      if (event === SUBSCRIPTION_DISCONNECTED) {
        yield put(subscriptionDisconnected());
      }

      if (event === SUBSCRIPTION_RECONNECTED) {
        yield put(subscriptionReconnected());
      }
    }
  } catch (error) {
    console.log('listen error', error);
  } finally {
    console.log('listen closed');
  }
}

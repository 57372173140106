import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import Avatar44 from '../../ui/Avatar44';

import {getUserPhoto, supportUserLogo} from '../../../lib/chat/Utils';

import { bindActionCreatorsObjective } from '../../../lib/redux/utils';
import * as chatActions from '../../../lib/redux/chat/actions';

import { renderDateFormatterRange, renderDateFormat } from '../../../lib/utils/render';

import ItemBody from './ItemBody';
import Message from './Message';
import Shift from './Shift';

import '../styles.css';
import Storage from "../../../lib/utils/localStorage";

const styles = theme => ({
  item: {
    borderBottom: '1px solid #dedede',
    '&:last-child': {
      borderBottom: 'none',
    },
    '& > div': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    }
  },
  wrapper: {
    display: 'flex',
    margin: 0,
    width: '100%',
  },
  avatar: {
  },
  body: {
    marginLeft: theme.spacing.unit * 2,
    flex: 1,
  },
  bodyEmpty: {
    display: 'flex',
    marginLeft:  theme.spacing.unit * 2,
    flex: 1,
    alignItems: 'center',
  },
  loadMoreWrapper: {
    display: 'flex',
    paddingTop: theme.spacing.unit * 3,
    justifyContent: 'center',
  },
});

class ChatChannels extends React.Component {
  constructor(props) {
    super(props);

    this.rowsOnPage = 20;

    this.state = {
      showRows: this.rowsOnPage,
    };
  }

  componentDidMount = () => {
    this.props.chatActions.requestChannels();
  };

  componentWillUnmount = () => {
  };

  openChannel = (id) => {
    const { history } = this.props;
    setTimeout(() => { history.push(`/Messages/${id}/show`); }, 300);
  };

  showMore = () => {
    const { showRows } = this.state;
    const { rowsOnPage } = this;

    this.setState({ showRows: showRows + rowsOnPage });
  };

  renderChannels() {
    const { channelsOrder, channels, classes } = this.props;
    const { showRows } = this.state;

    if (!channelsOrder) {
      return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
        <CircularProgress />
      </div>;
    }

    if (!channelsOrder.length) {
      return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
        <div>No channels</div>
      </div>;
    }

    return channelsOrder.slice(0, showRows).map(key => {
      const channel = channels[key];
      const { id, applicant, lastMessage, members } = channel;
      const { shift = {}} = (applicant || {});

      const { role, start_time, end_time } = (shift || {});

      const { member = {}, text ='', createdAt } = (lastMessage || {});

      const currentUserId = Number(Storage.getParam('user/id'));

      const nurse = members.find(({user}) => user.userModel === 'nurse');
      const currentUser = members.find(({user}) => user.userModel === 'facility' && user.userId === currentUserId);

      if (!lastMessage) {
        return (
          <div key={id} className={classes.item}>
            <ListItem onClick={() => this.openChannel(id)} button={true}>
              <div className={classes.wrapper}>
                <div className={classes.avatar}><Avatar44 src={getUserPhoto(nurse.user)} /></div>
                <div className={classes.bodyEmpty}>
                  <Typography color="textSecondary" variant="caption">No messages</Typography>
                </div>
              </div>
              <Shift role={role} date={renderDateFormatterRange(start_time, end_time)}/>
            </ListItem>
          </div>
        );
      }

      const author = members.find(({id}) => id === member.id);

      const isAdmin = author.user.userModel === 'user';

      return (
        <div key={id} className={classes.item}>
          <ListItem onClick={() => this.openChannel(id)} button={true}>
            <div className={classes.wrapper}>
              <div className={classes.avatar}><Avatar44 src={getUserPhoto(nurse.user)} /></div>
              <div className={classes.body}>
                <ItemBody
                  name={nurse.user.name}
                  message={
                    <Message
                      avatar={isAdmin ? supportUserLogo : getUserPhoto(author.user)}
                      name={author.user.name}
                      text={text}
                      date={renderDateFormat(createdAt)}
                    />
                  }
                  unconsumedCount={currentUser ? currentUser.unreadCount : 0}
                />
              </div>
            </div>
            <Shift role={role} date={renderDateFormatterRange(start_time, end_time)}/>
          </ListItem>
        </div>
      );
    });
  }

  render() {
    const { classes, channelsOrder } = this.props;
    const { showRows } = this.state;

    return (
      <div className="layout-page" data-page="messages" data-mode="list">
        <div className="list-page">
          <Paper>
            <List title="Messages">
              {this.renderChannels()}
            </List>
          </Paper>
          {showRows < (channelsOrder || []).length && <div className={classes.loadMoreWrapper}>
            <Button variant="contained" onClick={this.showMore}>Show more</Button>
          </div>}
        </div>
      </div>
    );
  }
}

ChatChannels.propTypes = {
  classes: PropTypes.object.required,
  channelsOrder: PropTypes.array.required,
  channels: PropTypes.object.required,
  chatActions: PropTypes.object.required,
};

export default connect(
  state => ({
    channels: state.chat.channels,
    channelsOrder: state.chat.channelsOrder,
  }),
  dispatch => bindActionCreatorsObjective({ chatActions }, dispatch)
)(withRouter(withStyles(styles)(ChatChannels)));

import { gql } from 'apollo-boost';
import { eventChannel, END } from 'redux-saga';
import { call, put, cancel, take, select } from 'redux-saga/effects';
import {
  client,
  subscriptionClient,
  SUBSCRIPTION_STATUS_CONNECTED,
} from '../../../dataProvider';

import {
  requestChannels,
  receiveMessage,
  requestMessages,
  clearMessages,
  CLEAR_MESSAGES,
} from '../actions';

import {
  SUBSCRIPTION_DISCONNECTED,
  SUBSCRIPTION_RECONNECTED,
} from '../../subscription/actions';

export const action = [
  '@@router/LOCATION_CHANGE',
  SUBSCRIPTION_DISCONNECTED,
  SUBSCRIPTION_RECONNECTED,
];

let finishListeningMessages;

function startListeningMessages(channelId) {
  return eventChannel((emitter) => {

    finishListeningMessages = () => {
      emitter(END);
    };

    const query = gql`
      subscription($channelId: Int) {
        newMessageCreated(channelId: $channelId) {
          id
          messageChannelId:channelId
          text
          createdAt
          member {
            id
          }
        }
      }
    `;

    const variables = {
      channelId: Number(channelId),
    };

    const subscribe = client.subscribe({ query, variables }).subscribe({
      next({ data }) {
        if (!data || !data.newMessageCreated) {
          return;
        }

        const { id, text, createdAt, member, messageChannelId } = data.newMessageCreated;

        if (Number(messageChannelId) === Number(channelId)) {
          emitter({id, text, createdAt, member: {id: member.id}});
        }
      },
      error(err) {
        console.error('err', err);
      },
    });

    return () => {
      if (subscriptionClient.status === SUBSCRIPTION_STATUS_CONNECTED) {
        subscribe.unsubscribe();
      }
    };
  });
}

async function resetMemberUnreadCount(channelId) {
  const mutation = gql`
    mutation($channelId: Int) {
      resetMemberUnreadCount(channelId: $channelId) {
        result
      }
    }
  `;

  const variables = { channelId };

  try {
    client.mutate({ mutation, variables, fetchPolicy: 'no-cache' });
  } catch (error) {
    console.warn(error);
  }
}

export default function* ({ type, payload }) {
  if (type === SUBSCRIPTION_DISCONNECTED) {
    if (finishListeningMessages) {
      finishListeningMessages();
    }
    finishListeningMessages = null;
    yield cancel();
  }

  const location = yield select(state => state.router.location);
  const pathname = (payload || location).pathname;
  let chat = yield select(state => state.chat);

  const match = pathname.match(/\/Messages\/([0-9]+)\/show/i);

  if (finishListeningMessages) {
    finishListeningMessages();
    finishListeningMessages = null;
    yield take(CLEAR_MESSAGES);
    chat = yield select(state => state.chat);
  }

  if (!match || !match[1]) {
    yield cancel();
  }

  const channelId = Number(match[1]);

  if (chat.channels === null) {
    yield put(requestChannels());
  }

  if (chat.messages === null || type === SUBSCRIPTION_RECONNECTED) {
    yield put(requestMessages(channelId));
  }

  const channel = yield call(startListeningMessages, channelId);

  console.log('start listen channel', channelId);
  try {
    while (true) {
      yield call(resetMemberUnreadCount, channelId);
      const message = yield take(channel);
      yield put(receiveMessage(message));
    }
  } catch (error) {
    console.log('error listen channel');
  } finally {
    if (subscriptionClient.status === SUBSCRIPTION_STATUS_CONNECTED) {
      yield put(clearMessages());
    }
    console.log('finish listen channel', channelId);
  }
}

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { userCheck, refreshView } from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DomainIcon from '@material-ui/icons/Domain';

import Storage, { LS_SET } from 'lib/utils/localStorage';

class LayoutFacilityChange extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
    };

    this.onClick = this.onClick.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onChange = this.onChange.bind(this);
    this.update = this.update.bind(this);
  }

  componentDidMount() {
    Storage.on('facility/changed', this.update);
  }

  componentWillUnmount() {
    Storage.off('facility/changed', this.update);
  }

  update() {
    const { actionRefreshView, history } = this.props;

    if (/\/(CompletedShift|OverviewShift)\/[0-9]+\/show/.test(history.location.pathname)) {
      history.push('/')
    }

    actionRefreshView();
    this.forceUpdate();
  }

  onClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  onClose() {
    this.setState({ anchorEl: null });
  }

  onChange(id) {
    const { actionUserCheck } = this.props;
    actionUserCheck({ changeFacilityId: id });
    this.onClose();
  }

  render() {
    const { title } = this.props;
    const { anchorEl } = this.state;
    const role = Storage.getParam('user/role');
    const list = Storage.getParam('admin/facility/list');

    if (role !== 'admin' || !list) {
      return <div />;
    }

    const facilities = JSON.parse(list);

    return (
      <>
        <IconButton color="inherit" onClick={this.onClick}>
          <DomainIcon />
        </IconButton>
        <p>{title}</p>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.onClose}
        >
          {facilities.map(facility => (
            <MenuItem key={facility.id} onClick={this.onChange.bind(this, facility.id)}>{facility.name}</MenuItem>
          ))}
        </Menu>
      </>
    );
  }
}

const mapActionToProps = (dispatch) => ({
  actionUserCheck: (payload) => dispatch(userCheck(payload)),
  actionRefreshView: () => dispatch(refreshView()),
});

export default connect(null, mapActionToProps)(withRouter(LayoutFacilityChange));

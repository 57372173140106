import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import pure from 'recompose/pure';
import moment from 'moment-timezone';
import { FunctionField } from 'react-admin';
import { renderDateFormatterRange } from 'lib/utils/render';
import currentTimezone from 'lib/utils/currentTimezone';

export const dateTime = (date) => {
  let timezone = currentTimezone();
  if (!date) return moment().tz(timezone);
  if (date.length !== 27) return date;
  return moment(date).tz(timezone);
};

export const DateTimeField = ({ elStyle, locales, options, record, showTime = false, source, label, format = 'M/D/YY h:mm a', ...props }) => {
  if (!record) {
    return null;
  }

  const value = get(record, source);
  if (!value) {
    return <span className="empty">not set</span>;
  }

  let timezone = currentTimezone();
  let dateString = moment(value).tz(timezone).format(format);

  return <span style={elStyle}>{dateString}</span>;
};

export const DateTimeFieldWithLabel = (props) => (
  <FunctionField {...props} render={
    (record, source) => {
      return <DateTimeField record={record} source={source} />;
    }
  } />
);

DateTimeField.propTypes = {
  addLabel: PropTypes.bool,
  elStyle: PropTypes.object,
  label: PropTypes.string,
  options: PropTypes.object,
  record: PropTypes.object,
  source: PropTypes.string.isRequired
};

const PureDateTimeField = pure(DateTimeField);

PureDateTimeField.defaultProps = {
  addLabel: true
};

export default PureDateTimeField;

import { reducerObjective } from '../utils';

import {
  CALENDAR_RESET,
  CALENDAR_SET_DAYS,
  CALENDAR_SET_EVENTS,
  CALENDAR_SET_FAVORITE_FLAG,
} from './actions';

const initialState = {
  days: {},
  events: {},
  facilityFavorites: false,
};

const reducers = {
  [CALENDAR_RESET]: () => ({
    ...initialState
  }),
  [CALENDAR_SET_DAYS]: (state, { payload }) => {
    const { days, touchedDays } = payload;

    const nextDays = { ...state.days };

    Object.keys(nextDays).forEach(key => {
      const match = key.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/);
      if (!match) {
        return;
      }
      const date = match[0];
      if (touchedDays.includes(date)) {
        delete nextDays[key];
      }
    });

    Object.keys(days).forEach(key => {
      nextDays[key] = days[key];
    });

    return ({
      ...state, days: nextDays,
    });
  },
  [CALENDAR_SET_EVENTS]: (state, { payload }) => {
    const { events, touchedDays } = payload;

    const nextEvents = { ...state.events };

    Object.keys(nextEvents).forEach(key => {
      const match = key.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/);
      if (!match) {
        return;
      }
      const date = match[0];
      if (touchedDays.includes(date)) {
        delete nextEvents[key];
      }
    });

    events.forEach(event => {
      nextEvents[`${event.payload.date}#${event.payload.id}`] = event;
    });

    return ({
      ...state, events: nextEvents,
    });
  },
  [CALENDAR_SET_FAVORITE_FLAG]: (state, { payload }) => ({ ...state, facilityFavorites: payload.facilityFavorites }),
};

export default reducerObjective(reducers, initialState);

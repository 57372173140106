import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'react-admin';

import RatingField from 'ui/RatingField';
import Title from './Title';
import CheckEvent from './CheckEvent';

const ReviewCompletedDetails = ({ record, handleShowCheckInSignature, handleShowCheckOutSignature }) => {
  if (record.status !== 'completed') {
    return null;
  }

  if (record.review?.hidden) {
    return null;
  }

  return (
    <div>
      <Title text="Completed" />
      <CheckEvent
        record={record}
        checkIn="checkInTime"
        checkOut="checkOutTime"
        checkinSupervisor="checkInSupervisorName"
        checkoutSupervisor="checkOutSupervisorName"
        showCheckinPopup={handleShowCheckInSignature}
        showCheckoutPopup={handleShowCheckOutSignature}
      />
      <RatingField record={record} source="review.overallRating" label="Overall Rating" />
      <RatingField record={record} source="review.customerService" label="Customer Service" />
      <RatingField record={record} source="review.nurseSkills" label="Clinician Skills" />
      <TextField record={record} source="review.comment" label="Comment" />
    </div>
  );
};

ReviewCompletedDetails.propTypes = {
  record: PropTypes.shape({
    status: PropTypes.string,
    review: PropTypes.string,
  }),
  handleShowCheckInSignature: PropTypes.func,
  handleShowCheckOutSignature: PropTypes.func,
};

ReviewCompletedDetails.defaultProps = {
  record: null,
  handleShowCheckInSignature: null,
  handleShowCheckOutSignature: null,
};

export default ReviewCompletedDetails;

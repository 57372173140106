const ENVIRONMENT = {
  localhost: {
    host: 'http://localhost:3003',
    gql: 'http://localhost:3003/graphql',
    ws: 'ws://localhost:3003/subscriptions',
    microAppUrl: 'http://localhost:3000',
    nextGenAppUrl: 'http://localhost:3000',
    ldClientId: '648c8b40debeb91262a5718c',
    mode: 'development',
  },
  'facility-staging.nursedash.com': {
    host: 'https://gql-api-staging.nursedash.com',
    gql: 'https://gql-api-staging.nursedash.com/graphql',
    ws: 'wss://gql-api-staging.nursedash.com/subscriptions',
    microAppUrl: 'https://shifts-staging.nursedash.com',
    nextGenAppUrl: 'https://nextgen-facility-staging.nursedash.com',
    ldClientId: '648c8b5282999b13b8839e03',
    mode: 'staging',
  },
  'facility-sandbox.nursedash.com': {
    host: 'https://gql-api-sandbox.nursedash.com',
    gql: 'https://gql-api-sandbox.nursedash.com/graphql',
    ws: 'wss://gql-api-sandbox.nursedash.com/subscriptions',
    nextGenAppUrl: 'https://nextgen-facility-sandbox.nursedash.com',
    ldClientId: '648c8b40debeb91262a5718c',
    mode: 'sandbox',
  },
  'nursedash-facility-prod-v2.herokuapp.com': {
    host: 'https://gql-api.nursedash.com',
    gql: 'https://gql-api.nursedash.com/graphql',
    ws: 'wss://gql-api.nursedash.com/subscriptions',
    microAppUrl: 'https://shifts.nursedash.com',
    nextGenAppUrl: 'https://app.nursedash.com',
    ldClientId: '648c8b40debeb91262a5718d',
    mode: 'production',
  },
  'facility.nursedash.com': {
    host: 'https://gql-api.nursedash.com',
    gql: 'https://gql-api.nursedash.com/graphql',
    ws: 'wss://gql-api.nursedash.com/subscriptions',
    microAppUrl: 'https://shifts.nursedash.com',
    nextGenAppUrl: 'https://app.nursedash.com',
    ldClientId: '648c8b40debeb91262a5718d',
    mode: 'production',
  },
};

const CASE = window.location.hostname;

export const ENVIRONMENT_MODE = ENVIRONMENT[CASE].mode;
export const API_HOST = ENVIRONMENT[CASE].host;
export const API_GQL = ENVIRONMENT[CASE].gql;
export const API_WS = ENVIRONMENT[CASE].ws;
export const MICRO_APP_URL = ENVIRONMENT[CASE].microAppUrl;
export const NEXTGEN_APP_URL = ENVIRONMENT[CASE].nextGenAppUrl;
export const LAUNCH_DARKLY_CLIENT_ID = ENVIRONMENT[CASE].ldClientId;

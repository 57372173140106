import { gql } from 'apollo-boost';
import { showNotification } from 'react-admin';
import { call, put } from 'redux-saga/effects';
import { client } from '../../../dataProvider';
import { SEND_MESSAGE } from '../actions';

export const action = SEND_MESSAGE;

export default function* ({ payload }) {
  yield put({ type: 'RA/FETCH_START' });

  const { channelId, text } = payload;

  const mutation = gql`
    mutation sendMessage($channelId: Int, $text: String) {
      addMessage(channelId: $channelId, text: $text) {
        id
      }
    }
  `;

  const variables = { channelId, text };

  try {
    yield call(client.mutate, { mutation, variables, fetchPolicy: 'no-cache' });
  } catch (error) {
    yield put(showNotification(error.message, 'error'));
  }

  yield put({ type: 'RA/FETCH_END' });
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  MenuItem, FormControl, Select, FormLabel,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  title: {
    color: '#2D3748',
    fontSize: 13,
    fontWeight: '600',
  },
  form: {
    marginTop: theme.spacing.unit * 2,
  },
  option: {
    color: '#000',
    fontSize: 13,
  },
  emptyOption: {
    color: '#A1A1AA',
    fontSize: 13,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});
const DNRReasonOptions = [
  { value: 'pending_investigation', label: 'Pending investigation' },
  { value: 'behavioral_issue', label: 'Behavioral issue' },
  { value: 'reliability_issue', label: 'Reliability issue' },
  { value: 'other', label: 'Other' },
];
const DNRReasons = ({ classes, onSubmit }) => {
  const [reason, setReason] = useState('');

  const handleChange = (event) => {
    setReason(event.target.value);
    onSubmit(event.target.value);
  };
  return (
    <form className={classes.form}>
      <FormControl sx={{ m: 5, minWidth: 120 }}>
        <FormLabel className={classes.title}> Do Not Return reason</FormLabel>
        <Select
          value={reason}
          onChange={handleChange}
          displayEmpty
          name="reason"
          className={reason === '' ? classes.emptyOption : classes.option}
        >
          <MenuItem value="" disabled className={classes.emptyOption}>
          Select Reason
          </MenuItem>
          {DNRReasonOptions.map((option) => (
            <MenuItem key={option.value} value={option.value} className={classes.option}>{option.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </form>
  );
};
DNRReasons.propTypes = {
  classes: PropTypes.shape({
    title: PropTypes.string,
    form: PropTypes.string,
    option: PropTypes.string,
    emptyOption: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};
export default withStyles(styles)(DNRReasons);

import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
  Show, SimpleShowLayout,
  TextField,
  CardActions,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import DeleteIcon from '@material-ui/icons/Message';
import IconButton from '@material-ui/core/IconButton';

import { convertDistance } from 'geolib';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import Storage from 'lib/utils/localStorage';
import { bindActionCreatorsObjective } from '../../lib/redux/utils';
import * as systemActions from '../../lib/redux/system/actions';

import ApplicantSelectButton from './ApplicantSelectButton';
import RelationMenu from './RelationMenu';
import Resume from './Resume';
import Credentials from './Credentials';
import RelationIcon from './RelationIcon';

const styles = (theme) => ({
  nurse: {
    marginLeft: -theme.spacing.unit * 3,
    marginRight: -theme.spacing.unit * 3,
  },
  nurseAvatar: {
    marginLeft: theme.spacing.unit,
  },
  nurseAction: {
    marginRight: theme.spacing.unit * 7,
  },
  nurseMenuAction: {
    marginRight: theme.spacing.unit,
  },
  positionType: {
    marginRight: theme.spacing.unit * 9,
  },
  orientationMaterials: {
    marginTop: theme.spacing.unit,
  },
  confirmed: {
    color: '#060',
  },
  unconfirmed: {
    color: '#600',
  },
  relationDiv: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  relationIcon: {
    paddingLeft: theme.spacing.unit,
  },
});

class CardSource extends React.Component {
  componentDidMount() {
    const { systemActions, record } = this.props;
    systemActions.trackViewApplicant(record.shift.shiftId, record.shift.role, record.id, record.name);
  }

  render() {
    const {
      record, classes, history, resource, flags,
    } = this.props;
    const avatarSrc = record.photo || record.image;
    const isConfirmed = (record?.orientationMaterialConfirms ?? [])
      .find((item) => item?.orientationMaterialUuid === record?.orientationMaterials?.uuid);
    const { facilityFavorites } = flags;
    const role = Storage.getParam('user/role');
    return (
      <List dense={false} className={classes.nurse}>
        <ListItem>
          <ListItemAvatar className={classes.nurseAvatar}>
            {avatarSrc
              ? <Avatar src={avatarSrc} />
              : <Avatar><PeopleIcon /></Avatar>}
          </ListItemAvatar>
          <ListItemText
            primary={(
              <div className={classes.relationDiv}>
                {record.name}
                { facilityFavorites
                && (
                <div className={classes.relationIcon}>
                  <RelationIcon relation={record.relation} />
                </div>
                )}
              </div>
            )}
            secondary={(
              <>
                <Typography color="textSecondary" className={classes.positionType}>
                  Position Type:
                  {(record.positionType || []).join(', ') || 'not set'}
                </Typography>
                <Typography color="textSecondary">
                  Rating:
                  {record.rating ? `${record.rating} / 5` : 'No rating yet'}
                </Typography>
                <Typography color="textSecondary">
                  {'Distance: '}
                  {record.distance ? `${Number(convertDistance(record.distance, 'mi')).toFixed(1)} miles away` : 'Not available'}
                </Typography>
                <Typography color="textSecondary">
                  Orientation materials:
                  {isConfirmed
                    ? <span className={classes.confirmed}>confirmed</span>
                    : <span className={classes.unconfirmed}>unconfirmed</span>}
                </Typography>
              </>
            )}
          />
          <ListItemSecondaryAction className={classes.nurseAction}>
            {record.channelId && (
              <IconButton disabled={record.status === 'unavailable'} aria-label="Start chat" onClick={() => history.push(`/Messages/${record.channelId}/show`)}>
                <DeleteIcon />
              </IconButton>
            )}
          </ListItemSecondaryAction>
          {
            facilityFavorites && role !== 'admin'
          && (
          <ListItemSecondaryAction className={classes.nurseMenuAction}>
            <RelationMenu record={record} resource={resource} />
          </ListItemSecondaryAction>
          )
  }
        </ListItem>
      </List>
    );
  }
}

const Card = connect(null, (dispatch) => bindActionCreatorsObjective({
  systemActions,
}, dispatch))(withStyles(styles)(withRouter(withLDConsumer()(CardSource))));

const ApplicantShow = (props) => (
  <div className="layout-page" data-page={props.resource} data-mode="show" data-item={props.match.params.id}>
    <Show actions={<CardActions />} {...props}>
      <SimpleShowLayout>
        <Card />
        <TextField source="summary" />
        <Resume />
        <Credentials />
        <ApplicantSelectButton />
      </SimpleShowLayout>
    </Show>
  </div>
);

export default ApplicantShow;

import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Button, Toolbar, Confirm } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import IconSave from '@material-ui/icons/Save';
import gql from 'graphql-tag';

import { mixpanelTrack } from 'lib/utils/mixpanel';

import Storage from '../../../lib/utils/localStorage';
import { client } from '../../../lib/dataProvider';

const styles = (theme) => ({
  toolbarText: {
    marginLeft: theme.spacing.unit * 2,
  },
});

const FormToolbar = ({
  isShowConfirm,
  confirmation,
  submitLabel,
  onSubmit,
  classes,
  covidStatus,
  unitDescription,
  ...props
}) => {
  const [isOpen, setOpen] = useState(false);
  const { handleSubmitWithRedirect } = props;

  const handleYes = async () => {
    const facility_id = Number(Storage.getParam('facility/id'));

    const mutation = gql`
    mutation updateFacility($id: Int, $covidStatus: String) {
      updateFacility:updateFacility(id: $id, covidStatus: $covidStatus) {
        id
      }
    }
    `;
    const variables = { id: facility_id, covidStatus: 'on_site' };
    client.mutate({ mutation, variables, fetchPolicy: 'no-cache' }).then(({ data }) => console.log(data));

    Storage.setParam('covidStatusAcknowledged', 'true');
    Storage.setParam('covidChangedFromShift', 'true');
  };

  const handleClick = useCallback(() => {
    mixpanelTrack('CREATE_SHIFT_POST_SHIFT');

    if (isShowConfirm) {
      setOpen(true);
    } else {
      handleSubmitWithRedirect(props.redirect)();
      if (onSubmit) {
        onSubmit();
      }
    }
  }, [isShowConfirm]);

  // console.log('facilityId', Number(Storage.getParam('facility/id')), 'covidStatus', covidStatus, 'unitDescription', unitDescription);

  const handleConfirm = useCallback(() => {
    if (covidStatus !== 'on_site' && unitDescription === 'covid unit') {
      handleYes();
    }

    handleSubmitWithRedirect(props.redirect)();
    if (onSubmit) {
      onSubmit();
    }
  }, [covidStatus, unitDescription]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <Toolbar {...props} className="toolbar">
      <Button
        variant="contained"
        onClick={handleClick}
        label={window?.innerWidth >= 600 ? submitLabel : ''}
        key="button"
      >
        <IconSave />
      </Button>
      <Confirm
        isOpen={isOpen}
        title={confirmation?.title}
        content={confirmation?.content}
        confirm={confirmation?.confirm}
        confirmColor={confirmation?.confirmColor}
        cancel={confirmation?.cancel}
        onConfirm={handleConfirm}
        onClose={handleClose}
      />
      <Typography variant="caption" className={classnames(classes.toolbarText, 'toolbar__text')}>
        {'by posting this shift you are agreeing to NurseDash’s '}
        <a href="https://nursedash.com/terms-of-service/">terms</a>
        {' of service and '}
        <a href="https://nursedash.com/privacy-policy/">privacy policy</a>
        .
      </Typography>
    </Toolbar>
  );
};

FormToolbar.propTypes = {
  isShowConfirm: PropTypes.bool,
  handleSubmitWithRedirect: PropTypes.func,
  redirect: PropTypes.func,
  submitLabel: PropTypes.string,
  confirmation: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
    confirm: PropTypes.string,
    confirmColor: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
    cancel: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  classes: PropTypes.shape({
    toolbarText: PropTypes.string,
  }),
};

FormToolbar.defaultProps = {
  isShowConfirm: false,
  handleSubmitWithRedirect: null,
  redirect: null,
  submitLabel: '',
  confirmation: {
    title: 'Title',
    content: 'Are you sure you want to do it?',
    confirm: 'Yes',
    confirmColor: 'primary',
    cancel: 'Cancel',
  },
  onSubmit: null,
  classes: {},
};

export default withStyles(styles)(FormToolbar);

import React, { useEffect, useState } from 'react';
import { userLogout } from 'react-admin';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { NEXTGEN_APP_URL } from '../../config';

const NextGenLogout = (props) => {
  const { actionUserLogout } = props;
  const [logoutComplete, setLogoutComplete] = useState(false);

  useEffect(() => {
    actionUserLogout();
    setLogoutComplete(true);
  }, [setLogoutComplete, actionUserLogout]);

  useEffect(() => {
    if (logoutComplete) {
      window.location.href = `${NEXTGEN_APP_URL}/logout`;
    }
  }, [logoutComplete, NEXTGEN_APP_URL]);

  return <></>;
};

const mapActionToProps = (dispatch) => ({
  actionUserLogout: (payload) => dispatch(userLogout()),
});

export default compose(
  connect(null, mapActionToProps),
)(NextGenLogout);

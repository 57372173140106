export const LOADING_START = 'LOADING_START';
export const LOADING_END = 'LOADING_END';

export const start = (name) => ({
  type: LOADING_START,
  payload: { name },
});

export const end = (name) => ({
  type: LOADING_END,
  payload: { name },
});

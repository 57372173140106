import React from 'react';

const ScreenLayout = ({ children, resource, item, ...props }) => {
  let mode = 'list';

  switch (true) {
    case props.hasCreate:
      mode = 'create';
      break;
    case props.hasEdit:
      mode = 'edit';
      break;
    case props.hasShow:
      mode = 'show';
      break;
  }

  return (
    <div
      className="layout-page"
      data-page={resource}
      data-mode={mode}
      data-item={item}
    >
      {children}
    </div>
  );
};

export default ScreenLayout;

import { bindActionCreators } from 'redux';

export const bindActionCreatorsObjective = (obj, ...params) => {
  const result = {};
  Object.keys(obj).forEach((key) => {
    result[key] = bindActionCreators(obj[key], ...params);
  });
  return result;
};

export const reducerObjective = (reducers, initialState, replacer = s => s) => (state = initialState, action) => {
  const type = replacer(action.type);
  if (!reducers[type]) {
    return state;
  }
  return reducers[type](state, action);
};
// @flow

// type TShiftCreateFrom = 'main menu' | 'list header' | 'duplicate button'

export const TRACK_EVENT_SEND = 'TRACK_EVENT_SEND';

export const trackLogin = () => ({ //+
  type: TRACK_EVENT_SEND,
  payload: {
    event: 'LOG_IN',
  },
});

export const trackLogout = () => ({ //+
  type: TRACK_EVENT_SEND,
  payload: {
    event: 'LOG_OUT',
  },
});

export const trackOpenScreenCalendar = () => ({ //+
  type: TRACK_EVENT_SEND,
  payload: {
    event: 'OPEN_SCREEN_CALENDAR',
  },
});

export const trackOpenScreenShiftOverview = () => ({ //+
  type: TRACK_EVENT_SEND,
  payload: {
    event: 'OPEN_SCREEN_SHIFT_OVERVIEW',
  },
});

export const trackOpenScreenCompletedShift = () => ({ //+
  type: TRACK_EVENT_SEND,
  payload: {
    event: 'OPEN_SCREEN_COMPLETED_SHIFTS',
  },
});

export const trackOpenScreenShiftCreate = (from = 'main menu') => ({ //+
  type: TRACK_EVENT_SEND,
  payload: {
    event: 'OPEN_SCREEN_CREATE_SHIFT',
    extra: { from: `from ${from}` },
  },
});

export const trackOpenScreenMessages = (from = 'main menu') => ({ //+
  type: TRACK_EVENT_SEND,
  payload: {
    event: 'OPEN_SCREEN_MESSAGES',
    extra: { from: `from ${from}` },
  },
});

export const trackPostShift = () => ({ //+
  type: TRACK_EVENT_SEND,
  payload: {
    event: 'POST_SHIFT',
  },
});

export const trackEditShift = () => ({ //+
  type: TRACK_EVENT_SEND,
  payload: {
    event: 'EDIT_SHIFT',
  },
});

export const trackCancelShift = (id) => ({ //+
  type: TRACK_EVENT_SEND,
  payload: {
    event: 'CANCEL_SHIFT',
    extra: { id },
  },
});

export const trackDismissCancelShift = (id) => ({ //+
  type: TRACK_EVENT_SEND,
  payload: {
    event: 'DISMISS_CANCEL_SHIFT',
    extra: { id },
  },
});

export const trackUseFilter = () => ({
  type: TRACK_EVENT_SEND,
  payload: {
    event: 'USE_FILTER',
  },
});

export const trackViewApplicant = (shiftId, shiftRole, applicantId, applicantName) => ({ //+
  type: TRACK_EVENT_SEND,
  payload: {
    event: 'VIEW_APPLICANT',
    extra: {
      shiftId, shiftRole, applicantId, applicantName,
    },
  },
});

export const trackSelectApplicant = (shiftId, shiftRole, applicantId, applicantName) => ({ //+
  type: TRACK_EVENT_SEND,
  payload: {
    event: 'SELECT_APPLICANT',
    extra: {
      shiftId, shiftRole, applicantId, applicantName,
    },
  },
});

export const trackUnselectApplicant = (shiftId, shiftRole, applicantId, applicantName) => ({ //+
  type: TRACK_EVENT_SEND,
  payload: {
    event: 'UNSELECT_APPLICANT',
    extra: {
      shiftId, shiftRole, applicantId, applicantName,
    },
  },
});

export const trackForcedLogout = (extra) => ({ //+
  type: TRACK_EVENT_SEND,
  payload: {
    event: 'FORCED_LOGOUT',
    extra,
  },
});

export const trackOpenSettings = () => ({
  type: TRACK_EVENT_SEND,
  payload: {
    event: 'OPEN_SCREEN_SETTINGS',
  },
});

export const trackMaliciousLoginAttempt = () => ({
  type: TRACK_EVENT_SEND,
  payload: {
    event: 'AUTH/MALICIOUS_ATTEMPT',
  },
});

export const trackRedirectToNextgen = () => ({
  type: TRACK_EVENT_SEND,
  payload: {
    event: 'REDIRECT_TO_NEXTGEN',
  },
});

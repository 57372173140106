import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import ShiftReviewModal from 'components/shift/ShiftReviewModal';

const ModalContext = React.createContext({
  isOpen: false,
  shift: {
    review: null,
  },
});

export const useShiftReviewModal = () => {
  const context = React.useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useShiftReviewModal must be used within the ShiftReviewModalProvider');
  }

  return context;
};

const CommonModalContext = ({ children }) => {
  const [modalProps, setModalProps] = useState({ isOpen: false, shift: null });

  const handleOpen = useCallback((shift) => setModalProps({ isOpen: true, shift }), []);
  const handleClose = useCallback(() => setModalProps({ isOpen: false, shift: null }), []);

  return (
    <ModalContext.Provider value={{ modalProps, handleOpen, handleClose }}>
      {children}
      {
        modalProps.isOpen
        && <ShiftReviewModal {...modalProps} handleClose={handleClose} />
      }
    </ModalContext.Provider>
  );
};

CommonModalContext.propTypes = {
  children: PropTypes.node,
};

CommonModalContext.defaultProps = {
  children: null,
};

export default CommonModalContext;

import { gql } from 'apollo-boost';
import { showNotification } from 'react-admin';
import { delay } from 'redux-saga';
import {
  call, put, cancel, select,
} from 'redux-saga/effects';
import moment from 'moment-timezone';
import currentTimezone from 'lib/utils/currentTimezone';
import pluralize from 'pluralize';
import decodeJwt from 'jwt-decode';

import { client } from 'lib/dataProvider';
import Storage from 'utils/localStorage';
import {
  CALENDAR_GET_EVENTS,
  setEvents,
} from '../actions';


export const action = [CALENDAR_GET_EVENTS, 'RA/REFRESH_VIEW'];

const tzShift = moment.tz('US/Central').utcOffset() * -1;

const getTouchedDays = (dateFrom, dateTo) => {
  const start = moment(dateFrom);
  const end = moment(dateTo);

  const days = [];
  let day = start;

  while (day <= end) {
    days.push(day.format('YYYY-MM-DD'));
    day = day.clone().add(1, 'd');
  }

  return days;
};

const preparePayload = (event, date, facilityFavorites) => {

  const {
    id, role, timeStart, timeEnd, status, prevStatus, applicants, assignedApplicantStatus, assignedApplicantName, facilityTimezone, relation
  } = event;
  const timezone = currentTimezone();
  const endMoment = moment(timeEnd).tz(timezone);
  const isPastShift = endMoment.isBefore(moment());
  const isFavorite = relation === 'facility-favorite' && facilityFavorites;
  const favoriteIcon = isFavorite ? ' 🤍' : '';
  const favoriteFormat = isFavorite ? { color: '#373882', fontWeight: 'bold' } : {};

  const payload = {
    id,
    role,
    timeStart,
    timeEnd,
    facilityTimezone,
    status,
    isFavorite,
    date: date.clone().add(tzShift, 'minutes').format('YYYY-MM-DDTHH:mm:ss.000\\Z'),
    tooltip: null,
    style: { backgroundColor: '#AAA', opacity: 0.5 },
    zIndex: 1,
  };

  if (status === 'confirmed') {
    return {
      ...payload,
      tooltip: `Confirmed: ${assignedApplicantName}${favoriteIcon}`,
      style: { backgroundColor: '#77BB77', ...favoriteFormat },
      zIndex: 4,
      assignedApplicantName,
    };
  }

  if (status === 'pending check-in') {
    return {
      ...payload,
      tooltip: `Pending clock-in: ${assignedApplicantName}${favoriteIcon}`,
      style: { backgroundColor: '#77BB77', ...favoriteFormat },
      zIndex: isPastShift ? 6 : 8,
      assignedApplicantName,
    };
  }

  if (status === 'pending clock') {
    return {
      ...payload,
      tooltip: `Pending clock-out: ${assignedApplicantName}${favoriteIcon}`,
      style: { backgroundColor: '#77BB77', ...favoriteFormat },
      zIndex: isPastShift ? 6 : 7,
      assignedApplicantName,
    };
  }
  if (status === 'opened' && isPastShift) {
    return {
      ...payload,
      tooltip: 'Open',
      style: { backgroundColor: '#AEB4A9' },
      zIndex: 3,
    };
  }

  if (status === 'opened' && assignedApplicantStatus === 'selected') {
    return {
      ...payload,
      tooltip: `Selected: ${assignedApplicantName}${favoriteIcon}`,
      style: { backgroundColor: '#76b5bb', ...favoriteFormat },
      assignedApplicantName,
      zIndex: isPastShift ? 3 : 5,
    };
  }

  if (status === 'opened' && applicants > 0) {
    return {
      ...payload,
      tooltip: `Opened with ${applicants} ${pluralize('applicant', applicants)}, action required`,
      style: { backgroundColor: '#BBBB77' },
      zIndex: isPastShift ? 3 : 6,
    };
  }
  if (status === 'opened' && prevStatus === 'confirmed' && assignedApplicantStatus === 'withdrawn') {
    return {
      ...payload,
      tooltip: `Open - ${assignedApplicantName} withdrew`,
      style: { backgroundColor: 'rgb(255, 14, 14)' },
      zIndex: isPastShift ? 2 : 3,
    };
  }

  if (status === 'opened' && !applicants) {
    return {
      ...payload,
      tooltip: 'Opened without applicants',
      style: { backgroundColor: '#BB7777' },
      zIndex: isPastShift ? 3 : 2,
    };
  }

  if (status === 'cancelled') {
    return {
      ...payload,
      tooltip: 'Cancelled',
    };
  }

  if (status === 'pending review') {
    return {
      ...payload,
      tooltip: 'Pending review, action required',
      style: { backgroundColor: '#ECC30B', ...favoriteFormat },
      zIndex: 5,
      assignedApplicantName,
    };
  }

  if (status === 'completed') {
    return {
      ...payload,
      tooltip: `Completed: ${assignedApplicantName}${favoriteIcon}`,
      style: { backgroundColor: 'rgb(157, 214, 157)', ...favoriteFormat },
      assignedApplicantName,
      zIndex: 4,
    };
  }

  return payload;
};

const last = {
  dateRangeStart: null,
  dateRangeEnd: null,
};

export default function* ({ payload = {}, type }) {
  const { dateRangeStart, dateRangeEnd, facilityFavorites } = payload || {};

  if (dateRangeStart && dateRangeEnd) {
    last.dateRangeStart = dateRangeStart;
    last.dateRangeEnd = dateRangeEnd;
  }

  const token = Storage.getParam('user/token');
  if (!token) {
    yield cancel();
  }

  const user = decodeJwt(token);
  if (!user || user.role === 'nurse' || (user.role === 'admin' && !user?.params?.facilityId)) {
    yield cancel();
  }

  const location = yield select((state) => state.router.location);
  const isCalendarOpened = /\/Calendar.*/i.test(payload.pathname || location.pathname);

  if (!isCalendarOpened) {
    yield cancel();
  }

  if (type === 'RA/REFRESH_VIEW' && !last.dateRangeStart && !last.dateRangeEnd) {
    yield cancel();
  }
  // if we are not coming from calendar we need to fetch the flag from state
  let hasFavoriteFeature = facilityFavorites;
  if (type === 'RA/REFRESH_VIEW') {
    hasFavoriteFeature = yield select((state) => state.calendar.facilityFavorites);
  }
  const dateFrom = dateRangeStart || last.dateRangeStart;
  const dateTo = dateRangeEnd || last.dateRangeEnd;

  yield delay(100);

  let response = {};

  try {
    response = yield call(client.query, {
      query: gql`
        query($filter: CalendarShiftFilter) {
          allCalendarShifts(filter: $filter) {
            id
            role
            date
            dateWithTimezone
            timeStart
            timeEnd
            status
            prevStatus
            applicants
            hasAssignedNurse
            assignedApplicantStatus
            assignedApplicantId
            assignedApplicantName
            facilityTimezone
            relation
          }
        }
      `,
      variables: {
        filter: {
          dateFrom,
          dateTo,
        },
      },
      fetchPolicy: 'no-cache',
    });
  } catch (error) {
    yield put(showNotification(error.message, 'error'));
    yield cancel();
  }

  const { data } = response;
  if (!data || !data.allCalendarShifts) {
    yield cancel();
  }

  const result = [];
  data.allCalendarShifts.forEach((event) => {
    const date = moment.tz(event.timeStart, event.facilityTimezone);
    // in order to work with day view we need to return javascript date object
    const start = moment(event.timeStart).tz(event.facilityTimezone).toDate();
    const end = moment(event.timeEnd).tz(event.facilityTimezone).toDate();
    result.push({
      allDay: false,
      start,
      end: start,
      actualEnd: end,
      title: `${event.id} - ${event.role}`,
      payload: preparePayload(event, date, hasFavoriteFeature),
    });
  });

  yield put(setEvents(result, getTouchedDays(dateFrom, dateTo)));
}
